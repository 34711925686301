export default {
  detectAI: "Rilevare l'intelligenza artificiale",
  placeholder: 'Incolla il tuo testo qui...',
  resultWillAppearHere: 'I risultati appariranno qui<br/> dopo la scansione',
  significantAIContentFound: 'Trovato un contenuto significativo di AI',
  values: {
    four: {
      description: "Il testo sembra scritto dall'intelligenza artificiale. Per soddisfare gli standard accademici e professionali, riscrivete le parti principali utilizzando i vostri pensieri, ristrutturate le frasi generate dall'IA e assicuratevi che il contenuto rifletta il vostro stile e la vostra comprensione.",
      title: "Prevalentemente generati dall'IA"
    },
    one: {
      description: "Il testo sembra essere per lo più scritto da un essere umano. Solo una piccola parte potrebbe contenere elementi generati dall'intelligenza artificiale. Se necessario, rivedete le sezioni chiave per garantire la coerenza del tono e dello stile.",
      title: 'Per lo più scritti da esseri umani'
    },
    three: {
      description: "Il testo è in gran parte generato dall'intelligenza artificiale. Per un tocco più originale e personale, riscrivete le sezioni più pesanti per l'IA con la vostra prospettiva e aggiungete spunti o esempi unici.",
      title: 'Trovato un contenuto significativo di AI'
    },
    two: {
      description: "Il testo contiene un mix di contenuti umani e generati dall'intelligenza artificiale. Per rafforzare l'autenticità, riformulate le sezioni generate dall'IA con parole vostre e perfezionate il tono per adattarlo alla vostra voce.",
      title: "Rilevata una certa influenza dell'IA"
    }
  },
  yourTextIsLargely: "Il testo è in gran parte generato dall'intelligenza artificiale. Per un tocco più originale e personale, riscrivete le sezioni più pesanti per l'IA con la vostra prospettiva e aggiungete spunti o esempi unici."
};
