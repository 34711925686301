import de from './de';
import en from './en';
import es from './es';
import fr from './fr';
import it from './it';
import pt from './pt';
export default {
  de,
  en,
  es,
  fr,
  it,
  pt,
};
