export default {
  active: "Aktiv",
  cancel: "Kündigen",
  canceled: "Storniert",
  "delete": "Löschen",
  deleteAccount: "Kontolöschung",
  deleteAccountAndData: "Konto und Daten löschen",
  deleteAccountButton: "Kontolöschung",
  deleteAccountText: "Bist du sicher, dass du Konto <br/> und Daten löschen möchtest?",
  deleteSuccessful: "Ihr Konto wurde gelöscht",
  emailAddress: "E-Mail-Adresse",
  freePlan: "Kostenloses ​Abo",
  goPro: "PRO NUTZEN",
  logOut: "Abmelden",
  ok: "OK",
  plan: "Abo",
  privacyPolicy: "Datenschutzrichtlinien",
  renewsAt: "Verlängert sich am",
  subscription: "Abonnement",
  termsOfUse: "Nutzungsbedingungen",
  title: "Einstellungen"
};
