export default {
  acceptButton: "Ci​ sto!",
  boostYourProductivityBy: "Aumenta​ la​ ​tua​ ​produttività",
  declineButton: "Non voglio ricevere consigli o aggiornamenti",
  description: "Ricevi​ consigli​ ​affidabili,​ ​aggiornamenti​ ​e​ ​offerte​ esclusive<br />per​ ​sfruttare​ ​al​ ​massimo​ ​il​ ​tuo​ ​assistente​ ​IA.",
  mobileVersion: {
    boostYourProductivityBy: "Aumenta la tua produttività",
    by: "",
    description: "Ricevi​ consigli​ ​affidabili,​ ​aggiornamenti​ ​e​<br/>​offerte​ esclusive per​ ​sfruttare​ ​al​ ​massimo​<br/>​il​ ​tuo​ ​assistente​ ​IA."
  },
  upTo3x: "fino a 3 volte!"
};
