export default {
  advancedTools: {
    advanced: 'Avançado',
    imageGeneration: {
      description: 'Crie imagens a partir de palavras',
      title: 'Geração de imagens'
    },
    linkAndAsk: {
      description: 'Cole qualquer ligação e pergunte',
      title: 'Ligação e Perguntar'
    },
    title: 'Ferramentas avançadas',
    tools: 'Ferramentas',
    uploadAndAsk: {
      description: 'Carregue um PDF, use a informação à sua maneira',
      title: 'Carregar e Perguntar'
    }
  },
  burgerMenu: {
    logout: 'Logout',
    settings: 'Settings',
    startConversation: 'Start Conversation'
  },
  chat: {
    startNewChat: 'Iniciar uma nova conversa'
  },
  chatHistory: 'Chat History',
  dallE3: 'Image Generation',
  emptyChatGPT: {
    gpt: 'GPT',
    gpt4o: 'GPT-4o',
    gptTabDescription: 'Desenvolvido pela OpenAI, o ChatGPT é o seu assistente para uma vasta gama de tarefas. Desde redigir e-mails e gerar relatórios detalhados até gerir o seu calendário e fazer brainstorming de ideias.',
    hereToHelpWithAnyQuestions: "I'm here to help you with any questions.",
    title: 'Como é que o posso ajudar hoje?'
  },
  emptyImageGeneration: {
    imageGeneration: 'Geração de imagens',
    imageGenerationTabDescription: 'Liberte a magia da IA com a Geração de imagens. Transforme instantaneamente as suas palavras em imagens impressionantes e hipnotizantes. Embarque numa odisseia criativa — experiencie o poder da imaginação em cada píxel.<br /><br />Pode iniciar uma conversa a partir da caixa de mensagens abaixo ou experimentar os seguintes exemplos:',
    items: {
      image1: 'Cria uma linha do horizonte<br/>iluminada a néon num mundo cyberpunk futurista.',
      image2: 'Cria uma imagem de uma cabana acolhedora<br/>numa floresta com neve ao anoitecer.',
      image3: 'Desenha uma casa de banho pequena e elegante<br/>que seja gira e funcional.',
      image4: 'Imagina amigos e animais de estimação<br/>a desfrutarem juntos de um piquenique solarengo.'
    },
    title: 'Crie imagens a partir de palavras',
    youCanStartConvarsation: 'Pode iniciar uma conversa a partir da caixa de mensagens abaixo ou experimentar os seguintes exemplos:'
  },
  emptyLinkAndAsk: {
    linkAndAsk: 'Ligação e Perguntar',
    linkAndAskTabDescription: "Enter a URL and get answers to any question related to the page's content.",
    pasteLinkHere: 'Cole aqui uma ligação',
    title: 'Cole qualquer ligação e pergunte'
  },
  emptyUploadAndAsk: {
    changeDocument: 'Trocar documento',
    clickToUpload: 'Clique para carregar',
    fileSizeShouldNotExceed: 'O tamanho do arquivo não deve exceder',
    format: 'Formato, máx.',
    helpToYouWithQuestions: "I'm here to help you with any questions.",
    onlyPDFFilesAreAllowed: 'Apenas arquivos PDF são permitidos.',
    orDragAndDrop: 'ou arraste e largue',
    uploadAndAsk: 'Carregar e Perguntar',
    uploadAndAskTabDescription: 'Pesquise ou pergunte sobre qualquer coisa no seu documento.<br​•​/><br​•​/>Desde acordos legais a relatórios financeiros, a ferramenta Carregar e Perguntar permite-lhe interagir com os seus docmentos PDF sem problemas. Carregue um PDF, faça perguntas, obtenha resumos e extraia dados instantaneamente utilizando IA.',
    uploadPDFUseInfoYourWay: 'Carregue um PDF, use a informação à sua maneira',
    uploading: 'A carregar…'
  },
  greatForMostTasks: 'Great for most tasks',
  linkAndAsk: 'Link & Ask',
  mobileVersion: {
    allTime: 'All time',
    history: 'History',
    lastMonth: 'Últimos 30 dias',
    lastWeek: 'Últimos 7 dias',
    lastYear: 'Ano​•​passado',
    myAccount: 'My Account',
    save: 'Save',
    today: 'Hoje',
    tools: 'Ferramentas'
  },
  pro: 'Pró',
  promptLibrary: 'Prompt Library',
  promptLibraryDescription: 'Descubra e crie versões personalizadas do ChatGPT que combinam instruções, conhecimentos extra<br​•​/>e qualquer combinação de competências.',
  promptLibraryHint: 'Explore instruções para as suas ideias',
  recentlyUsed: 'Usadas recentemente',
  seeAll: 'Ver Todos',
  startNewChat: 'Iniciar uma nova conversa',
  uploadAndAsk: 'Upload & Ask',
  usesAdvancedReasoning: 'Uses advanced reasoning',
  writeYourMessage: 'Escreva a sua mensagem…'
};
