export default {
  dialogueSaved: "Dialogue sauvegardé",
  mobileVersion: {
    four: {
      items: {
        "[0]": {
          emoji: "💥",
          text: "Productivité"
        },
        "[1]": {
          emoji: "📚",
          text: "Apprendre"
        },
        "[2]": {
          emoji: "📈",
          text: "Vente"
        },
        "[3]": {
          emoji: "🚀",
          text: "Travail"
        },
        "[4]": {
          emoji: "🔬",
          text: "Données"
        },
        "[5]": {
          emoji: "🎭",
          text: "Divertissement"
        },
        "[6]": {
          emoji: "🎨",
          text: "Art"
        },
        item1: "Productivité",
        item2: "Apprendre",
        item3: "Vente",
        item4: "Travail",
        item5: "Données",
        item6: "Divertissement",
        item7: "Art"
      }
    },
    three: {
      message: "Crée une série de paysages de rêve, chacun représentant une saison différente"
    },
    two: {
      message: "Crée des œuvres d'art IA combinant des motifs circulaires et des fleurs aux couleurs vives"
    }
  },
  model: "Image​ générée",
  poweredBy: "Développé par",
  steps: {
    four: {
      description: "Au-delà des outils d’image IA, AskGPT propose une suite de services IA puissants, incluant une assistance via chat intelligente, l’analyse de liens de sites web et l’analyse de texte PDF.",
      gptMessage: "L'exploration spatiale a parcouru un long chemin depuis que le premier regard humain s'est tourné vers le ciel avec émerveillement. <br/><br/>Ses jalons ont marqué le voyage audacieux de l'humanité au-delà de la Terre et ont ouvert la voie à un avenir rempli d'immenses possibilités.<br/><br/>Cet essai explore les principales réalisations de l'exploration spatiale et ce qui nous attend...",
      items: {
        "[0]": {
          emoji: "🎨",
          text: "Art"
        },
        "[1]": {
          emoji: "🚀",
          text: "Travail"
        },
        "[2]": {
          emoji: "📚",
          text: "Apprendre"
        },
        "[3]": {
          emoji: "📈",
          text: "Vente"
        },
        "[4]": {
          emoji: "💥",
          text: "Productivité"
        },
        "[5]": {
          emoji: "🔬",
          text: "Données"
        },
        "[6]": {
          emoji: "🎭",
          text: "Divertissement"
        },
        item1: "Art",
        item2: "Travail",
        item3: "Apprendre",
        item4: "Vente",
        item5: "Productivité",
        item6: "Données",
        item7: "Divertissement"
      },
      title: "Boîte à outils d'IA avancée",
      userMessage: "Rédige un essai de 1 000 mots sur les évènements marquants et l'avenir de l'exploration spatiale"
    },
    one: {
      description: "Bienvenue sur AskGPT.<br/> Grâce à des modèles​ d'IA​ ​avancés, transformez vos idées les plus audacieuses en images époustouflantes, sans effort et sans limites.",
      items: {
        four: {
          description: "Conception d'un logo moderne pour une startup tech",
          title: "Image de marque d'une entreprise"
        },
        one: {
          description: "Chapeau de magicien d'où s’échappent des galaxies",
          title: "Générer des images avec l'IA"
        },
        three: {
          description: "Peinture abstraite minimaliste pour un salon",
          title: "Créer des œuvres d'art personnalisées"
        },
        two: {
          description: "Illustration de blog : grimpeur au sommet d'une montagne",
          title: "Visualisez vos idées"
        }
      },
      title: "De l'imagination à l'image <br class='hidden xxl:block'/>avec la créativité de l'IA"
    },
    three: {
      description: "AskGPT vous permet de créer des œuvres d'art, des tenues, des personnages de jeux et des visuels marketing personnalisés. Grâce à l'IA de pointe, donnez vie à vos idées sans effort.",
      title: "Visualisez​ ​tout ce que vous voulez​ ​avec des outils d'IA polyvalents",
      userMessage1: "Crée une série de paysages de rêve, chacun représentant une saison différente",
      userMessage2: "Générez une série d'intérieurs de salon, chacun dans un style de rendu unique"
    },
    two: {
      description: "Créez instantanément des visuels époustouflants avec AskGPT. Faites l'expérience d'une génération d'images rapide et de qualité supérieure et exploitez des possibilités créatives infinies.",
      items: {
        "[0]": {
          emoji: "🎨",
          text: "Art de l'IA"
        },
        "[1]": {
          emoji: "🪄",
          text: "Logos​ ​IA"
        },
        "[2]": {
          emoji: "📸",
          text: "Photos d'IA"
        },
        "[3]": {
          emoji: "🖋️",
          text: "Tatouages AI"
        },
        "[4]": {
          emoji: "🌈",
          text: "Affiches AI"
        },
        "[5]": {
          emoji: "📖",
          text: "Texte à image"
        },
        "[6]": {
          emoji: "🔁",
          text: "Image à image"
        },
        item1: "Art de l'IA",
        item2: "Logos IA",
        item3: "Photos d'IA",
        item4: "Tatouages AI",
        item5: "Affiches AI",
        item6: "Texte à image",
        item7: "Image à image"
      },
      title: "Images​ IA​ ​de​ ​haute​ ​qualité<br/>​ ​en​​ quelques​ ​secondes",
      userMessage: "Crée des œuvres d'art IA combinant des motifs circulaires et des fleurs aux couleurs vives"
    }
  }
};
