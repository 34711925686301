export default {
  mobileVersion: {
    button: "Weiter",
    poweredBy: "angetrieben von",
    steps: {
      two: {
        gptMessages: {
          message1: "„Guten Tag und vielen Dank, dass Sie sich die Zeit für ein Gespräch mit mir nehmen. Ich freue mich über die Möglichkeit, meine berufliche Entwicklung und mein Potenzial innerhalb des Unternehmens zu erörtern.“",
          message2: "„Guten Tag und vielen Dank, dass...",
          title: "Vorstellung"
        },
        userMessage: "Hilf mir, eine kurze Rede vorzubereiten, in der ich um eine Beförderung bitte"
      }
    }
  },
  signup: {
    trustText: "Jeden Tag vertrauen Millionen Menschen auf KI-Produkte"
  },
  steps: {
    "continue": "Weiter",
    mobile: {
      one: {
        description: "Willkommen bei AskGPT, Ihrem KI-Assistenten<br/>, der auf GPT- und GPT-4o-Technologie basiert.<br/>Erhalten Sie sofort fachkundigen Rat und personalisierte Lösungen<br/>.<br/>Fragen Sie alles, was Sie brauchen, wann immer Sie wollen.",
        title: "Unbegrenzte KI-Unterstützung<br/>angetrieben von GPT"
      },
      three: {
        description: "Definieren Sie neu, wie Sie Herausforderungen angehen,<br/>kommunizieren und lernen - mit fortschrittlicher<br/>Technologie in Ihren Händen.",
        title: "Ein KI-unterstützter Alltag"
      },
      two: {
        description: "Ob es darum geht, Probleme zu lösen oder Neugier zu befriedigen,<br/>AskGPT liefert präzise Antworten. Fragen Sie einfach.",
        title: "Sofortige Antworten erhalten"
      }
    },
    one: {
      description: "Willkommen bei dem KI-Chat-Assistenten, der auf der DeepSeek-Technologie basiert. Erhalten Sie sofort hochpräzise, personalisierte Ratschläge und Lösungen. Fragen Sie alles, jederzeit.",
      items: {
        four: {
          description: "Kannst du mir das Tageshoroskop für das Sternzeichen Stier geben?",
          title: "Spiritualität"
        },
        one: {
          description: "Einen Linkedin-Beitrag schreiben, um neue Kontakte für mein Unternehmen zu gewinnen",
          title: "Angetrieben von DeepSeek"
        },
        three: {
          description: "Gib mir einen Test, mit dem ich mein Wissen über die Sozialwissenschaften prüfen kann",
          title: "Lernen"
        },
        two: {
          description: "Schreiben Sie einen überzeugenden Werbetext für meine neue Kollektion von Cowboystiefeln",
          title: "Karriere"
        }
      },
      title: "Unbegrenzter <br/>Zugang zu DeepSeek"
    },
    poweredBy: "Angetrieben von",
    three: {
      description: "Bewältige Herausforderungen, kommuniziere und lerne auf neue Art und Weise – mit fortschrittlicher Technologie, die stets griffbereit ist.",
      messages: {
        gpt1: "Hallo 👋 Wie kann ich dir helfen?",
        gpt2: "Ein eigenes Unternehmen zu gründen, kann zu einem spannenden und lohnenden Projekt werden. Hier ist eine Schritt-für-Schritt-Anleitung, die den Einstieg erleichtert …",
        user: "Wie gründe ich mein eigenes Unternehmen?"
      },
      title: "Ein KI-unterstützter Alltag"
    },
    two: {
      description: "Von gelösten Problemen bis zu gestillter Neugier – Ihr KI-Chat-Assistent liefert präzise Antworten. Einfach fragen.",
      gptMessages: {
        message1: {
          message: "„Guten Tag und vielen Dank, dass Sie sich die Zeit für ein Gespräch mit mir nehmen. Ich freue mich über die Möglichkeit, meine berufliche Entwicklung und mein Potenzial innerhalb des Unternehmens zu erörtern.“",
          title: "Vorstellung:"
        },
        message2: {
          message: "„In den vergangenen [Zeitraum] durfte ich an mehreren wichtigen Projekten mitwirken, darunter [spezifisches Projekt nennen], bei dem wir [spezifisches Ergebnis] erzielen konnten.”",
          title: "Herausstellen der eigenen Leistungen:"
        },
        message3: {
          message: "„Von [bestimmter Aspekt der Arbeit oder des Unternehmens] bin ich auch persönlich sehr überzeugt, und ich denke, dass ich als [gewünschte Position] durch [bestimmte Beiträge] noch mehr bewirken kann. Ich möchte mich gerne neuen Herausforderungen stellen und unser Team auch weiterhin zum Erfolg führen.“",
          title: "Vision für die Zukunft:"
        }
      },
      items: {
        item1: "Persönliche Produktivität",
        item2: "Finanzen",
        item3: "Lernen und Ausbildung",
        item4: "Spiritualität und Religion",
        item5: "Kommunikation und Content-Erstellung",
        item6: "Mentales Wohlbefinden"
      },
      title: "Erhalte umgehend Antworten",
      userMessage: "Hilf mir, eine kurze Rede vorzubereiten, in der ich um eine Beförderung bitte"
    }
  }
};
