export default {
  form: {
    "continue": "Continuar",
    email: "Correo electrónico",
    password: "Contraseña"
  },
  login: {
    continueToYourAccount: "Continuar a tu cuenta",
    dontHaveAnAccount: "¿No tienes una cuenta?",
    signup: "Regístrate"
  },
  mobileVersion: {
    button: "Continuar",
    poweredBy: "Desarrollado por",
    steps: {
      two: {
        gptMessages: {
          message1: "«Hola. Muchas gracias por reunirse conmigo hoy. Le agradezco la oportunidad de hablar sobre mi crecimiento profesional y mi potencial en la empresa».",
          message2: "«Hola. Muchas gracias por reunirse conmigo...",
          title: "Introducción"
        },
        userMessage: "Ayúdame a preparar un guion para pedir un ascenso a mi jefe"
      }
    }
  },
  signup: {
    and: "y",
    byCreatingAnAccountYouAgree: "Al crear una cuenta, aceptas los",
    continueWith: "Continuar con {provider}",
    continueWithOneOfThese: "Continúa por uno de estos medios",
    doYouHaveAnAccount: "¿Tienes cuenta?",
    enjoySecureSpamFreeAssistance: "Recibe ayuda de forma segura y sin spam.",
    gptTechnologyTrustedByMillions: "Millones de personas confían en nuestro producto cada día",
    joinMillionsOfUsers: "Únete a millones<br/>de usuarios que confían en la IA",
    login: "Iniciar sesión",
    ourHappyUsers: "Usuarios satisfechos",
    privacyPolicy: "la política de privacidad y la política de cookies",
    termOfUse: "los términos de servicio",
    testimonials: {
      "[0]": {
        paragraph: "¡Vaya! Es con vivir en el futuro. Haz cualquier pregunta y te responde en cuestión de segundos. Mucho mejor que googlear y buscar por todas partes.",
        title: "",
        user: "jerseygirl​5"
      },
      "[1]": {
        paragraph: "La aplicación es absolutamente increíble, ayuda con todo tipo de tareas, lo recomiendo encarecidamente, me ayudó con mis matemáticas y tareas de Inglés",
        title: "",
        user: "increíble"
      },
      "[2]": {
        paragraph: "Es con vivir en el futuro. Haz cualquier pregunta y te responde en cuestión de segundos. Mucho mejor que googlear y buscar por todas partes.",
        title: "¡Vaya!",
        user: "jerseygirl5"
      },
      "[3]": {
        paragraph: "Lo más increíble que he visto y usado en mi vida",
        title: "El aspecto más importante de este planeta",
        user: "mylilphony1999"
      },
      "[4]": {
        paragraph: "100%",
        title: "Definitivamente ayuda a mi bloqueo de escritor",
        user: "Ashgang2162"
      },
      "[5]": {
        paragraph: "¡¡¡Me sorprende lo rápido que encuentra respuestas!!!",
        title: "¡Me​ encanta!",
        user: "CodeNameBianca"
      },
      "[6]": {
        paragraph: "La aplicación ofrece una interfaz fácil de usar que hace que acceder a la asistencia de IA mientras viaja sea increíblemente conveniente. Sin embargo, a veces se producen ligeros retrasos al cargar una respuesta.",
        title: "Bien",
        user: "nskdbffnrb"
      },
      "[7]": {
        paragraph: "En realidad, este asistente es bastante legítimo: era escéptico pero ustedes lo hicieron",
        title: "Droga",
        user: "Mackarthur"
      },
      user1: {
        paragraph: "¡Vaya! Es con vivir en el futuro. Haz cualquier pregunta y te responde en cuestión de segundos. Mucho mejor que googlear y buscar por todas partes.",
        title: "",
        user: "jerseygirl5"
      },
      user2: {
        paragraph: "La aplicación es absolutamente increíble, ayuda con todo tipo de tareas, lo recomiendo encarecidamente, me ayudó con mis matemáticas y tareas de Inglés.",
        title: "",
        user: "increíble"
      },
      user3: {
        paragraph: "Es con vivir en el futuro. Haz cualquier pregunta y te responde en cuestión de segundos. Mucho mejor que googlear y buscar por todas partes.",
        title: "¡Vaya!",
        user: "jerseygirl5"
      },
      user4: {
        paragraph: "La cosa más increíble que he visto y usado",
        title: "El aspecto más importante de este planeta",
        user: "mylilphony1999"
      },
      user5: {
        paragraph: "100%",
        title: "Definitivamente ayuda a mi bloqueo de escritor",
        user: "Ashgang2162"
      },
      user6: {
        paragraph: "¡¡¡Me sorprende lo rápido que encuentra respuestas!!!",
        title: "¡Me encanta!",
        user: "CodeNameBianca"
      },
      user7: {
        paragraph: "La aplicación ofrece una interfaz fácil de usar que hace que acceder a la asistencia de IA mientras viaja sea increíblemente conveniente. Sin embargo, a veces se producen ligeros retrasos al cargar una respuesta.",
        title: "Bien",
        user: "nskdbffnrb"
      },
      user8: {
        paragraph: "En realidad, este asistente es bastante legítimo: era escéptico pero ustedes lo hicieron",
        title: "Droga",
        user: "Mackarthur"
      }
    }
  },
  steps: {
    "continue": "Continuar",
    mobile: {
      one: {
        description: "Te damos la bienvenida a AskGPT,<br/>una herramienta entrenada con modelos avanzados de inteligencia artificial.<br/>Recibe consejos profesionales y soluciones personalizadas al instante.<br/>Pregunta lo que quieras cuando lo necesites.",
        title: "Obtén acceso ilimitado<br/>al asistente de IA"
      },
      three: {
        description: "Redefine tu forma de enfrentarte a los retos,<br/>comunicarte y aprender con tecnología<br/>avanzada en tus manos.",
        title: "Una vida más fácil con la inteligencia artificial"
      },
      two: {
        description: "AskGPT hace de todo: desde resolver problemas<br/> hasta saciar la curiosidad. Solo tienes que preguntarle.",
        title: "Obtén respuestas al instante"
      }
    },
    one: {
      description: "Te damos la bienvenida a AskGPT, una herramienta entrenada con modelos avanzados de inteligencia artificial. Recibe consejos profesionales y soluciones personalizadas al instante. Pregunta lo que quieras cuando lo necesites.",
      items: {
        four: {
          description: "¿Pueden facilitarme mi horóscopo diario para Tauro?",
          title: "Espiritualidad"
        },
        one: {
          description: "Escribe una publicación en LinkedIn para captar nuevos contactos para mi negocio",
          title: "Creación de contenido"
        },
        three: {
          description: "Hazme una prueba para comprobar mis conocimientos de Estudios Sociales",
          title: "Aprendizaje"
        },
        two: {
          description: "Escribe un texto publicitario persuasivo para mi nueva línea de botas vaqueras",
          title: "Carrera profesional"
        }
      },
      title: "Obtén<br/>acceso ilimitado<br/>al asistente de IA"
    },
    poweredBy: "Desarrollado por",
    three: {
      description: "Redefine tu forma de enfrentarte a los retos, comunicarte y aprender con tecnología avanzada en tus manos.",
      messages: {
        gpt1: "¡Hola 👋! ¿En qué puedo ayudarte?",
        gpt2: "Crear tu propia empresa puede ser una aventura emocionante y gratificante. Aquí tienes una guía paso a paso para empezar.",
        user: "¿Cómo monto mi propio negocio?"
      },
      title: "Una vida más fácil<br/>con la inteligencia artificial"
    },
    two: {
      description: "AskGPT hace de todo: desde resolver problemas hasta saciar la curiosidad. Solo tienes que preguntarle.",
      gptMessages: {
        "[0]": {
          message: "«Hola. Muchas gracias por reunirse conmigo hoy. Le agradezco la oportunidad de hablar sobre mi crecimiento profesional y mi potencial en la empresa».",
          title: "Introducción:"
        },
        "[1]": {
          message: "«A lo largo de los últimos [duración], he tenido el privilegio de contribuir en varios proyectos clave, entre ellos [menciona el proyecto concreto], en los que he logrado un [resultado concreto]».",
          title: "Destaca tus logros:"
        },
        "[2]": {
          message: "«Me apasiona [aspecto específico de tu trabajo o empresa] y creo que, en un [puesto deseado], puedo dejar una huella aún mayor mediante [contribuciones específicas]. Estoy deseando asumir nuevos retos y seguir impulsando a nuestro equipo hacia el éxito».",
          title: "Visión de futuro:"
        },
        message1: {
          message: "«Hola. Muchas gracias por reunirse conmigo hoy. Le agradezco la oportunidad de hablar sobre mi crecimiento profesional y mi potencial en la empresa».",
          title: "Introducción:"
        },
        message2: {
          message: "«Me apasiona [aspecto específico de tu trabajo o empresa] y creo que, en un [puesto deseado], puedo dejar una huella aún mayor mediante [contribuciones específicas]. Estoy deseando asumir nuevos retos y seguir impulsando a nuestro equipo hacia el éxito».",
          title: "Destaca tus logros:"
        },
        message3: {
          message: "«Me apasiona [aspecto específico de tu trabajo o empresa] y creo que, en un [puesto deseado], puedo dejar una huella aún mayor mediante [contribuciones específicas]. Estoy deseando asumir nuevos retos y seguir impulsando a nuestro equipo hacia el éxito».",
          title: "Visión de futuro:"
        }
      },
      items: {
        "[0]": {
          emoji: "📈",
          text: "Productividad personal"
        },
        "[1]": {
          emoji: "💰",
          text: "Finanzas"
        },
        "[2]": {
          emoji: "📚",
          text: "Aprendizaje y educación"
        },
        "[3]": {
          emoji: "🙏",
          text: "Espiritualidad y religión"
        },
        "[4]": {
          emoji: "💬",
          text: "Comunicación, creación de contenido"
        },
        "[5]": {
          emoji: "🧘",
          text: "Bienestar mental"
        },
        item1: "Productividad personal",
        item2: "Finanzas",
        item3: "Aprendizaje y educación",
        item4: "Espiritualidad y religión",
        item5: "Comunicación, creación de contenido",
        item6: "Bienestar mental"
      },
      title: "Obtén respuestas al instante",
      userMessage: "Ayúdame a preparar un guion para pedir un ascenso a mi jefe"
    }
  }
};
