export default {
  clearText: "Texto claro",
  copied: "Copiado",
  copy: "Copia",
  dislike: "No me gusta",
  enterTextHere: "Introduzca aquí el texto ...",
  like: "Como",
  loadingText: "Estamos analizando su contenido ahora",
  yourOutputContent: "Su contenido de salida"
} 
