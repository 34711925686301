import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import * as amplitude from '@amplitude/analytics-browser';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ClickOutside } from '@/Directives/ClickOutside';
import { router } from '@inertiajs/vue3';
import Vue3Marquee from 'vue3-marquee';
import { createPinia } from 'pinia';
import axiosInstance from '@/Utils/axiosInstance';
import MetaPixel from '@/plugins/meta-pixel';

import '@mdi/font/css/materialdesignicons.css';
import * as Sentry from '@sentry/vue';
import { createI18n } from 'vue-i18n';
import messages from '@/i18n/index.js';
import { initializeTrafficSource } from '@/Utils/designSwitcher.js';
const i18n = createI18n({
  locale: window.locale,
  fallbackLocale: 'en',
  messages,
});

export default i18n;

const pinia = createPinia();

if (window.location.pathname !== '/' && !window.location.pathname.startsWith('/admin')) {
  initializeTrafficSource();
}

createInertiaApp({
  title: (title) => `${title}`,
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(ZiggyVue)
      .use(Vue3Marquee)
      .use(pinia)
      .use(i18n)
      .use(MetaPixel)
      .directive('click-outside', ClickOutside)
      .mixin({
        computed: {
          UTMSource() {
            return localStorage.getItem('utm_source');
          },
          isSubscriptionActive() {
            return this.$page.props.auth?.hasActiveSubscription;
          },
          defaultValue() {
            return localStorage.getItem('default');
          },
        },
      });
    app.config.globalProperties.$axios = axiosInstance;

    const isStagingOrProduction = ['staging', 'production'].includes(window.stage);

    if (isStagingOrProduction) {
      Sentry.init({
        app,
        dsn: 'https://635de678a90a19739537dc6c424bc740@o4508461090865152.ingest.us.sentry.io/4508676182900736',
        ignoreErrors: [
          /TypeError/,
          /Network Error/,
          /AxiosError/,
          /SyntaxError/,
          /ReferenceError/,
          /SecurityError/,
          /DataCloneError/,
          /IntegrationError/,
          /Error/,
        ],
        beforeSend(event) {
          if (event.exception && event.exception.values) {
            const shouldIgnore = event.exception.values.some(
              (exception) => {
                const isTypeError = exception.type === 'TypeError';
                const isNetworkError = exception.value && exception.value.includes('Network Error');

                return isTypeError || isNetworkError;
              }
            );

            if (shouldIgnore) {
              return null;
            }
          }
          return event;
        },
        ignoreUrls: [/.*node_modules.*/, /.*public\/build.*/],
        integrations: [
          Sentry.browserTracingIntegration(),
          // Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: [
          'localhost',
          new RegExp(`^${window.appUrl}`),
        ],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        environment: window.stage,
      });
    }
    app.mount(el);
  },
  progress: {
    color: '#4B5563',
  },
});

router.on('navigate', (event) => {
  if (window.gtag) {
    let gtagConfig = {
      debug: window.stage !== 'production',
    };
    gtag('js', new Date());
    gtag('config', 'AW-11397947624', gtagConfig);
  }
});
