export default {
  detectAI: 'Detectar IA',
  placeholder: 'Pega aquí tu texto...',
  resultWillAppearHere: 'Los resultados aparecerán aquí<br/> después de escanear',
  significantAIContentFound: 'Contenido significativo de IA',
  values: {
    four: {
      description: 'Tu texto parece escrito por la IA. Para cumplir las normas académicas y profesionales, reescribe las partes clave con tus propios pensamientos, reestructura las frases generadas por la IA y asegúrate de que el contenido refleja tu estilo y comprensión únicos.',
      title: 'Predominantemente generado por IA'
    },
    one: {
      description: 'Su texto parece estar escrito en su mayor parte por humanos. Sólo una pequeña parte puede contener elementos generados por IA. Si es necesario, revise las secciones clave para garantizar la coherencia en el tono y el estilo.',
      title: 'Mayoritariamente escrito por humanos'
    },
    three: {
      description: 'Tu texto está generado en gran parte por la IA. Para darle un toque más original y personal, reescribe las secciones con más IA desde tu propia perspectiva y añade ideas o ejemplos únicos.',
      title: 'Contenido significativo de IA'
    },
    two: {
      description: 'Tu texto contiene una mezcla de contenido humano y generado por IA. Para reforzar la autenticidad, reformula las secciones generadas por la IA con tus propias palabras y refina el tono para que coincida con tu voz.',
      title: 'Se ha detectado cierta influencia de la IA'
    }
  },
  yourTextIsLargely: 'Tu texto está generado en gran parte por la IA. Para darle un toque más original y personal, reescribe las secciones con más IA desde tu propia perspectiva y añade ideas o ejemplos únicos.'
};
