export default {
  checkout: {
    "continue": "Continuar",
    discount: "de descuento",
    easierFasterAndSaferWayToPay: "Una forma de pago más fácil, rápida y segura",
    orPayByCard: "O paga con tarjeta",
    title: "Pago",
    total: "Total",
    totalPerDiscount: "Total por día"
  },
  "continue": "Continuar",
  plans: {
    and: "y",
    appName: "AskGPT​ Pro",
    byProceedingWithThePurchase: "Al comprar un plan, aceptas",
    mostPopular: "Más​ ​popular",
    oneMonth: "1 mes",
    oneYear: "1 año",
    paySafe: "Pagar de forma segura",
    perDay: "AL DÍA",
    privacyPolicy: "la política de privacidad",
    refund: "reembolso y cancelación.",
    save: "ahorra un",
    subtitle: "Desata todo el potencial de AskGPT​ Pro",
    subtitleDeepSeek: "Libera todo el potencial de tu asistente de chat con IA",
    subtitleDetector: "Libera todo el potencial de AskGPT App",
    subtitleGrok: "Libera todo el potencial de tu asistente de chat con IA",
    termsOfService: "los términos de servicio",
    threeMonths: "3 meses",
    title: "Actualizar<br/>a AskGPT Pro",
    titleDeepSeek: "Actualiza<br/>al asistente de chat AI Pro",
    titleGrok: "Actualiza<br/>al asistente de chat AI Pro",
    to: "a",
    toOur: "nuestros",
    upgrade: "Pasarse a"
  },
  poweredBy: "Desarrollado por",
  prompts: {
    benefits: {
      "[0]": {
        description: "Desde proyectos personales a rutinas diarias",
        title: "Más de 350 soluciones de tareas a medida"
      },
      "[1]": {
        description: "Accede al instante a soluciones personalizadas para tus retos",
        title: "Respuestas personalizadas con un nuevo clic"
      },
      "[2]": {
        description: "Tu asistente personal gratuito para todos los aspectos de la vida",
        title: "Bot GPT universal"
      },
      item1: {
        description: "Desde proyectos personales a rutinas diarias",
        title: "Más de 350 soluciones de tareas a medida"
      },
      item2: {
        description: "Accede al instante a soluciones personalizadas para tus retos",
        title: "Respuestas personalizadas con un nuevo clic"
      },
      item3: {
        description: "Tu asistente personal gratuito para todos los aspectos de la vida",
        title: "Bot​ ​IA​ ​universal"
      }
    },
    boostYourProductivityBy: "¡Triplica tu productividad!",
    expertCraftedPrompts: "prompts elaborados por expertos",
    getLifetimeAccessOurExpert: "Obtén acceso de por vida a nuestra biblioteca de prompt expertos!",
    now: "ahora",
    off: "DE DESCUENTO",
    oneTimePaymentForLifetimeAccess: "Pago único para acceso de por vida",
    paySafe: "Pagar de forma segura",
    proposedPlans: {
      pro: {
        items: {
          "[0]": "10-15 min",
          "[1]": "3-4 horas",
          "[2]": "20-30 min",
          "[3]": "10-15 min",
          "[4]": "20 min",
          item1: "10-15 min",
          item2: "3-4 horas",
          item3: "20-30 min",
          item4: "10-15 min",
          item5: "20 min"
        },
        newTitle: "Con nuestros robots de IA personalizados<br/> ",
        title: "Con nuestros prompts basados en el análisis"
      },
      standart: {
        items: {
          "[0]": "1-2 horas",
          "[1]": "8-10 horas",
          "[2]": "2-3 horas",
          "[3]": "1-2 horas",
          "[4]": "1-2 horas",
          item1: "1-2 horas",
          item2: "8-10 horas",
          item3: "2-3 horas",
          item4: "1-2 horas",
          item5: "1-2 horas"
        },
        title: "Con el bot de IA<br/>estándar"
      }
    },
    save: "Ahorra un",
    superchargeYour: "Mejora tu",
    superchargeYourRoutine: "Mejora tu rutina",
    title: "Mejora tu<br​ ​class=\"lg:hidden\"​ ​/> ChatGPT-4 con <br​ class=\"lg:hidden\"​ ​/>prompts elaborados por expertos, <br​ class=\"lg:hidden\"​ />¡ahora <span​ class=\"text-[#DC2728]\">con un 80 % de descuento!</span>",
    topicsForRequests: {
      "[0]": "🏋 Crear un plan de entrenamiento personalizado",
      "[1]": "📸 Aprender edición fotográfica básica",
      "[2]": "🎥 Crear un guion para un vídeo",
      "[3]": "🥗 Sugerir un plan de comidas semanal",
      "[4]": "📝 Responder a 10 correos electrónicos",
      topic1: "🏋 Crear un plan de entrenamiento personalizado",
      topic2: "📸 Aprender edición fotográfica básica",
      topic3: "🎥 Crear un guion para un vídeo",
      topic4: "🥗 Sugerir un plan de comidas semanal",
      topic5: "📝 Responder a 10 correos electrónicos"
    },
    totalToday: "Total​ ​hoy",
    upgrade: "Pasarse a",
    upgradeToExpert: "Actualizar a indicaciones de experto",
    "with": "con",
    withCustomAIBots: "con robots AI personalizados,"
  },
  purchaseBenefits: {
    free: "gratis",
    items: {
      advancedTools: {
        description: "Acceda a herramientas avanzadas de IA como el Generador de imágenes",
        title: "Uso de herramientas avanzadas"
      },
      gpt: {
        description: "Chatea con el asistente de IA",
        title: "Acceso a OpenAI GPT-4o mini"
      },
      gpt4o: {
        description: "OpenAI GPT-4o, Gemini, Claude",
        title: "Acceso al modelo GPT-4o"
      },
      noLimits: {
        description: "Entabla conversaciones ilimitadas con todos los modelos de IA",
        title: "Sin​ límites"
      }
    },
    itemsDeepSeek: {
      advancedTools: {
        description: "Acceda a herramientas avanzadas de IA como el Generador de imágenes",
        title: "Desbloquear herramientas avanzadas"
      },
      deepSeek: {
        description: "Pruebe el modelo avanzado de DeepSeek AI",
        title: "Acceso a DeepSeek"
      },
      gpt4o: {
        description: "OpenAI GPT-4o, Gemini, Claude",
        title: "Acceso a múltiples modelos:"
      },
      noLimits: {
        description: "Diálogos ilimitados con todos los modelos de IA",
        title: "Sin límites"
      }
    },
    itemsDetector: {
      advancedTools: {
        description: "Acceda a herramientas avanzadas como el Generador de imágenes",
        title: "Desbloquear herramientas avanzadas"
      },
      gpt: {
        description: "Chatea con la tecnología OpenAI",
        title: "Acceso a OpenAI GPT-4o mini"
      },
      gpt4o: {
        description: "Detección de IA 100% precisa",
        title: "Detector de contenidos AI"
      },
      noLimits: {
        description: "Diálogos ilimitados con todos los modelos de IA",
        title: "Sin límites"
      }
    },
    itemsGrok: {
      advancedTools: {
        description: "Acceda a herramientas avanzadas de IA como el Generador de imágenes",
        title: "Desbloquear herramientas avanzadas"
      },
      gpt4o: {
        description: "OpenAI GPT-4o, Gemini, Claude",
        title: "Acceso a modelos avanzados:"
      },
      grok: {
        description: "Charla con el modelo Grok de xAI",
        title: "Acceso a Grok"
      },
      noLimits: {
        description: "Diálogos ilimitados con todos los modelos de IA",
        title: "Sin límites"
      }
    },
    pro: "pro"
  },
  successModal: {
    backToChat: "Volver​ al​ ​chat",
    hasBeenSuccessfullyActivated: "se ha activado correctamente.",
    plan: "Plan",
    title: "¡Pago exitoso!",
    yourPro: "Tu​ Pro"
  }
};
