export default {
  form: {
    "continue": "Continuer",
    email: "Adresse e-mail",
    password: "Mot de passe"
  },
  login: {
    continueToYourAccount: "Continuer vers votre compte",
    dontHaveAnAccount: "Vous ne possédez pas de compte?",
    signup: "Inscrivez-vous"
  },
  mobileVersion: {
    button: "Continuer",
    poweredBy: "Développé par",
    steps: {
      two: {
        gptMessages: {
          message1: "« Bonjour, merci d’avoir pris le temps d'échanger aujourd’hui. C'est une bonne occasion de discuter de l’évolution de ma carrière et de mon potentiel au sein de l’entreprise. »",
          message2: "« Bonjour, merci d’avoir pris le temps de...",
          title: "Introduction"
        },
        userMessage: "Aide-moi à préparer un discours pour demander une promotion"
      }
    }
  },
  signup: {
    and: "et",
    byCreatingAnAccountYouAgree: "En créant un compte, vous acceptez nos",
    continueWith: "Continuer avec un {provider}",
    continueWithOneOfThese: "Continuez avec une des options suivantes",
    doYouHaveAnAccount: "Vous avez déjà un compte?",
    enjoySecureSpamFreeAssistance: "Bénéficiez d'une assistance sécurisée et sans spam, en toute quiétude.",
    gptTechnologyTrustedByMillions: "Des millions de personnes font confiance à notre produit chaque jour",
    joinMillionsOfUsers: "Rejoignez les millions <br/>de personnes convaincues<br/>par l’IA intelligente",
    login: "Connectez-vous",
    ourHappyUsers: "Utilisateurs satisfaits",
    privacyPolicy: "notre Politique de confidentialité et de Traitement des cookies",
    termOfUse: "Conditions​ d'utililisation",
    testimonials: {
      "[0]": {
        paragraph: "Ouah ! C'est comme vivre dans le futur! Posez n'importe quelle question et il vous répond en quelques secondes! C'est tellement mieux que de rechercher sur Google et de chercher partout.",
        title: "",
        user: "jerseygirl5"
      },
      "[1]": {
        paragraph: "Cette appli est absolument incroyable, elle m’aide pour tous mes devoirs, je recommande vivement, elle m’a aidé pour mes devoirs de maths et d’anglais",
        title: "",
        user: "hallucinant"
      },
      "[2]": {
        paragraph: "C'est comme vivre dans le futur ! Posez n'importe quelle question et il vous répond en quelques secondes ! C'est tellement mieux que de rechercher sur Google et de chercher partout.",
        title: "Ouah !",
        user: "jerseygirl5"
      },
      "[3]": {
        paragraph: "La chose la plus incroyable que j'ai jamais vue et utilisée",
        title: "L'aspect le plus important de cette planète.",
        user: "mylilphony1999"
      },
      "[4]": {
        paragraph: "100%",
        title: "Aide vraiment à surmonter le syndrome de la page blanche",
        user: "Ashgang2162"
      },
      "[5]": {
        paragraph: "Je suis étonné de la rapidité avec laquelle il propose des réponses !!!",
        title: "J'adore !",
        user: "CodeNameBianca"
      },
      "[6]": {
        paragraph: "L'application offre une interface conviviale qui rend l'accès à l'assistance IA en déplacement incroyablement pratique. Cependant, il y a parfois de légers retards dans le chargement d'une réponse.",
        title: "Bon",
        user: "nskdbffnrb"
      },
      "[7]": {
        paragraph: "Cet assistant est en fait assez légitime : j'étais sceptique mais vous l'avez fait",
        title: "Génial",
        user: "Mackarthur"
      },
      user1: {
        paragraph: "Ouah ! C'est comme vivre dans le futur ! Posez n'importe quelle question et il vous répond en quelques secondes ! C'est tellement mieux que de rechercher sur Google et de chercher partout.",
        title: "",
        user: "jerseygirl5"
      },
      user2: {
        paragraph: "Cette appli est absolument incroyable, elle m’aide pour tous mes devoirs, je recommande vivement, elle m’a aidé pour mes devoirs de maths et d’anglais",
        title: "",
        user: "Incroyable"
      },
      user3: {
        paragraph: "C'est comme vivre dans le futur ! Posez n'importe quelle question et il vous répond en quelques secondes ! C'est tellement mieux que de rechercher sur Google et de chercher partout.",
        title: "Ouah !",
        user: "jerseygirl5"
      },
      user4: {
        paragraph: "La chose la plus incroyable que j'ai jamais vue et utilisée",
        title: "L'aspect le plus important de cette planète.",
        user: "mylilphony1999"
      },
      user5: {
        paragraph: "100%",
        title: "Aide vraiment à surmonter le syndrome de la page blanche",
        user: "Ashgang2162"
      },
      user6: {
        paragraph: "Je suis étonné de la rapidité avec laquelle il propose des réponses !!!",
        title: "J'adore !",
        user: "CodeNameBianca"
      },
      user7: {
        paragraph: "L'application offre une interface conviviale qui rend l'accès à l'assistance IA en déplacement incroyablement pratique. Cependant, il y a parfois de légers retards dans le chargement d'une réponse.",
        title: "Bien​",
        user: "nskdbffnrb"
      },
      user8: {
        paragraph: "Cet assistant est en fait assez légitime : j'étais sceptique mais vous l'avez fait",
        title: "Génial",
        user: "Mackarthur"
      }
    }
  },
  steps: {
    "continue": "Continuer",
    mobile: {
      one: {
        description: "Bienvenue sur AskChat, alimenté <br/> par la technologie GPT et GPT-4o.<br/>Recevez instantanément des conseils pointus et des solutions personnalisées.<br/>Demandez ce que vous voulez, quand vous voulez.",
        title: "Obtenez un accès illimité<br/>à l'assistant IA"
      },
      three: {
        description: "Redéfinissez votre façon de relever les défis,<br/>de communiquer et d'apprendre, avec une technologie<br/>avancée entre vos mains.",
        title: "La vie enrichie par l’IA"
      },
      two: {
        description: "Pour résoudre des problèmes ou satisfaire votre curiosité,<br/>AskGPT vous donne des réponses précises. Il suffit de demander.",
        title: "Recevez des réponses instantanées"
      }
    },
    one: {
      description: "Bienvenue sur AskGPT, propulsé par des modèles d'IA avancés. Recevez instantanément des conseils pointus et des solutions personnalisées. Demandez ce que vous voulez, quand vous voulez.",
      items: {
        four: {
          description: "Peux-tu me donner l’horoscope du jour pour les Taureaux ?",
          title: "Spiritualité"
        },
        one: {
          description: "Rédige un post LinkedIn afin d’attirer de nouveaux prospects pour mon entreprise",
          title: "Création​ de​ ​contenu"
        },
        three: {
          description: "Faites-moi passer un test pour vérifier mes connaissances en sciences sociales",
          title: "Apprentissage"
        },
        two: {
          description: "Rédige un texte publicitaire persuasif pour ma nouvelle ligne de bottes de cow-boy",
          title: "Carrières"
        }
      },
      title: "Obtenez<br/>un accès illimité<br/>à l'assistant IA"
    },
    poweredBy: "Développé par",
    three: {
      description: "Redéfinissez votre façon de relever les défis, de communiquer et d'apprendre, avec une technologie avancée entre vos mains.",
      messages: {
        gpt1: "Bonjour 👋 Besoin d'aide ?",
        gpt2: "Créer sa propre entreprise peut être une aventure excitante et gratifiante. Voici un guide étape par étape pour vous aider à vous lancer…",
        user: "Comment puis-je créer ma propre entreprise?"
      },
      title: "La vie enrichie par l’IA"
    },
    two: {
      description: "Pour résoudre des problèmes ou satisfaire votre curiosité,<br/>AskGPT vous donne des réponses précises. Il suffit de demander.",
      gptMessages: {
        "[0]": {
          message: "« Bonjour, merci d’avoir pris le temps d'échanger aujourd’hui. C'est une bonne occasion de discuter de l’évolution de ma carrière et de mon potentiel au sein de l’entreprise. »",
          title: "Introduction:"
        },
        "[1]": {
          message: "« Au cours [de la période], j'ai eu le privilège de contribuer à plusieurs projets importants, notamment [mentionner le projet spécifique], dans le cadre duquel nous avons réalisé [un résultat spécifique]. »",
          title: "Mettez en valeur vos réalisations :"
        },
        "[2]": {
          message: "« [L’aspect spécifique de votre travail ou de votre entreprise] me passionne et je pense qu’en tant que [poste souhaité], je pourrai avoir un impact encore plus important grâce à [des contributions spécifiques]... »",
          title: "Vision​ pour​ ​l'avenir :"
        },
        message1: {
          message: "« Bonjour, merci d’avoir pris le temps d'échanger aujourd’hui. Je suis ravi d’avoir eu l’occasion de discuter de l’évolution de ma carrière et de mon potentiel au sein de l’entreprise. »",
          title: "Introduction​:"
        },
        message2: {
          message: "« Au cours [de la période], j'ai eu le privilège de contribuer à plusieurs projets importants, notamment [mentionner le projet spécifique], dans le cadre duquel nous avons réalisé [un résultat spécifique]. »",
          title: "Mettez en valeur vos réalisations :"
        },
        message3: {
          message: "« [L’aspect spécifique de votre travail ou de votre entreprise] me passionne et je pense qu’en tant que [poste souhaité], je pourrai avoir un impact encore plus important grâce à [des contributions spécifiques]... »",
          title: "Vision de l’avenir :"
        }
      },
      items: {
        "[0]": {
          emoji: "📈",
          text: "Productivité personnelle"
        },
        "[1]": {
          emoji: "💰",
          text: "Finance"
        },
        "[2]": {
          emoji: "📚",
          text: "Éducation et apprentissage"
        },
        "[3]": {
          emoji: "🙏",
          text: "Spiritualité et religion"
        },
        "[4]": {
          emoji: "💬",
          text: "Communication, création de contenu"
        },
        "[5]": {
          emoji: "🧘",
          text: "Bien-être mental"
        },
        item1: "Productivité personnelle",
        item2: "Finance",
        item3: "Éducation et apprentissage",
        item4: "Spiritualité et religion",
        item5: "Communication, création de contenu",
        item6: "Bien-être mental"
      },
      title: "Recevez des réponses instantanées",
      userMessage: "Aide-moi à préparer un discours pour demander une promotion"
    }
  }
};
