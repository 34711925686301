export default {
  poweredBy: 'Powered by',
  continue: 'Continue',
  purchaseBenefits: {
    free: 'free',
    pro: 'pro',
    items: {
      gpt: {
        title: 'Access to OpenAI GPT-4o mini',
        description: 'Chat with OpenAI API technology',
      },
      gpt4o: {
        title: 'Access to advanced models:',
        description: 'OpenAI GPT-4o, Gemini, Claude',
      },
      advancedTools: {
        title: 'Unlock Advanced Tools',
        description: 'Access advanced AI tools such as Image Generator',
      },
      noLimits: {
        title: 'No Limits',
        description: 'Unlimited dialogues with all AI models',
      },
    },
    itemsDeepSeek: {
      deepSeek: {
        title: 'Unlimited access to DeepSeek',
        description: 'Chat with DeepSeek',
      },
      gpt4o: {
        title: 'Access to multiple models:',
        description: 'OpenAI GPT-4o, Gemini, Claude',
      },
      advancedTools: {
        title: 'Unlock Advanced Tools',
        description: 'Access advanced AI tools such as Image Generator',
      },
      noLimits: {
        title: 'No Limits',
        description: 'Unlimited dialogues with all AI models',
      },
    },
    itemsDetector: {
      gpt: {
        title: 'Access to OpenAI GPT-4o mini',
        description: 'Chat with OpenAI technology',
      },
      gpt4o: {
        title: 'AI content detector',
        description: '100% accurate AI detection',
      },
      advancedTools: {
        title: 'Unlock advanced tools',
        description: 'Access advanced tools such as Image generator',
      },
      noLimits: {
        title: 'No Limits',
        description: 'Unlimited dialogues with all AI models',
      },
    },
    itemsGrok: {
      grok: {
        title: 'Unlimited access to Grok',
        description: 'Chat with xAI\'s Grok model',
      },
      gpt4o: {
        title: 'Access to advanced models:',
        description: 'OpenAI GPT-4o, Gemini, Claude',
      },
      advancedTools: {
        title: 'Unlock Advanced Tools',
        description: 'Access advanced AI tools such as Image Generator',
      },
      noLimits: {
        title: 'No Limits',
        description: 'Unlimited dialogues with all AI models',
      },
    },
    itemsGemini: {
      gemini: {
        title: 'Unlimited access to Gemini',
        description: 'Chat with Google\'s Gemini AI model',
      },
      gpt4o: {
        title: 'Access to advanced models:',
        description: 'OpenAI GPT-4o, Grok, Claude',
      },
      advancedTools: {
        title: 'Unlock Advanced Tools',
        description: 'Access advanced AI tools such as Image Generator',
      },
      noLimits: {
        title: 'No Limits',
        description: 'Unlimited dialogues with all AI models',
      },
    }
  },
  plans: {
    title: 'Upgrade<br/>to AskGPT Pro',
    titleDeepSeek: 'Upgrade<br/>to AI chat assistant Pro',
    titleGrok: 'Upgrade<br/>to AI chat assistant Pro',
    titleGemini: 'Upgrade<br/>to AI chat assistant Pro',
    subtitle: 'Unlock the full potential of AskGPT Pro',
    chooseYourPlan: 'Choose your plan:',
    subtitleDeepSeek: 'Unlock the full potential of your AI chat assistant',
    subtitleDetector: 'Unlock the full potential of AskGPT App',
    subtitleGrok: 'Unlock the full potential of your AI chat assistant',
    subtitleGemini: 'Unlock the full potential of your AI chat assistant',
    mostPopular: 'Most popular',
    save: 'save',
    perDay: 'Per Day',
    byProceedingWithThePurchase: 'By proceeding with the purchase of a plan, you agree',
    toOur: 'to our',
    termsOfService: 'Terms of Service',
    privacyPolicy: 'Privacy Policy',
    and: 'and',
    refund: 'Refund & Cancellation Policy.',
    paySafe: 'Pay safe & secure',
    oneMonth: '1 month',
    threeMonths: '3 months',
    oneYear: '1 year',
  },
  checkout: {
    title: 'Checkout',
    discount: 'discount',
    totalPerDiscount: 'Total per day',
    total: 'Total',
    easierFasterAndSaferWayToPay: 'Easier, faster and safer way to pay',
    orPayByCard: 'Or pay by card',
    continue: 'Continue',
  },
  successModal: {
    title: 'Payment successful!',
    yourPro: 'Your Pro',
    plan: 'Plan',
    hasBeenSuccessfullyActivated: 'has been successfully activated.',
    backToChat: 'Back to chat',
  },
  prompts: {
    superchargeYourRoutine: 'Supercharge your routine',
    withCustomAIBots: 'with custom AI-bots,',
    superchargeYour: 'Supercharge your',
    with: 'with',
    expertCraftedPrompts: 'expert-crafted prompts',
    now: 'now',
    off: 'OFF',
    save: 'Save',
    totalToday: 'Total today',
    oneTimePaymentForLifetimeAccess: 'One-Time Payment for Lifetime Access!',
    getLifetimeAccessOurExpert: 'Get lifetime access to our expert prompt library!',
    upgrade: 'Upgrade',
    upgradeToExpert: 'Upgrade to expert prompts',
    paySafe: 'Pay safe & secure',
    boostYourProductivityBy: 'Boost your productivity by',
    benefits: {
      item1: {
        title: '350+ tailored task solutions',
        description: 'From professional projects to daily routines',
      },
      item2: {
        title: 'One-click personalized answers',
        description: 'Instantly access customized solutions for your challenges',
      },
      item3: {
        title: 'Universal AI bot',
        description: 'Your personal, free assistant for all aspects of life',
      },
    },
    topicsForRequests: {
      topic1: '🏋 Create a custom workout plan',
      topic2: '📸 Learn basic photo editing',
      topic3: '🎥 Write a script for a video',
      topic4: '🥗 Suggest a weekly meal plan',
      topic5: '📝 Reply to 10 emails',
    },
    proposedPlans: {
      standart: {
        title: `With standard<br/>AI Bot`,
        items: {
          item1: '1-2 hours',
          item2: '8-10 hours',
          item3: '2-3 hours',
          item4: '1-2 hours',
          item5: '1-2 hours',
        },
      },
      pro: {
        newTitle: 'With our custom<br/> AI bots',
        title: 'With our research-based prompts',
        items: {
          item1: '10-15 min',
          item2: '3-4 hours',
          item3: '20-30 min',
          item4: '10-15 min',
          item5: '20 min',
        },
      },
    },
  },
};
