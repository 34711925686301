export default {
  form: {
    "continue": "Continua",
    email: "E-mail",
    password: "Password"
  },
  login: {
    continueToYourAccount: "Continua con il tuo account",
    dontHaveAnAccount: "Non hai un account?",
    signup: "Iscriviti"
  },
  mobileVersion: {
    button: "Continua",
    poweredBy: "Alimentato da",
    steps: {
      two: {
        gptMessages: {
          message1: "\"Ciao, grazie per aver trovato il tempo di incontrarmi oggi. Apprezzo l'opportunità di discutere della mia crescita professionale e delle opportunità di carriera all'interno dell'azienda.\"",
          message2: "\"Ciao, grazie per aver trovato il tempo...",
          title: "Introduzione"
        },
        userMessage: "Aiutami a preparare un discorso per chiedere una promozione al mio capo"
      }
    }
  },
  signup: {
    and: "e",
    byCreatingAnAccountYouAgree: "Creando un account, accetti i ​​nostri",
    continueWith: "Continua con {provider}",
    continueWithOneOfThese: "Continua con uno di questi metodi",
    doYouHaveAnAccount: "Hai un account?",
    enjoySecureSpamFreeAssistance: "Approfitta di un'assistenza sicura e senza spam, per una tranquillità totale.",
    gptTechnologyTrustedByMillions: "Milioni di persone si fidano del nostro prodotto ogni giorno",
    joinMillionsOfUsers: "Unisciti a milioni di utenti<br/>di intelligenza artificiale avanzata",
    login: "Accedi",
    ourHappyUsers: "utenti felici",
    privacyPolicy: "la nostra Informativa sulla privacy e sui cookie",
    termOfUse: "Termini di utilizzo",
    testimonials: {
      "[0]": {
        paragraph: "Wow! È come vivere nel futuro! Fai una domanda qualsiasi e ti darà una risposta in pochi secondi che soddisfa la tua domanda! Molto meglio che cercare su Google e altrove.",
        title: "",
        user: "jerseygirl5"
      },
      "[1]": {
        paragraph: "L'app è assolutamente fantastica, aiuta davvero con tutti i tipi di compiti. La consiglio vivamente, mi ha aiutato con i compiti di matematica e inglese",
        title: "",
        user: "sorprendente"
      },
      "[2]": {
        paragraph: "È come vivere nel futuro! Fai una domanda qualsiasi e ti darà una risposta in pochi secondi che soddisfa la tua domanda! Molto meglio che cercare su Google e altrove.",
        title: "Wow!",
        user: "jerseygirl5"
      },
      "[3]": {
        paragraph: "La cosa più incredibIle che abbia mai visto e usato.",
        title: "L'aspetto più importante di questo pianeta.",
        user: "mylilphony1999"
      },
      "[4]": {
        paragraph: "100%",
        title: "Mi aiuta molto con Il blocco dello scrittore.",
        user: "Ashgang2162"
      },
      "[5]": {
        paragraph: "Sono sbalordito dalla rapidità con cui elabora le risposte!",
        title: "Lo adoro!",
        user: "NomeCodiceBianca"
      },
      "[6]": {
        paragraph: "L'app offre un'interfaccia intuitiva che rende l'accesso all'assistenza AI facIle e comodo. Tuttavia, a volte ci sono leggere attese nel caricamento di una risposta.",
        title: "Buono",
        user: "nskdbffnrb"
      },
      "[7]": {
        paragraph: "Questo assistente è davvero ottimo, ero scettico ma avete fatto un lavoro incredibIle",
        title: "Fantastico",
        user: "Mackarthur"
      },
      user1: {
        paragraph: "Wow! È come vivere nel futuro! Fai una domanda qualsiasi e ti darà una risposta in pochi secondi che soddisfa la tua domanda! Molto meglio che cercare su Google e altrove.",
        title: "",
        user: "jerseygirl5"
      },
      user2: {
        paragraph: "L'app è assolutamente fantastica, aiuta davvero con tutti i tipi di compiti. La consiglio vivamente, mi ha aiutato con i compiti di matematica e inglese",
        title: "",
        user: "sorprendente"
      },
      user3: {
        paragraph: "È come vivere nel futuro! Fai una domanda qualsiasi e ti darà una risposta in pochi secondi che soddisfa la tua domanda! Molto meglio che cercare su Google e altrove.",
        title: "Wow!",
        user: "jerseygirl5"
      },
      user4: {
        paragraph: "La cosa più incredibIle che abbia mai visto e usato",
        title: "L'aspetto più importante di questo pianeta",
        user: "mylilphony1999"
      },
      user5: {
        paragraph: "100%",
        title: "Mi aiuta molto con Il blocco dello scrittore",
        user: "Ashgang2162"
      },
      user6: {
        paragraph: "Sono sbalordito dalla rapidità con cui elabora le risposte!",
        title: "Lo adoro!",
        user: "NomeCodiceBianca"
      },
      user7: {
        paragraph: "L'app offre un'interfaccia intuitiva che rende l'accesso all'assistenza AI facIle e comodo. Tuttavia, a volte ci sono leggere attese nel caricamento di una risposta.",
        title: "Buono",
        user: "nskdbffnrb"
      },
      user8: {
        paragraph: "Questo assistente è davvero ottimo, ero scettico ma avete fatto un lavoro incredibIle",
        title: "Fantastico",
        user: "Mackarthur"
      }
    }
  },
  steps: {
    "continue": "Continua",
    mobile: {
      one: {
        description: "Benvenuto su AskGPT, <br/>alimentato da modelli avanzati di intelligenza artificiale.<br/>Ricevi consigli esperti e soluzioni<br/>personalizzate all'istante.<br/>Chiedi qualsiasi cosa,<br/>in qualsiasi momento tu ne abbia bisogno.",
        title: "Ottieni<br/>accesso illimitato<br/>all'assistente IA"
      },
      three: {
        description: "Ridefinisci il modo in cui affronti le sfide,<br/>comunichi e impari, con tecnologia avanzata<br/>nelle tue mani.",
        title: "Vivere con l'intelligenza artificiale"
      },
      two: {
        description: "Dalla risoluzione dei problemi alla soddisfazione delle curiosità,<br/>AskGPT fornisce risposte precise. Basta chiedere.",
        title: "Ottieni risposte immediate"
      }
    },
    one: {
      description: "Ti diamo il benvenuto su AskGPT, alimentato da modelli avanzati di intelligenza artificiale. Ricevi istantaneamente consigli di esperti e soluzioni personalizzate. Chiedi qualsiasi cosa in qualsiasi momento.",
      items: {
        four: {
          description: "Puoi dirmi l'oroscopo giornaliero per il segno del Toro?",
          title: "Spiritualità"
        },
        one: {
          description: "Scrivi un post su Linkedin per attrarre nuovi contatti per la mia attività",
          title: "Creazione di contenuti"
        },
        three: {
          description: "Proponimi un test per verificare la mia conoscenza delle scienze sociali",
          title: "Apprendimento"
        },
        two: {
          description: "Scrivi un testo pubblicitario persuasivo per la mia nuova linea di stivali da cowboy",
          title: "Carriera"
        }
      },
      title: "Ottieni<br/>accesso illimitato<br/>all'assistente AI"
    },
    poweredBy: "Alimentato da",
    three: {
      description: "Ridefinisci il modo in cui affronti le sfide, comunichi e impari, con una tecnologia avanzata nelle tue mani.",
      messages: {
        gpt1: "Ciao 👋 Come posso aiutarti?",
        gpt2: "Avviare un'attività in proprio può essere un'impresa entusiasmante e gratificante. Ecco una guida passo dopo passo per aiutarti a iniziare...",
        user: "Come posso avviare un'attività in proprio?"
      },
      title: "Vivere con<br/>l'intelligenza artificiale"
    },
    two: {
      description: "Dalla risoluzione dei problemi alla soddisfazione delle curiosità, AskGPT fornisce risposte precise. Basta chiedere.",
      gptMessages: {
        "[0]": {
          message: "\"Ciao, grazie per aver trovato il tempo di incontrarmi oggi. Apprezzo l'opportunità di discutere della mia crescita professionale e delle opportunità di carriera all'interno dell'azienda.\"",
          title: "Introduzione:"
        },
        "[1]": {
          message: "\"Negli ultimi [durata], ho avuto il privilegio di contribuire a diversi progetti chiave, tra cui [menziona un progetto specifico], in cui abbiamo ottenuto un [risultato specifico].\"",
          title: "Metti in evidenza i tuoi risultati:"
        },
        "[2]": {
          message: "\"Sono appassionato di [aspetto specifico del tuo lavoro o della tua azienda] e credo che con il ruolo di [ruolo desiderato], potrò avere un impatto ancora maggiore mediante [contributi specifici]. Sono ansioso di affrontare nuove sfide e continuare a guidare il nostro team verso il successo\".",
          title: "Visione per il futuro:"
        },
        message1: {
          message: "\"Ciao, grazie per aver trovato il tempo di incontrarmi oggi. Apprezzo l'opportunità di discutere della mia crescita professionale e delle opportunità di carriera all'interno dell'azienda.\"",
          title: "Introduzione:"
        },
        message2: {
          message: "\"Negli ultimi [durata], ho avuto il privilegio di contribuire a diversi progetti chiave, tra cui [menziona un progetto specifico], in cui abbiamo ottenuto un [risultato specifico].\"",
          title: "Metti in evidenza i tuoi risultati:"
        },
        message3: {
          message: "\"Sono appassionato di [aspetto specifico del tuo lavoro o della tua azienda] e credo che con il ruolo di [ruolo desiderato], potrò avere un impatto ancora maggiore mediante [contributi specifici]. Sono ansioso di affrontare nuove sfide e continuare a guidare il nostro team verso il successo\".",
          title: "Visione per il futuro:"
        }
      },
      items: {
        "[0]": {
          emoji: "📈",
          text: "Produttività personale"
        },
        "[1]": {
          emoji: "💰",
          text: "Finanza"
        },
        "[2]": {
          emoji: "📚",
          text: "Apprendimento ed educazione"
        },
        "[3]": {
          emoji: "🙏",
          text: "Spiritualità e religione"
        },
        "[4]": {
          emoji: "💬",
          text: "Comunicazione, creazione di contenuti"
        },
        "[5]": {
          emoji: "🧘",
          text: "Benessere mentale"
        },
        item1: "Produttività personale",
        item2: "Finanza",
        item3: "Apprendimento ed educazione",
        item4: "Spiritualità e religione",
        item5: "Comunicazione, creazione di contenuti",
        item6: "Benessere mentale"
      },
      title: "Ottieni risposte immediate",
      userMessage: "Aiutami a preparare un discorso per chiedere una promozione al mio capo"
    }
  }
};
