export default {
  mobileVersion: {
    button: "Continuer",
    poweredBy: "Propulsé par",
    steps: {
      two: {
        gptMessages: {
          message1: "\"Bonjour, merci d'avoir pris le temps de me rencontrer aujourd'hui. J'apprécie l'occasion qui m'est donnée de discuter de mon évolution de carrière et de mon potentiel au sein de l'entreprise.\"",
          message2: "\"Bonjour, merci d'avoir pris le...",
          title: "Introduction"
        },
        userMessage: "Aidez-moi à préparer un discours pour demander une promotion à mon patron"
      }
    }
  },
  signup: {
    trustText: "Des millions de personnes font confiance aux produits d'IA chaque jour"
  },
  steps: {
    "continue": "Continuer",
    mobile: {
      one: {
        description: "Bienvenue sur AskGPT, votre assistant IA<br/>alimenté par les technologies GPT et GPT-4o.<br/>Recevez instantanément des conseils d'experts et des solutions personnalisées<br/>.<br/>Demandez tout ce que vous voulez, quand vous le voulez.",
        title: "Assistance illimitée de l'IA<br/>powered by GPT"
      },
      three: {
        description: "Redéfinissez la façon dont vous relevez les défis,<br/>communiquez et apprenez - avec la technologie avancée<br/>entre vos mains.",
        title: "La vie alimentée par l'IA"
      },
      two: {
        description: "Qu'il s'agisse de résoudre des problèmes ou de satisfaire la curiosité,<br/>AskGPT fournit des réponses précises. Il suffit de demander.",
        title: "Obtenir des réponses instantanées"
      }
    },
    one: {
      description: "Bienvenue dans l'assistant de chat AI, alimenté par la technologie DeepSeek. Obtenez instantanément des conseils et des solutions personnalisés d'une grande précision. Demandez n'importe quoi, n'importe quand.",
      items: {
        four: {
          description: "Pouvez-vous me donner l'horoscope du jour pour le Taureau ?",
          title: "Spiritualité"
        },
        one: {
          description: "Rédiger un post Linkedin pour attirer de nouveaux clients potentiels pour mon entreprise",
          title: "Propulsé par DeepSeek"
        },
        three: {
          description: "Faites-moi passer un test pour vérifier mes connaissances en sciences sociales.",
          title: "Apprentissage"
        },
        two: {
          description: "Rédiger un texte publicitaire convaincant pour ma nouvelle ligne de bottes de cow-boy",
          title: "Carrière"
        }
      },
      title: "Obtenez un accès illimité à DeepSeek sur <br/>"
    },
    poweredBy: "Propulsé par",
    three: {
      description: "Redéfinissez votre façon de relever les défis, de communiquer et d'apprendre - avec une technologie avancée entre vos mains.",
      messages: {
        gpt1: "Bonjour 👋 Comment puis-je vous aider ?",
        gpt2: "Créer sa propre entreprise peut être une aventure passionnante et gratifiante. Voici un guide étape par étape pour vous aider à démarrer...",
        user: "Comment puis-je créer ma propre entreprise ?"
      },
      title: "La vie alimentée par l'IA"
    },
    two: {
      description: "Qu'il s'agisse de résoudre des problèmes ou de satisfaire votre curiosité, votre assistant de chat IA vous apporte des réponses précises. Il suffit de demander.",
      gptMessages: {
        message1: {
          message: "\"Bonjour, merci d'avoir pris le temps de me rencontrer aujourd'hui. J'apprécie l'occasion qui m'est donnée de discuter de mon évolution de carrière et de mon potentiel au sein de l'entreprise.\"",
          title: "Introduction :"
        },
        message2: {
          message: "\"Au cours des [dernières années], j'ai eu le privilège de contribuer à plusieurs projets clés, dont [mentionner le projet spécifique], qui nous ont permis d'atteindre un [résultat spécifique].",
          title: "Mettez en valeur vos réalisations :"
        },
        message3: {
          message: "\"Je suis passionné par [aspect spécifique de votre travail ou de votre entreprise], et je pense que dans un [poste souhaité], je peux avoir un impact encore plus important grâce à [contributions spécifiques]. Je suis impatient de relever de nouveaux défis et de continuer à mener notre équipe vers le succès.\"",
          title: "Une vision pour l'avenir :"
        }
      },
      items: {
        item1: "Productivité personnelle",
        item2: "Finances",
        item3: "Apprentissage et éducation",
        item4: "Spiritualité et religion",
        item5: "Communication, création de contenu",
        item6: "Bien-être mental"
      },
      title: "Obtenir des réponses instantanées",
      userMessage: "Aidez-moi à préparer un discours pour demander une promotion à mon patron"
    }
  }
};
