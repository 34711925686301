export default {
  acceptButton: 'I’m in!',
  boostYourProductivityBy: 'Boost your productivity by',
  declineButton: "I don't want to receive tips or updates ",
  description: 'Get trusted tips, updates, and exclusive offers to make<br />the most of your AI assistant.',
  mobileVersion: {
    boostYourProductivityBy: 'Boost your productivity',
    description: 'Get trusted tips, updates, and<br />exclusive offers to make the<br />most of your AI assistant.',
    upTo3x: 'by up to 3x!'
  },
  upTo3x: 'up to 3x!'
};
