export default {
  mobileVersion: {
    button: "Continúa en",
    poweredBy: "Desarrollado por",
    steps: {
      two: {
        gptMessages: {
          message1: "\"Hola, gracias por tomarse el tiempo de reunirse conmigo hoy. Agradezco la oportunidad de hablar sobre mi crecimiento profesional y mi potencial dentro de la empresa.\"",
          message2: "\"Hola, gracias por tomarse la...",
          title: "Introducción"
        },
        userMessage: "Ayúdame a preparar un discurso para pedir un ascenso a mi jefe"
      }
    }
  },
  signup: {
    trustText: "Millones de personas confían cada día en los productos de IA"
  },
  steps: {
    "continue": "Continúa en",
    mobile: {
      one: {
        description: "Bienvenido al asistente de chat de IA, impulsado por Grok de xAI. Obtenga soluciones personalizadas al instante. Pregunta lo que quieras, cuando quieras.",
        title: "Obtenga acceso ilimitado<br/>a Grok"
      },
      three: {
        description: "Redefina su forma de afrontar los retos, comunicarse y aprender a través de<br/>con la avanzada tecnología<br/>en sus manos.",
        title: "Una vida impulsada por la inteligencia artificial"
      },
      two: {
        description: "Desde resolver problemas hasta satisfacer la curiosidad, su asistente de chat con inteligencia artificial proporciona respuestas precisas. Solo tienes que preguntar.",
        title: "Obtenga respuestas al instante"
      }
    },
    one: {
      description: "Bienvenido al asistente de chat de IA, impulsado por Grok de xAI. Obtenga soluciones personalizadas al instante. Pregunta lo que quieras, cuando quieras.",
      items: {
        four: {
          description: "¿Puede proporcionarme mi horóscopo diario para Tauro?",
          title: "Espiritualidad"
        },
        one: {
          description: "Escribir un post en Linkedin para atraer nuevos clientes potenciales para mi negocio",
          title: "Desarrollado por Grok AI"
        },
        three: {
          description: "Hazme un test para comprobar mis conocimientos de Estudios Sociales",
          title: "Aprender"
        },
        two: {
          description: "Escribir un texto publicitario persuasivo para mi nueva línea de botas vaqueras",
          title: "Carrera profesional"
        }
      },
      title: "Obtenga acceso ilimitado a Grok en <br/>"
    },
    poweredBy: "Desarrollado por",
    three: {
      description: "Redefina su forma de enfrentarse a los retos, comunicarse y aprender, con la tecnología avanzada en sus manos.",
      messages: {
        gpt1: "Hola 👋 ¿En qué puedo ayudarte?",
        gpt2: "Crear tu propia empresa puede ser una aventura apasionante y gratificante. Aquí tienes una guía paso a paso para empezar...",
        user: "¿Cómo puedo crear mi propia empresa?"
      },
      title: "Una vida impulsada por la inteligencia artificial"
    },
    two: {
      description: "Desde resolver problemas hasta satisfacer la curiosidad, su asistente de chat con inteligencia artificial proporciona respuestas precisas. Solo tienes que preguntar.",
      gptMessages: {
        message1: {
          message: "\"Hola, gracias por tomarse el tiempo de reunirse conmigo hoy. Agradezco la oportunidad de hablar sobre mi crecimiento profesional y mi potencial dentro de la empresa.\"",
          title: "Introducción:"
        },
        message2: {
          message: "\"Durante los últimos [duración], he tenido el privilegio de contribuir a varios proyectos clave, entre ellos [mencione el proyecto específico], en los que logramos un [resultado específico].\"",
          title: "Destaque sus logros:"
        },
        message3: {
          message: "\"Me apasiona [aspecto específico de su trabajo o empresa], y creo que en un [puesto deseado], puedo tener un impacto aún mayor mediante [contribuciones específicas]. Estoy ansioso por asumir nuevos retos y seguir impulsando a nuestro equipo hacia el éxito.\"",
          title: "Visión de futuro:"
        }
      },
      items: {
        item1: "Productividad personal",
        item2: "Finanzas",
        item3: "Aprendizaje y educación",
        item4: "Espiritualidad y religión",
        item5: "Comunicación, creación de contenidos",
        item6: "Bienestar mental"
      },
      title: "Obtenga respuestas al instante",
      userMessage: "Ayúdame a preparar un discurso para pedir un ascenso a mi jefe"
    }
  }
};
