export default {
  acceptButton: "¡Me apunto!",
  boostYourProductivityBy: "¡Triplica tu productividad!",
  declineButton: "No quiero recibir consejos ni novedades ",
  description: "Recibe consejos fiables, novedades y ofertas exclusivas para<br /> aprovechar al máximo tu asistente de IA.",
  mobileVersion: {
    boostYourProductivityBy: "¡Triplica tu productividad!",
    by: "",
    description: "Recibe consejos fiables, novedades y<br />ofertas exclusivas para sacar el máximo<br />provecho de tu asistente de IA."
  },
  upTo3x: " "
};
