export default {
  steps: {
    one: {
      title: 'AI detector by AskGPT',
      description: 'Identify AI-written text in research papers, student submissions, and professional documents.',
      rightSection: {
        text1: 'Detect AI-generated content with 100% accuracy.',
        text2: 'Analyze entire documents for authenticity.',
        text3: 'Get detailed reports with actionable insights.'
      },
      leftSection: {
        title: 'AI detector',
        description: 'Enter text here or upload file to check for AI Content.',
        items: {
          one: 'Paste Text',
          two: 'Sample Text',
          three: 'Upload File'
        }
      },
    },
    two: {
      title: 'Fast, reliable, and<br/>effortless AI detection',
      description: 'How it works:',
      rightSection: {
        step: 'Step',
        text1: 'Upload your document or paste your text.',
        text2: 'Our AI scans and analyzes your content.',
        text3: 'Instantly receive a detailed authenticity report.'
      },
      leftSection: {
        title: 'AI Generated Text',
        theUniverseIsAVastAndEver: 'The universe is a vast and ever-expanding mystery, filled with cosmic wonders beyond',
        humanComprehensionWithBillions: 'human comprehension. With billions of galaxies scattered across the fabric of space-time, each may harbor untold secrets—some familiar, others beyond our imagination.',
        scientistsEstimateThatOnly: 'Scientists estimate that only 5% of the universe consists of known matter, while the rest remains a puzzle of dark energy and dark matter, invisible forces shaping the cosmos. Black holes bend the very fabric of reality, swallowing light and time itself.',
        exoplanetsOrbitDistant: 'Exoplanets orbit distant stars, some',
        potentiallyHarboringLife: 'potentially harboring life, while signals from deep space whisper of unknown phenomena.',
        everyDiscoveryPeelsBack: 'Every discovery peels back a layer of the unknown, yet with each answer, new questions emerge, reminding us that the universe is not just vast—it is infinite in its mysteries.',
      },
    },
    three: {
      title: 'Stay ahead of AI-<br/>generated content risks',
      description: '',
      rightSection: {
        text1: 'Detect AI-generated content before submission.',
        text2: 'Verify research papers, student work, and professional documents.',
        text3: 'Avoid accidental misuse of AI-written text.',
        text4: 'All-in-one AI assistant with built-in AI detection.'
      },
      leftSection: {
        min: 'min',
        title: 'We’re analyzing your content now',
        steps: {
          label1: 'Processing texts',
          label2: 'Identifying same words',
          label3: 'Identifying changed words',
          label4: 'Finalizing sources analysis...',
        }
      },
    },
  },
  signup: {
    trustText: 'Millions trust AI products every day',
  },
  mobileVersion: {
    steps: {
      three: {
        title: 'Stay ahead<br/>of AI-generated<br/>content risks'
      }
    }
  },
};
