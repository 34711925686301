export default {
  steps: {
    one: {
      title: `Get unlimited <br/>access to AI assistant`,
      description: 'Welcome to AskGPT, powered by advanced AI models. Receive expert advice and personalized solutions instantly. Ask anything, anytime you need.',
      items: {
        one: {
          title: 'Content creation',
          description:
            'Write a Linkedin post to attract new leads for my business',
        },
        two: {
          title: 'Career',
          description:
            'Write persuasive ad copy for my new line of cowboy boots',
        },
        three: {
          title: 'Learning',
          description:
            'Give me a test to check my knowledge of Social Studies',
        },
        four: {
          title: 'Spirituality',
          description:
            'Can you provide me with my daily horoscope for Taurus?',
        },
      },
    },
    two: {
      title: `Get instant answers`,
      description: `From solving problems to satisfying curiosity, AskGPT delivers precise responses. Just ask.`,
      userMessage: `Help me prepare a speech to ask my boss for a promotion`,
      gptMessages: {
        message1: {
          title: 'Introduction:',
          message: `"Hello, thank you for taking the time to meet with me today. I appreciate the opportunity to discuss my career growth and potential within the company."`,
        },
        message2: {
          title: 'Highlight Your Achievements:',
          message: `"Over the past [duration], I have had the privilege of contributing to several key projects, including [mention specific project], where we achieved a [specific result]."`,
        },
        message3: {
          title: 'Vision for the Future:',
          message: `"I am passionate about [specific aspect of your job or company], and I believe that in a [desired position], I can make an even greater impact by [specific contributions]. I am eager to take on new challenges and continue driving our team towards success."`,
        },
      },
      items: {
        item1: 'Personal productivity',
        item2: 'Finance',
        item3: 'Learning and education',
        item4: 'Spirituality and religion',
        item5: 'Communication, content creation',
        item6: 'Mental wellbeing',
      },
    },
    three: {
      title: `AI-powered living`,
      description: `Redefine how you tackle challenges, communicate, and learn — with advanced technology in your hands.`,
      messages: {
        gpt1: `Hi 👋 How can I help you?`,
        user: `How do I start my own business?`,
        gpt2: `Starting your own business can be an exciting and rewarding venture.
                Here’s a step-by-step guide to help you get started…`,
      },
    },
    continue: 'Continue',
    poweredBy: 'Powered by',
    mobile: {
      one: {
        title: `Unlimited AI assistance<br/>powered by GPT`,
        description: `Welcome to AskGPT, your AI assistant<br/>powered by GPT and GPT-4o technology.<br/>Receive expert advice and personalized<br/>solutions instantly.<br/>Ask anything, anytime you need.`,
      },
      two: {
        title: `Get instant answers`,
        description: `From solving problems to satisfying curiosity,<br/>AskGPT delivers precise responses. Just ask.`,
      },
      three: {
        title: `AI-powered living`,
        description: `Redefine how you tackle challenges,<br/>communicate,  and learn — with advanced<br/>technology in your hands.`,
      },
    },
  },
  signup: {
    ourHappyUsers: 'HAPPY USERS',
    alreadySignedUp: 'Already Signed Up',
    gptTechnologyTrustedByMillions: 'Millions trust our product every day',
    makeAiYourEverydayAssistant: 'Make AI your everyday assistant',
    joinUsAndEnhance: 'Join us and enhance your life using an AI assistant',
    joinMillionsOfUsers: `Join millions of smart<br/>AI users`,
    enjoySecureSpamFreeAssistance: `Enjoy secure, spam-free assistance with peace of mind.`,
    enjoySecure: `Your data security is our priority`,
    continueWithOneOfThese: 'Continue with one of these',
    continueWith: 'Continue with {provider}',
    byCreatingAnAccountYouAgree: 'By creating an account, you agree to our',
    termOfUse: 'Terms of Use',
    and: 'and',
    privacyPolicy: 'Privacy & Cookie Statement ',
    doYouHaveAnAccount: 'Do you have an account?',
    login: 'Log in',
    testimonials: {
      user1: {
        user: 'jerseygirl5',
        title: '',
        paragraph: `Wow! It’s like living in the future! Ask any question and it comes up with a response in seconds and answers the question! So much better than googling and searching everywhere.`,
      },
      user2: {
        user: 'amazing',
        title: '',
        paragraph: `The app it’s absolutely amazing, it actually helps with all types of homework, I extremely recommend, I had helped me with my math and English homework`,
      },
      user3: {
        user: 'jerseygirl5',
        title: 'Wow!',
        paragraph: `It’s like living in the future! Ask any question and it comes up with a response in seconds and answers the question! So much better than googling and searching everywhere.`,
      },
      user4: {
        user: 'mylilphony1999',
        title: 'The most important aspect of this planet',
        paragraph: `The most incredible thing I’ve ever seen and used`,
      },
      user5: {
        user: 'Ashgang2162',
        title: 'Definitely helps my writers block',
        paragraph: `100%`,
      },
      user6: {
        user: 'CodeNameBianca',
        title: 'Loving it!',
        paragraph: `I’m astonished at how fast it comes up with answers!!!`,
      },
      user7: {
        user: 'nskdbffnrb',
        title: 'Good',
        paragraph: `The app offers a user-friendly interface that makes accessing AI assistance on the go incredibly convenient. However, there are sometimes slight delays in loading a response.`,
      },
      user8: {
        user: 'Mackarthur',
        title: 'Dope',
        paragraph: `This assistant is pretty legit actually: I was skeptic but y’all did it`,
      },
    },
  },
  login: {
    dontHaveAnAccount: `Don't have an account?`,
    signup: 'Sign up',
    continueToYourAccount: 'Continue to your account',
  },
  form: {
    email: 'Email',
    password: 'Password',
    continue: 'Continue',
  },
  mobileVersion: {
    poweredBy: 'Powered by',
    button: 'Continue',
    steps: {
      two: {
        userMessage:
          'Help me prepare a speech to ask my boss for a promotion',
        gptMessages: {
          title: 'Introduction',
          message1:
            '"Hello, thank you for taking the time to meet with me today. I appreciate the opportunity to discuss my career growth and potential within the company."',
          message2: '"Hello, thank you for taking the...',
        },
      },
    },
  },
};
