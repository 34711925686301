export default {
  active: "Activo",
  cancel: "Cancelar",
  canceled: "Cancelado",
  "delete": "Borrar",
  deleteAccount: "Eliminar cuenta",
  deleteAccountAndData: "Eliminar cuenta y datos",
  deleteAccountButton: "Eliminar cuenta",
  deleteAccountText: "¿Estás seguro de que quieres borrar la cuenta <br/> y los datos?",
  deleteSuccessful: "Su cuenta ha sido eliminada",
  emailAddress: "Correo​ electrónico",
  freePlan: "Plan​ gratuito",
  goPro: "HAZTE PRO",
  logOut: "Salir",
  ok: "OK",
  plan: "​plan",
  privacyPolicy: "Política de privacidad",
  renewsAt: "se​ renueva​ ​el",
  subscription: "Suscripción",
  termsOfUse: "Términos de uso",
  title: "Ajustes"
};
