export default {
  active: 'Active',
  cancel: 'Cancel',
  canceled: 'Canceled',
  emailAddress: 'Email address',
  freePlan: 'Free plan',
  goPro: 'GO PRO',
  logOut: 'Log Out',
  plan: 'plan',
  renewsAt: 'renews at',
  subscription: 'Subscription',
  title: 'Settings',
  deleteAccount: 'Excluir conta',
  deleteAccountAndData: 'Excluir conta e dados',
  deleteAccountButton: 'Excluir conta',
  deleteAccountText: `Tem certeza que deseja excluir a conta <br/> e os dados?`,
  delete: 'Excluir',
  deleteSuccessful: 'Sua conta foi excluída',
  ok: 'OK'
};
