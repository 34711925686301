export default {
  acceptButton: "Ça m'intéresse !",
  boostYourProductivityBy: "Boostez votre productivité,",
  declineButton: "Je ne veux pas recevoir de conseils ni d’informations.",
  description: "Recevez​ ​des​ ​conseils​ ​fiables,​ ​des​ ​mises​ ​à​ ​jour​ ​et<br/>des​ ​offres exclusives​ ​pour​ ​tirer​ ​le​ ​meilleur​ ​parti<br/>de​ ​votre​ ​assistance ​IA.",
  mobileVersion: {
    boostYourProductivityBy: "Boostez votre productivité,",
    by: "par",
    description: "Recevez​ ​des​ ​conseils​ ​fiables,​ ​des​ ​mises​ ​à​ ​jour​ ​et<br/>des​ ​offres exclusives​ ​pour​ ​tirer​ ​le​ ​meilleur​ ​parti<br/>de​ ​votre​ ​assistant​ ​IA."
  },
  upTo3x: "jusqu'à la multiplier par 3 !"
};
