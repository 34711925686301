export default {
  mobileVersion: {
    steps: {
      three: {
        title: "Gardez une longueur d'avance<br/>sur les risques liés au contenu<br/>généré par l'IA"
      }
    }
  },
  signup: {
    trustText: "Des millions de personnes font confiance aux produits d'IA chaque jour"
  },
  steps: {
    one: {
      description: "Identifier les textes rédigés par l'IA dans les travaux de recherche, les travaux d'étudiants et les documents professionnels.",
      leftSection: {
        description: "Saisissez un texte ici ou téléchargez un fichier pour vérifier la présence de contenu AI.",
        items: {
          one: "Coller le texte",
          three: "Télécharger le fichier",
          two: "Exemple de texte"
        },
        title: "Détecteur d'IA"
      },
      rightSection: {
        text1: "Détecter les contenus générés par l'IA avec une précision de 100 %.",
        text2: "Analyser des documents entiers pour en vérifier l'authenticité.",
        text3: "Obtenez des rapports détaillés avec des informations exploitables."
      },
      title: "Détecteur d'IA par AskGPT"
    },
    three: {
      description: "",
      leftSection: {
        min: "Min",
        steps: {
          label1: "Traitement des textes",
          label2: "Identifier les mêmes mots",
          label3: "Identifier les mots modifiés",
          label4: "Finalisation de l'analyse des sources..."
        },
        title: "Nous analysons votre contenu en ce moment"
      },
      rightSection: {
        text1: "Détecter les contenus générés par l'IA avant leur soumission.",
        text2: "Vérifier les travaux de recherche, les travaux d'étudiants et les documents professionnels.",
        text3: "Éviter l'utilisation accidentelle de textes rédigés par l'IA.",
        text4: "Assistant IA tout-en-un avec détection IA intégrée."
      },
      title: "Garder une longueur d'avance sur les risques liés au contenu généré par l'IA<br/>"
    },
    two: {
      description: "Comment cela fonctionne-t-il ?",
      leftSection: {
        everyDiscoveryPeelsBack: "Chaque découverte fait reculer une couche d'inconnu, mais à chaque réponse, de nouvelles questions émergent, nous rappelant que l'univers n'est pas seulement vaste, mais qu'il est infini dans ses mystères.",
        exoplanetsOrbitDistant: "Des exoplanètes en orbite autour d'étoiles lointaines, certaines",
        humanComprehensionWithBillions: "l'entendement humain. Avec des milliards de galaxies dispersées dans l'espace-temps, chacune d'entre elles peut abriter des secrets inimaginables, certains familiers, d'autres dépassant notre imagination.",
        potentiallyHarboringLife: "potentiellement de la vie, tandis que des signaux provenant de l'espace lointain évoquent des phénomènes inconnus.",
        scientistsEstimateThatOnly: "Les scientifiques estiment que seuls 5 % de l'univers sont constitués de matière connue, le reste demeurant une énigme d'énergie et de matière noires, des forces invisibles qui façonnent le cosmos. Les trous noirs tordent le tissu même de la réalité, avalant la lumière et le temps lui-même.",
        theUniverseIsAVastAndEver: "L'univers est un vaste mystère qui ne cesse de s'étendre, rempli de merveilles cosmiques qui dépassent l'entendement.",
        title: "Texte généré par l'IA"
      },
      rightSection: {
        step: "Étape",
        text1: "Téléchargez votre document ou collez votre texte.",
        text2: "Notre IA scanne et analyse votre contenu.",
        text3: "Recevoir instantanément un rapport d'authenticité détaillé."
      },
      title: "Détection rapide, fiable et<br/>sans effort de l'IA"
    }
  }
};
