export default {
  mobileVersion: {
    steps: {
      three: {
        title: "Vaya por delante<br/>de los riesgos de los contenidos generados por IA<br/>"
      }
    }
  },
  signup: {
    trustText: "Millones de personas confían cada día en los productos de IA"
  },
  steps: {
    one: {
      description: "Identifique textos escritos con IA en trabajos de investigación, presentaciones de estudiantes y documentos profesionales.",
      leftSection: {
        description: "Introduzca aquí el texto o cargue el archivo para comprobar el contenido AI.",
        items: {
          one: "Pegar texto",
          three: "Cargar archivo",
          two: "Texto de muestra"
        },
        title: "Detector de IA"
      },
      rightSection: {
        text1: "Detecte contenidos generados por IA con una precisión del 100%.",
        text2: "Analizar documentos completos para comprobar su autenticidad.",
        text3: "Obtenga informes detallados con información práctica."
      },
      title: "Detector de IA de AskGPT"
    },
    three: {
      description: "",
      leftSection: {
        min: "Min",
        steps: {
          label1: "Tratamiento de textos",
          label2: "Identificar las mismas palabras",
          label3: "Identificar las palabras modificadas",
          label4: "Finalizar el análisis de las fuentes..."
        },
        title: "Estamos analizando su contenido ahora"
      },
      rightSection: {
        text1: "Detecte los contenidos generados por IA antes de enviarlos.",
        text2: "Verificar trabajos de investigación, trabajos de estudiantes y documentos profesionales.",
        text3: "Evitar el mal uso accidental del texto escrito por la IA.",
        text4: "Asistente de IA todo en uno con detección de IA incorporada."
      },
      title: "Adelántese a los riesgos de los contenidos generados por IA-<br/>"
    },
    two: {
      description: "Cómo funciona:",
      leftSection: {
        everyDiscoveryPeelsBack: "Cada descubrimiento desvela una capa de lo desconocido, pero con cada respuesta surgen nuevas preguntas que nos recuerdan que el universo no sólo es vasto, sino infinito en sus misterios.",
        exoplanetsOrbitDistant: "Los exoplanetas orbitan estrellas lejanas, algunos",
        humanComprehensionWithBillions: "comprensión humana. Con miles de millones de galaxias esparcidas por el tejido del espacio-tiempo, cada una de ellas puede albergar secretos incalculables, algunos familiares, otros más allá de nuestra imaginación.",
        potentiallyHarboringLife: "potencialmente albergan vida, mientras que las señales del espacio profundo susurran fenómenos desconocidos.",
        scientistsEstimateThatOnly: "Los científicos calculan que sólo el 5% del universo está formado por materia conocida, mientras que el resto sigue siendo un rompecabezas de energía y materia oscuras, fuerzas invisibles que dan forma al cosmos. Los agujeros negros doblan el tejido mismo de la realidad, tragándose la luz y el tiempo mismo.",
        theUniverseIsAVastAndEver: "El universo es un misterio vasto y en constante expansión, repleto de maravillas cósmicas que van más allá de la imaginación.",
        title: "Texto generado por IA"
      },
      rightSection: {
        step: "Paso",
        text1: "Sube tu documento o pega tu texto.",
        text2: "Nuestra IA escanea y analiza su contenido.",
        text3: "Reciba al instante un informe de autenticidad detallado."
      },
      title: "Detección de IA rápida, fiable y sin esfuerzo<br/>"
    }
  }
};
