export default {
  clearText: "Texte en clair",
  copied: "Copié",
  copy: "Copie",
  dislike: "N'aime pas",
  enterTextHere: "Saisir le texte ici ...",
  like: "Comme",
  loadingText: "Nous analysons votre contenu en ce moment",
  yourOutputContent: "Votre contenu de sortie"
} 
