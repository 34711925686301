export default {
  form: {
    "continue": "Weiter",
    email: "E-Mail-Adresse",
    password: "Passwort"
  },
  login: {
    continueToYourAccount: "Weiter zu Ihrem Konto",
    dontHaveAnAccount: "Du hast kein Konto?",
    signup: "Registrieren"
  },
  mobileVersion: {
    button: "Weiter",
    poweredBy: "Angetrieben von",
    steps: {
      two: {
        gptMessages: {
          message1: "„Guten Tag und vielen Dank, dass Sie sich die Zeit für ein Gespräch mit mir nehmen. Ich freue mich über die Möglichkeit, meine berufliche Entwicklung und mein Potenzial innerhalb des Unternehmens zu erörtern.“",
          message2: "„Guten Tag und vielen Dank, dass...",
          title: "Vorstellung"
        },
        userMessage: "Hilf mir, eine kurze Rede vorzubereiten, in der ich um eine Beförderung bitte"
      }
    }
  },
  signup: {
    and: "und",
    byCreatingAnAccountYouAgree: "Mit dem Erstellen eines Kontos akzeptierst du unsere",
    continueWith: "Mit ​​{provider} anmelden",
    continueWithOneOfThese: "Melde dich an mit einer dieser Optionen",
    doYouHaveAnAccount: "Du hast schon ein Konto?",
    enjoySecureSpamFreeAssistance: "Genieße sichere Unterstützung – ohne Werbung.",
    gptTechnologyTrustedByMillions: "Millionen vertrauen unserem Produkt jeden Tag",
    joinMillionsOfUsers: "Werde Teil Millionen<br/>cleverer KI-Nutzer",
    login: "Anmelden",
    ourHappyUsers: "zufriedene Nutzer",
    privacyPolicy: "Datenschutz- und Cookie-Richtlinien ",
    termOfUse: "Nutzungsbedingungen",
    testimonials: {
      "[0]": {
        paragraph: "Wow! Es ist, als würde man in der Zukunft leben! Man kann irgendeine Frage stellen, und nach ein paar Sekunden kommt schon die Antwort! Viel besser als überall mit Google rumzusuchen.",
        title: "",
        user: "jerseygirl5"
      },
      "[1]": {
        paragraph: "Die App ist einfach der Wahnsinn, sie kann wirklich bei allen möglichen Hausaufgaben helfen. Ich würde sie auf jeden Fall empfehlen, mir hat sie schon bei meinen Hausaufgaben in Mathe und Englisch geholfen",
        title: "",
        user: "fantastisch"
      },
      "[2]": {
        paragraph: "Es ist, als würde man in der Zukunft leben! Man kann irgendeine Frage stellen, und nach ein paar Sekunden kommt schon die Antwort! Viel besser als überall mit Google rumzusuchen.",
        title: "Wow!",
        user: "jerseygirl5"
      },
      "[3]": {
        paragraph: "Das Unglaublichste, was ich je gesehen und benutzt habe",
        title: "Der wichtigste Aspekt dieses Planeten",
        user: "mylilphony1999"
      },
      "[4]": {
        paragraph: "100%",
        title: "Hilft auf jeden Fall bei der Blockade meiner Autoren",
        user: "Aschgang2162"
      },
      "[5]": {
        paragraph: "Ich bin erstaunt, wie schnell es Antworten gibt!!!",
        title: "Ich liebe es!",
        user: "CodeNameBianca"
      },
      "[6]": {
        paragraph: "Die App bietet eine benutzerfreundliche Oberfläche, die den Zugriff auf KI-Unterstützung unterwegs unglaublich bequem macht. Allerdings kommt es manchmal zu leichten Verzögerungen beim Laden einer Antwort.",
        title: "Gut",
        user: "nskdbffnrb"
      },
      "[7]": {
        paragraph: "Dieser Assistent ist eigentlich ziemlich echt: Ich war skeptisch, aber ihr habt es alle getan",
        title: "Aufputschmittel",
        user: "Mackarthur"
      },
      user1: {
        paragraph: "Wow! Es ist, als würde man in der Zukunft leben! Man kann irgendeine Frage stellen, und nach ein paar Sekunden kommt schon die Antwort! Viel besser als überall mit Google rumzusuchen.",
        title: "",
        user: "jerseygirl5"
      },
      user2: {
        paragraph: "Die App ist einfach der Wahnsinn, sie kann wirklich bei allen möglichen Hausaufgaben helfen. Ich würde sie auf jeden Fall empfehlen, mir hat sie schon bei meinen Hausaufgaben in Mathe und Englisch geholfen",
        title: "",
        user: "fantastisch"
      },
      user3: {
        paragraph: "Es ist, als würde man in der Zukunft leben! Man kann irgendeine Frage stellen, und nach ein paar Sekunden kommt schon die Antwort! Viel besser als überall mit Google rumzusuchen.",
        title: "Wow!",
        user: "jerseygirl5"
      },
      user4: {
        paragraph: "Das Unglaublichste, was ich je gesehen und benutzt habe",
        title: "Der wichtigste Aspekt dieses Planeten",
        user: "mylilphony1999"
      },
      user5: {
        paragraph: "100%",
        title: "Hilft auf jeden Fall bei der Blockade meiner Autoren",
        user: "Ashgang2162"
      },
      user6: {
        paragraph: "Ich bin erstaunt, wie schnell es Antworten gibt!!!",
        title: "Ich liebe es!",
        user: "CodeNameBianca"
      },
      user7: {
        paragraph: "Die App bietet eine benutzerfreundliche Oberfläche, die den Zugriff auf KI-Unterstützung unterwegs unglaublich bequem macht. Allerdings kommt es manchmal zu leichten Verzögerungen beim Laden einer Antwort.",
        title: "Gut",
        user: "nskdbffnrb"
      },
      user8: {
        paragraph: "Dieser Assistent ist eigentlich ziemlich echt: Ich war skeptisch, aber ihr habt es alle getan",
        title: "Aufputschmittel",
        user: "Mackarthur"
      }
    }
  },
  steps: {
    "continue": "Weiter",
    mobile: {
      one: {
        description: "Willkommen bei AskGPT,<br/>betrieben mit fortschrittlichen KI-Modellen.<br/>Erhalte jederzeit fundierte Ratschläge und personalisierte<br/>Lösungsvorschläge zu all deinen Fragen.<br/>Frag jederzeit, wenn du etwas brauchst.",
        title: "Erhalte unbegrenzten Zugriff<br/>auf den KI-Assistenten"
      },
      three: {
        description: "Bewältige Herausforderungen,<br/>kommuniziere und lerne auf neue Art und Weise – mit fortschrittlicher<br/>Technologie, die stets griffbereit ist.",
        title: "Ein KI-unterstützter Alltag"
      },
      two: {
        description: "Von gelösten Problemen bis zu gestillter Neugier<br/>– AskGPT liefert präzise Antworten. Einfach fragen.",
        title: "Erhalte umgehend Antworten"
      }
    },
    one: {
      description: "Willkommen bei AskGPT, betrieben mit fortschrittlichen KI-Modellen. Erhalte jederzeit fundierte Ratschläge und personalisierte Lösungsvorschläge zu all deinen Fragen. Frag jederzeit, wenn du etwas brauchst.",
      items: {
        four: {
          description: "Kannst du mir das Tageshoroskop für das Sternzeichen Stier geben?",
          title: "Spiritualität"
        },
        one: {
          description: "Verfasse einen LinkedIn-Post, mit dem ich neue Leads für mein Unternehmen gewinnen kann",
          title: "Inhalts­erstellung"
        },
        three: {
          description: "Gib mir einen Test, mit dem ich mein Wissen über die Sozialwissenschaften prüfen kann",
          title: "Lernen"
        },
        two: {
          description: "Schreib eine überzeugende Anzeige für meine neuen Cowboystiefel",
          title: "Karriere"
        }
      },
      title: "Erhalte<br/>unbegrenzten Zugriff<br/>auf den KI-Assistenten"
    },
    poweredBy: "Angetrieben von",
    three: {
      description: "Bewältige Herausforderungen, kommuniziere und lerne auf neue Art und Weise – mit fortschrittlicher Technologie, die stets griffbereit ist.",
      messages: {
        gpt1: "Hallo 👋 Wie kann ich dir helfen?",
        gpt2: "Ein eigenes Unternehmen zu gründen, kann zu einem spannenden und lohnenden Projekt werden. Hier ist eine Schritt-für-Schritt-Anleitung, die den Einstieg erleichtert …",
        user: "Wie gründe ich mein eigenes Unternehmen?"
      },
      title: "Ein KI-unterstützter Alltag"
    },
    two: {
      description: "Von gelösten Problemen bis zu gestillter Neugier – AskGPT liefert präzise Antworten. Einfach fragen.",
      gptMessages: {
        "[0]": {
          message: "„Guten Tag und vielen Dank, dass Sie sich die Zeit für ein Gespräch mit mir nehmen. Ich freue mich über die Möglichkeit, meine berufliche Entwicklung und mein Potenzial innerhalb des Unternehmens zu erörtern.“",
          title: "Vorstellung:"
        },
        "[1]": {
          message: "„In den vergangenen [Zeitraum] durfte ich an mehreren wichtigen Projekten mitwirken, darunter [spezifisches Projekt nennen], bei dem wir [spezifisches Ergebnis] erzielen konnten.”",
          title: "Herausstellen der eigenen Leistungen:"
        },
        "[2]": {
          message: "„Von [bestimmter Aspekt der Arbeit oder des Unternehmens] bin ich auch persönlich sehr überzeugt, und ich denke, dass ich als [gewünschte Position] durch [bestimmte Beiträge] noch mehr bewirken kann. Ich möchte mich gerne neuen Herausforderungen stellen und unser Team auch weiterhin zum Erfolg führen.“",
          title: "Vision für die Zukunft:"
        },
        message1: {
          message: "„Guten Tag und vielen Dank, dass Sie sich die Zeit für ein Gespräch mit mir nehmen. Ich freue mich über die Möglichkeit, meine berufliche Entwicklung und mein Potenzial innerhalb des Unternehmens zu erörtern.“",
          title: "Vorstellung:"
        },
        message2: {
          message: "„In den vergangenen [Zeitraum] durfte ich an mehreren wichtigen Projekten mitwirken, darunter [spezifisches Projekt nennen], bei dem wir [spezifisches Ergebnis] erzielen konnten.”",
          title: "Herausstellen der eigenen Leistungen:"
        },
        message3: {
          message: "„Von [bestimmter Aspekt der Arbeit oder des Unternehmens] bin ich auch persönlich sehr überzeugt, und ich denke, dass ich als [gewünschte Position] durch [bestimmte Beiträge] noch mehr bewirken kann. Ich möchte mich gerne neuen Herausforderungen stellen und unser Team auch weiterhin zum Erfolg führen.“",
          title: "Vision für die Zukunft:"
        }
      },
      items: {
        "[0]": {
          emoji: "📈",
          text: "Persönliche Produktivität"
        },
        "[1]": {
          emoji: "💰",
          text: "Finanzen"
        },
        "[2]": {
          emoji: "📚",
          text: "Lernen und Ausbildung"
        },
        "[3]": {
          emoji: "🙏",
          text: "Spiritualität und Religion"
        },
        "[4]": {
          emoji: "💬",
          text: "Kommunikation und Content-Erstellung"
        },
        "[5]": {
          emoji: "🧘",
          text: "Mentales Wohlbefinden"
        },
        item1: "Persönliche Produktivität",
        item2: "Finanzen",
        item3: "Lernen und Ausbildung",
        item4: "Spiritualität und Religion",
        item5: "Kommunikation und Content-Erstellung",
        item6: "Mentales Wohlbefinden"
      },
      title: "Erhalte umgehend Antworten",
      userMessage: "Hilf mir, eine kurze Rede vorzubereiten, in der ich um eine Beförderung bitte"
    }
  }
};
