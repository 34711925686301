export default {
  boostYourProductivityBy: 'Boost your productivity by',
  upTo3x: 'up to 3x!',
  description: `Get trusted tips, updates, and exclusive offers to make<br />the most of your AI assistant.`,
  acceptButton: 'I’m in!',
  declineButton: 'I don\'t want to receive tips or updates ',
  mobileVersion: {
    boostYourProductivityBy: 'Boost your productivity',
    by: 'by',
    description: `Get trusted tips, updates, and<br />exclusive offers to make the<br />most of your AI assistant.`,
  }
};
  