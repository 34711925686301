export default {
  title: 'Settings',
  emailAddress: 'Email address',
  subscription: 'Subscription',
  plan: 'plan',
  active: 'Active',
  canceled: 'Canceled',
  renewsAt: 'renews at',
  cancel: 'Cancel',
  freePlan: 'Free plan',
  goPro: 'GO PRO',
  logOut: 'Log Out',
  termsOfUse: 'Terms of Use',
  privacyPolicy: 'Privacy Policy',
  deleteAccount: 'Delete Account',
  deleteAccountAndData: 'Delete account and data',
  deleteAccountButton: 'Delete account',
  deleteAccountText: `Are you sure you want to delete account <br/> and data?`,
  delete: 'Delete',
  deleteSuccessful: 'Your account has been deleted',
  ok: 'Ok'
};
