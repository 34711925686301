const STANDARD_EVENTS = [
  'CompleteRegistration',
  'InitiateCheckout',
  'Lead',
  'PageView',
  'Purchase',
  'StartTrial',
];

const isStandardEvent = (event) => STANDARD_EVENTS.some((standardEvent) => {
  return standardEvent.toLowerCase() === event.toLowerCase();
});

const event = (name, data = {}, extraData = {}) => {
  if (!window.fbq) {
    console.error('[Meta Pixel] `window.fbq` is not defined');
    return;
  }

  const eventName = isStandardEvent(name) ? 'track' : 'trackCustom';

  window.fbq(eventName, name, data, extraData);
};

const generateGeneralEventId = (eventName) => {
  const timestamp = Math.round(Date.now() / (1000 * 60 * 60)); // hours

  return `event_${localStorage.getItem('visitor_id')}_${timestamp}_${eventName}`;
};

const generateStartTrialEventId = () => `event_${localStorage.getItem('visitor_id')}_StartTrial`;

const install = (app) => {
  app.config.globalProperties.$fbq = {
    event,
    generateGeneralEventId,
    generateStartTrialEventId,
  };
};

export default { install };
