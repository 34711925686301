export default {
  form: {
    'continue': 'Continuar',
    email: 'Email',
    password: 'Palavra-passe'
  },
  login: {
    continueToYourAccount: 'Continue e aceda à sua conta',
    dontHaveAnAccount: 'Não tem uma conta?',
    signup: 'Registe-se'
  },
  mobileVersion: {
    button: 'Continuar',
    poweredBy: 'Com tecnologia',
    steps: {
      two: {
        gptMessages: {
          message1: '"Olá. Obrigado(a) por reservar este tempo para se reunir comigo hoje. Agradeço-lhe esta oportunidade para discutir a minha progressão profissional e as minhas possibilidades dentro da empresa."',
          message2: '"Olá. Obrigado(a) por reservar este tempo...',
          title: 'Introdução:'
        },
        userMessage: 'Ajuda-me a preparar um discurso para pedir uma promoção ao meu chefe'
      }
    }
  },
  signup: {
    and: 'e',
    byCreatingAnAccountYouAgree: 'Ao criar uma conta, está a concordar com os nossos',
    continueWith: 'Continuar​•​com​•​{provider}',
    continueWithOneOfThese: 'Continue com um destes',
    doYouHaveAnAccount: 'Tem uma conta?',
    enjoySecureSpamFreeAssistance: 'Desfrute com tranquilidade de assistência segura e livre de spam.',
    gptTechnologyTrustedByMillions: 'A tecnologia GPT tem a confiança de milhões',
    joinMillionsOfUsers: 'Junte-se a milhões<br/>de utilizadores inteligentes da IA',
    login: 'Inicie sessão',
    ourHappyUsers: 'dos nossos felizes utilizadores',
    privacyPolicy: 'e com a nossa Política de Privacidade e Cookies',
    termOfUse: 'Termos do Serviço',
    testimonials: {
      '[0]': {
        paragraph: 'Uau! É como viver no futuro! Pode fazer qualquer pergunta e a resposta será apresentada em segundos! Muito melhor do que pesquisar no Google e procurar respostas em todo o lado.',
        title: '',
        user: 'jerseygirl5'
      },
      '[1]': {
        paragraph: 'A aplicação é absolutamente incrível. Consegue realmente ajudar em todos os tipos de trabalhos de casa. Recomendo vivamente. Ajudou-me nos meus trabalhos de casa de Matemática e Inglês',
        title: '',
        user: 'fantástica'
      },
      '[2]': {
        paragraph: 'É como viver no futuro! Pode fazer qualquer pergunta e a resposta será apresentada em segundos! Muito melhor do que pesquisar no Google e procurar respostas em todo o lado.',
        title: 'Uau!',
        user: 'jerseygirl5'
      },
      '[3]': {
        paragraph: 'The most incredible thing I’ve ever seen and used',
        title: 'The most important aspect of this planet',
        user: 'mylilphony1999'
      },
      '[4]': {
        paragraph: '100%',
        title: 'Definitely helps my writers block',
        user: 'Ashgang2162'
      },
      '[5]': {
        paragraph: 'I’m astonished at how fast it comes up with answers!!!',
        title: 'Adorei!',
        user: 'CodiNomeBianca'
      },
      '[6]': {
        paragraph: 'The app offers a user-friendly interface that makes accessing AI assistance on the go incredibly convenient. However, there are sometimes slight delays in loading a response.',
        title: 'Boa',
        user: 'nskdbffnrb'
      },
      '[7]': {
        paragraph: 'This assistant is pretty legit actually: I was skeptic but y’all did it',
        title: 'Dope',
        user: 'Mackarthur'
      },
      user1: {
        paragraph: 'Wow! It’s like living in the future! Ask any question and it comes up with a response in seconds and answers the question! So much better than googling and searching everywhere.',
        title: '',
        user: 'jerseygirl5'
      },
      user2: {
        paragraph: 'The app it’s absolutely amazing, it actually helps with all types of homework, I extremely recommend, I had helped me with my math and English homework',
        title: '',
        user: 'amazing'
      },
      user3: {
        paragraph: 'It’s like living in the future! Ask any question and it comes up with a response in seconds and answers the question! So much better than googling and searching everywhere.',
        title: 'Wow!',
        user: 'jerseygirl5'
      },
      user4: {
        paragraph: 'The most incredible thing I’ve ever seen and used',
        title: 'The most important aspect of this planet',
        user: 'mylilphony1999'
      },
      user5: {
        paragraph: '100%',
        title: 'Definitely helps my writers block',
        user: 'Ashgang2162'
      },
      user6: {
        paragraph: 'I’m astonished at how fast it comes up with answers!!!',
        title: 'Loving it!',
        user: 'CodeNameBianca'
      },
      user7: {
        paragraph: 'The app offers a user-friendly interface that makes accessing AI assistance on the go incredibly convenient. However, there are sometimes slight delays in loading a response.',
        title: 'Good',
        user: 'nskdbffnrb'
      },
      user8: {
        paragraph: 'This assistant is pretty legit actually: I was skeptic but y’all did it',
        title: 'Dope',
        user: 'Mackarthur'
      }
    }
  },
  steps: {
    'continue': 'Continuar',
    mobile: {
      one: {
        description: 'Bem-vindo ao AskGPT, desenvolvida com<br/>a tecnologia dos modelos GPT e GPT-4o.<br/>Obtenha instantaneamente conselhos de especialistas<br/>e soluções personalizadas.<br/>Pergunte tudo o que quiser, sempre que precisar.<br/>',
        title: 'Unlimited AI assistance<br/>powered by GPT'
      },
      three: {
        description: 'Redefina a forma como enfrenta os desafios,<br/>comunica e aprende — tenha à mão<br/>a tecnologia mais avançada.',
        title: 'Capacitar a nossa vida com a IA'
      },
      two: {
        description: 'Desde a resolução de problemas até à satisfação da sua curiosidade, <br/>a AskGPT dá-lhe respostas precisas. Basta perguntar.',
        title: 'Obtenha respostas instantâneas'
      }
    },
    one: {
      description: 'Bem-vindo ao AskChat, desenvolvida com a tecnologia dos modelos GPT e GPT-4o. Obtenha instantaneamente conselhos de especialistas e soluções personalizadas. Pergunte tudo o que quiser, sempre que precisar.',
      items: {
        four: {
          description: 'Podes mostrar-me o meu horóscopo diário para o signo de Touro?',
          title: 'Espiritualidade'
        },
        one: {
          description: 'Escreve uma publicação para o LinkedIn para atrair novos clientes para o meu negócio',
          title: 'Com a tecnologia do modelo GPT-4o'
        },
        three: {
          description: 'Faz-me um teste para avaliar os meus conhecimentos de Estudos Sociais',
          title: 'Aprendizagem'
        },
        two: {
          description: 'Escreve um texto persuasivo para um anúncio que promova a minha nova linha de botas de cowboy',
          title: 'Carreira'
        }
      },
      title: 'Obtenha acesso ilimitado<br/>ao ChatGPT'
    },
    poweredBy: 'Com a tecnologia',
    three: {
      description: 'Redefina a forma como enfrenta os desafios, comunica e aprende — tenha à mão a tecnologia mais avançada.',
      messages: {
        gpt1: 'Olá 👋 Como é que posso ajudar?',
        gpt2: 'Criar o seu próprio negócio pode ser um empreendimento emocionante e gratificante. Apresento-lhe aqui um guia passo a passo para o ajudar nas etapas iniciais…',
        user: 'Como é que posso criar o meu próprio negócio?'
      },
      title: 'Capacitar a nossa vida com a IA'
    },
    two: {
      description: 'Desde a resolução de problemas até à satisfação da sua curiosidade, a AskGPT dá-lhe respostas precisas. Basta perguntar.',
      gptMessages: {
        '[0]': {
          message: '"Olá. Obrigado(a) por reservar este tempo para se reunir comigo hoje. Agradeço-lhe esta oportunidade para discutir a minha progressão profissional e as minhas possibilidades dentro da empresa."',
          title: 'Introdução:'
        },
        '[1]': {
          message: '"Ao longo dos últimos [indicar um período], tive o privilégio de contribuir para vários projetos importantes, incluindo o projeto [mencionar um projeto específico] onde alcançámos um(a) [mencionar um resultado específico]."',
          title: 'Destacar aquilo que alcançou:'
        },
        '[2]': {
          message: '"Interesso-me muito por [mencionar um aspeto específico do seu trabalho ou da empresa] e acredito que na posição de [mencionar a posição desejada] posso ter um impacto ainda mais significativo ao [mencionar contributos específicos]. Estou muito motivado(a) para abraçar novos desafios e continuar a contribuir, orientando a nossa equipa, para o nosso sucesso."',
          title: 'Visão para o futuro:'
        },
        message1: {
          message: '"Hello, thank you for taking the time to meet with me today. I appreciate the opportunity to discuss my career growth and potential within the company."',
          title: 'Introduction:'
        },
        message2: {
          message: '"Over the past [duration], I have had the privilege of contributing to several key projects, including [mention specific project], where we achieved a [specific result]."',
          title: 'Highlight Your Achievements:'
        },
        message3: {
          message: '"I am passionate about [specific aspect of your job or company], and I believe that in a [desired position], I can make an even greater impact by [specific contributions]. I am eager to take on new challenges and continue driving our team towards success."',
          title: 'Vision for the Future:'
        }
      },
      items: {
        '[0]': {
          emoji: '📈',
          text: 'Produtividade pessoal'
        },
        '[1]': {
          emoji: '💰',
          text: 'Finanças'
        },
        '[2]': {
          emoji: '📚',
          text: 'Aprendizagem e educação'
        },
        '[3]': {
          emoji: '🙏',
          text: 'Espiritualidade e religião'
        },
        '[4]': {
          emoji: '💬',
          text: 'Comunicação, criação de conteúdos'
        },
        '[5]': {
          emoji: '🧘',
          text: 'Bem-estar e saúde mental'
        },
        item1: 'Personal productivity',
        item2: 'Finance',
        item3: 'Learning and education',
        item4: 'Spirituality and religion',
        item5: 'Mental wellbeing'
      },
      title: 'Obtenha respostas instantâneas',
      userMessage: 'Ajuda-me a preparar um discurso para pedir uma promoção ao meu chefe'
    }
  }
};
