export default {
  dallE3: 'Image Generation',
  dallE3V2: 'Image Generator',
  uploadAndAsk: 'Upload & Ask',
  uploadAndAskV2: 'Upload & Ask',
  aiHumanizer: 'AI Humanizer',
  aiDetector: 'AI Detector',
  linkAndAsk: 'Link & Ask',
  greatForMostTasks: 'Great for most tasks',
  usesAdvancedReasoning: 'Uses advanced reasoning',
  writeYourMessage: 'Write your message...',
  startNewChat: 'Start a new chat',
  chatHistory: 'Chat History',
  miniBotHub: 'Mini-bot hub',
  readyToUseAI: 'Ready-to-use AI mini-bots that simplify tasks and boost your efficiency.',
  promptLibrary: 'Prompt Library',
  promptLibraryHint: 'Explore prompts for your ideas',
  promptLibraryDescription: ` Discover and create custom versions of GPT that combine
    instructions, extra knowledge, <br />
    and any combination of skills.`,
  customizeGPTWithSkills: 'Customize GPT with the skills and knowledge you need.',
  recentlyUsed: 'Recently Used',
  pro: 'Pro',
  more: 'More',
  seeAll: 'See All',
  advancedTools: {
    title: 'Advanced tools',
    advanced: 'Advanced',
    tools: 'Tools',
    imageGeneration: {
      title: 'Image Generation',
      description: 'Create images using words',
    },
    linkAndAsk: {
      title: 'Link & Ask',
      description: 'Paste any link and ask',
    },
    uploadAndAsk: {
      title: 'Upload & Ask',
      description: 'Upload a PDF, use the info your way',
    },
  },
  message: {
    you: 'You',
    chat: 'Chat',
    error: 'Sorry, your request could not be processed. Please try again.',
    imageGeneration: 'Your image is being generated...',
    regenerate: 'Regenerate',
    download: 'Download',
  },
  aiHumanizerChat: {
    aiTextHumanizer: 'AI Text Humanizer',
    description: 'Refine AI-generated content to match a human writing style.',
  },
  aiDetectorChat: {
    aiTextDetector: 'AI Text Detector',
    description: 'Paste your text to check the AI-generated percentage.',
  },
  emptyChatGPT: {
    grok: 'Grok',
    gpt: 'OpenAI GPT-4o-mini',
    gpt4o: 'OpenAI GPT-4o',
    gemini: 'Gemini',
    claude: 'Claude',
    deepSeek: 'DeepSeek',
    title: 'How can I help you today?',
    hereToHelpWithAnyQuestions: "I'm here to help you with any questions.",
    gpt4oMiniDescription:
      'Your go-to assistant for a wide range of tasks. From drafting emails and generating in-depth reports to managing your calendar and brainstorming ideas.',
    gpt4oDescription: 'A powerful assistant for tackling complex tasks and providing detailed answers. Perfect for generating insights, analyzing information, and solving challenging problems.',
    geminiDescription: 'Your assistant for research, problem-solving, and creativity. Great for analyzing data, answering tough questions, and generating content across multiple languages.',
    claudeDescription: 'A clear thinker for complex tasks. Perfect for summarizing long texts, refining ideas, and tackling complex topics with clarity.',
    deepSeekDescription: 'A highly accurate AI model designed for precise, detailed, and insightful responses. Ideal for problem-solving, research, and in-depth analysis.',
    grokDescription: 'A powerful assistant for tackling complex tasks and providing detailed answers. Perfect for generating insights, analyzing information, and solving challenging problems.',
  },
  emptyImageGeneration: {
    title: 'Create images using words',
    youCanStartConvarsation:
      'You can start a conversation from the message box below or try the following examples:',
    imageGeneration: 'Image Generation',
    imageGenerationTabDescription: `Unleash the magic of AI with Image Generation. Instantly
        transform your words into stunning, mesmerizing visuals.
        Embark on a creative odyssey—experience power of imagination
        in every pixel.
        <br />
        <br />
        You can start a conversation from the message box below or
        try the following examples:`,
    items: {
      image1: `Create a neon-lit skyline<br/> in a futuristic cyberpunk world.`,
      image2: `Picture a cozy cabin in a snowy<br/> forest at dusk.`,
      image3: `Design a stylish, small bathroom<br/> that's cute and functional.`,
      image4: `Imagine friends and pets<br/> enjoying a sunny picnic together.`,
    },
    imageGenerator: 'Image Generator',
    turnYourIdeasIntoEye: 'Turn your ideas into eye-catching visuals — just type a short description.'
  },
  emptyLinkAndAsk: {
    linkAndAsk: 'Link & Ask',
    title: 'Paste any link and ask',
    linkAndAskTabDescription:
      "Enter a URL and get answers to any question related to the page's content.",
    pasteLinkHere: 'Paste a link here',
  },
  emptyUploadAndAsk: {
    uploadAndAsk: 'Upload & Ask',
    uploadAndAskTabDescription: `Search or ask anything in your document.<br /><br />
        From legal agreements to financial reports, Upload & Ask
        tool lets you interact with your PDFs seamlessly. Upload a
        PDF, ask questions, get summaries, and extract data
        instantly using AI.`,
    helpToYouWithQuestions: "I'm here to help you with any questions.",
    clickToUpload: 'Click to Upload',
    format: 'format, max.',
    uploading: 'Uploading...',
    changeDocument: 'Change document',
    orDragAndDrop: 'or Drag and Drop',
    max: 'Max',
    uploadPDFUseInfoYourWay: 'Upload a PDF, use the info your way',
    onlyPDFFilesAreAllowed: 'Only PDF files are allowed.',
    uploadFileInCorrectFormat: 'Upload file in the correct format (pdf, xlsx, csv, txt, doc, docx).',
    fileSizeShouldNotExceed: 'File size should not exceed',
    uploadPDFAskQuestions: 'Upload files, ask questions, get summaries, and extract data instantly using AI.',
    uploadAndAskPDF: 'Upload & Ask',
    only: 'Only'
  },
  burgerMenu: {
    startConversation: 'Start Conversation',
    settings: 'Settings',
    logout: 'Logout',
  },
  mobileVersion: {
    myAccount: 'My Account',
    tools: 'Tools',
    history: 'History',
    save: 'Save',
    today: 'Today',
    lastWeek: 'Last 7 days',
    lastMonth: 'Last 30 days',
    lastYear: 'Last year',
    allTime: 'All time',
    header: {
      startNewChat: 'Start New Chat',
    },
  },
  goPro: 'Go PRO',
  newChat: 'New Chat',
  chats: 'Chats'
};
