export default {
  advancedTools: {
    advanced: "Fortgeschrittene",
    imageGeneration: {
      description: "Bilder aus Worten erstellen",
      title: "Bildgenerierung"
    },
    linkAndAsk: {
      description: "Beliebigen Link einfügen und fragen",
      title: "Verlinken und fragen"
    },
    title: "Fortgeschrittene Tools",
    tools: "Tools",
    uploadAndAsk: {
      description: "PDF hochladen, Infos nach Bedarf nutzen",
      title: "Hochladen und fragen"
    }
  },
  aiHumanizer: "AI Humanizer",
  aiHumanizerChat: {
    aiTextHumanizer: "AI Text Humanizer",
    description: "Verfeinern Sie KI-generierte Inhalte so, dass sie einem menschlichen Schreibstil entsprechen."
  },
  burgerMenu: {
    logout: "Abmelden",
    settings: "Einstellungen",
    startConversation: "Konversation​ starten"
  },
  chat: {
    startNewChat: "Neue Unterhaltung beginnen"
  },
  chatHistory: "Chatverlauf",
  chats: "Chats",
  customizeGPTWithSkills: "Passen Sie GPT mit den Fähigkeiten und Kenntnissen an, die Sie benötigen.",
  dallE3: "Bildgenerierung",
  dallE3V2: "Bildgenerator",
  emptyChatGPT: {
    claude: "Claude",
    claudeDescription: "Ein klarer Denker für komplexe Aufgaben. Perfekt, um lange Texte zusammenzufassen, Ideen zu verfeinern und komplexe Themen mit Klarheit anzugehen.",
    deepSeek: "DeepSeek",
    deepSeekDescription: "Ein hochpräzises KI-Modell, das für präzise, detaillierte und aufschlussreiche Antworten entwickelt wurde. Ideal für Problemlösungen, Forschung und tiefgreifende Analysen.",
    gemini: "Gemini",
    geminiDescription: "Dein Assistent für Recherche, Problemlösung und Kreativität. Ideal für die Analyse von Daten, die Beantwortung schwieriger Fragen und die Erstellung von Inhalten in mehreren Sprachen.",
    gpt: "OpenAI GPT-4o-mini",
    gpt4o: "OpenAI GPT-4o",
    gpt4oDescription: "Ein leistungsstarker Assistent, der komplexe Aufgaben bewältigt und detaillierte Antworten liefert. Perfekt für die Gewinnung von Erkenntnissen, die Analyse von Informationen und das Lösen anspruchsvoller Probleme.",
    gpt4oMiniDescription: "Dein zuverlässiger Assistent für eine Vielzahl von Aufgaben. Vom Verfassen von E-Mails und Erstellen detaillierter Berichte bis hin zur Kalenderverwaltung und Ideenfindung.",
    gptTabDescription: "Dein zuverlässiger Assistent für eine Vielzahl von Aufgaben. Vom Verfassen von E-Mails und Erstellen detaillierter Berichte bis hin zur Kalenderverwaltung und Ideenfindung.",
    grok: "Grok",
    grokDescription: "Ein leistungsstarker Assistent, der komplexe Aufgaben bewältigt und detaillierte Antworten liefert. Perfekt für die Gewinnung von Erkenntnissen, die Analyse von Informationen und das Lösen anspruchsvoller Probleme.",
    hereToHelpWithAnyQuestions: "Ich bin hier, um Ihnen bei allen Fragen zu helfen.",
    title: "Wie kann ich dir heute helfen?"
  },
  emptyImageGeneration: {
    imageGeneration: "Bildgenerierung",
    imageGenerationTabDescription: "Die Bildgenerierung entfesselt die Magie der KI. Eigene Worte werden sofort in faszinierende, fesselnde Bilder verwandelt. Eine kreative Reise wartet – mit der Kraft der eigenen Vorstellung in jedem Pixel. <br /><br /> Die Beispiele können ausprobiert, oder ein Chat in der Mitteilungsbox gestartet werden:",
    imageGenerator: "Bildgenerator",
    items: {
      image1: "Kreiere eine neonbeleuchtete Skyline<br/> in einer futuristischen Cyberpunkwelt.",
      image2: "Stell dir eine gemütliche Hütte in einem verschneiten<br/> Wald in der Abenddämmerung vor.",
      image3: "Entwirf ein stilvolles, kleines Badezimmer,<br/> das hübsch und praktisch ist.",
      image4: "Stell dir vor, wie Freunde und Haustiere<br/> gemeinsam ein sonniges Picknick genießen."
    },
    title: "Bilder aus Worten erstellen",
    turnYourIdeasIntoEye: "Mache aus deinen Ideen ansprechende Bilder – gib einfach eine kurze Beschreibung ein.",
    youCanStartConvarsation: "Du kannst eine Nachricht über das folgende Feld eingeben oder die nachstehenden Beispiele nutzen:"
  },
  emptyLinkAndAsk: {
    linkAndAsk: "Verlinken und fragen",
    linkAndAskTabDescription: "Gib einen Link ein und erhalte Antworten auf alle Fragen zum Seiteninhalt.",
    pasteLinkHere: "Link hier einfügen",
    title: "Beliebigen Link einfügen und fragen"
  },
  emptyUploadAndAsk: {
    changeDocument: "Dokument ersetzen",
    clickToUpload: "Zum Hochladen klicken",
    fileSizeShouldNotExceed: "Die Datei darf nicht größer sein",
    format: "format,​ max.",
    helpToYouWithQuestions: "Ich bin hier, um Ihnen bei allen Fragen zu helfen.",
    max: "Max",
    only: "Nur",
    onlyPDFFilesAreAllowed: "Es sind nur PDF-Dateien erlaubt",
    orDragAndDrop: "oder ziehen und ablegen",
    uploadAndAsk: "Hochladen und fragen",
    uploadAndAskPDF: "Hochladen &amp; Fragen",
    uploadAndAskTabDescription: "Suche im Dokument oder stelle Fragen dazu.<br​ ​/><br​ /> Von rechtlichen Verträgen bis hin zu Finanzberichten – die „Hochladen und fragen“- Funktion erleichtert dir die Arbeit mit PDFs. Lade ein PDF hoch und stelle Fragen – die KI liefert sofort Zusammenfassungen und extrahiert Daten.",
    uploadFileInCorrectFormat: "Laden Sie die Datei im richtigen Format hoch (pdf, xlsx, csv, txt, doc, docx).",
    uploadPDFAskQuestions: "Laden Sie Dateien hoch, stellen Sie Fragen, erhalten Sie Zusammenfassungen, und extrahieren Sie Daten sofort mithilfe von KI.",
    uploadPDFUseInfoYourWay: "PDF hochladen, Infos nach Bedarf nutzen",
    uploading: "Wird hochgeladen …"
  },
  goPro: "PRO NUTZEN",
  greatForMostTasks: "Ideal für die meisten Aufgaben",
  linkAndAsk: "Verlinken​ und​ fragen",
  message: {
    chat: "Chat",
    download: "Herunterladen",
    error: "Ihre Anfrage konnte leider nicht bearbeitet werden. Bitte versuchen Sie es erneut.",
    imageGeneration: "Ihr Bild wird gerade erstellt...",
    regenerate: "Regenerieren",
    you: "Du"
  },
  miniBotHub: "Mini-Roboter-Nabe",
  mobileVersion: {
    allTime: "Seit Beginn",
    header: {
      startNewChat: "Neue Unterhaltung beginnen"
    },
    history: "Verlauf",
    lastMonth: "Letzte 30 Tage",
    lastWeek: "Letzte 7 Tage",
    lastYear: "Letztes Jahr",
    myAccount: "Mein​ ​Konto",
    save: "Spare",
    today: "Heute",
    tools: "Tools"
  },
  more: "Mehr",
  newChat: "Neuer Chat",
  pro: "Pro",
  promptLibrary: "Prompt-Kollektion",
  promptLibraryDescription: "Individuelle Versionen von ChatGPT entdecken, die Anweisungen, Extrawissen <br​ ​/> und beliebige Skill-Kombinationen vereinen.",
  promptLibraryHint: "Prompts für eigene Ideen entdecken",
  readyToUseAI: "Sofort einsatzbereite KI-Mini-Bots, die Aufgaben vereinfachen und Ihre Effizienz steigern.",
  recentlyUsed: "Kürzlich Verwendet",
  seeAll: "Alles ansehen",
  startNewChat: "Neue Unterhaltung beginnen",
  uploadAndAsk: "Hochladen und Fragen",
  uploadAndAskV2: "Hochladen &amp; Fragen",
  usesAdvancedReasoning: "Nutzt fortschrittliches Denken",
  writeYourMessage: "Text eingeben..."
};
