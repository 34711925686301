export default {
  mobileVersion: {
    button: "Weiter",
    poweredBy: "angetrieben von",
    steps: {
      two: {
        gptMessages: {
          message1: "\"Hallo, vielen Dank, dass Sie sich die Zeit genommen haben, sich heute mit mir zu treffen. Ich weiß es zu schätzen, dass Sie mir die Gelegenheit geben, über meine berufliche Entwicklung und mein Potenzial im Unternehmen zu sprechen.\"",
          message2: "\"Hallo, danke, dass Sie sich die Zeit nehmen...",
          title: "Einführung"
        },
        userMessage: "Helfen Sie mir, eine Rede vorzubereiten, um meinen Chef um eine Beförderung zu bitten"
      }
    }
  },
  signup: {
    trustText: "Jeden Tag vertrauen Millionen Menschen auf KI-Produkte"
  },
  steps: {
    "continue": "Weiter",
    mobile: {
      one: {
        description: "Willkommen beim KI-Chat-Assistenten, angetrieben von Grok von xAI. Erhalten Sie sofort messerscharfe, fachmännisch angepasste Lösungen. Fragen Sie alles, jederzeit.",
        title: "Unbegrenzter Zugang<br/>zu Grok"
      },
      three: {
        description: "Definieren Sie neu, wie Sie Herausforderungen angehen,<br/>kommunizieren und lernen - mit fortschrittlicher<br/>Technologie in Ihren Händen.",
        title: "KI-gestütztes Leben"
      },
      two: {
        description: "Von der Lösung von Problemen bis zur Befriedigung der Neugier - Ihr KI-Chat-Assistent liefert präzise Antworten. Fragen Sie einfach.",
        title: "Sofortige Antworten erhalten"
      }
    },
    one: {
      description: "Willkommen beim KI-Chat-Assistenten, angetrieben von Grok von xAI. Erhalten Sie sofort messerscharfe, fachmännisch angepasste Lösungen. Fragen Sie alles, jederzeit.",
      items: {
        four: {
          description: "Können Sie mir mein Tageshoroskop für Stier zur Verfügung stellen?",
          title: "Spiritualität"
        },
        one: {
          description: "Einen Linkedin-Beitrag schreiben, um neue Kontakte für mein Unternehmen zu gewinnen",
          title: "Angetrieben von Grok AI"
        },
        three: {
          description: "Geben Sie mir einen Test, um mein Wissen über Sozialkunde zu überprüfen",
          title: "Lernen"
        },
        two: {
          description: "Schreiben Sie einen überzeugenden Werbetext für meine neue Kollektion von Cowboystiefeln",
          title: "Karriere"
        }
      },
      title: "Unbegrenzter <br/>Zugang zu Grok"
    },
    poweredBy: "angetrieben von",
    three: {
      description: "Definieren Sie neu, wie Sie Herausforderungen angehen, kommunizieren und lernen - mit fortschrittlicher Technologie in Ihren Händen.",
      messages: {
        gpt1: "Hallo 👋 Wie kann ich Ihnen helfen?",
        gpt2: "Die Gründung eines eigenen Unternehmens kann ein aufregendes und lohnendes Unterfangen sein. Hier ist eine Schritt-für-Schritt-Anleitung, die Ihnen den Einstieg erleichtert...",
        user: "Wie gründe ich mein eigenes Unternehmen?"
      },
      title: "KI-gestütztes Leben"
    },
    two: {
      description: "Von der Lösung von Problemen bis zur Befriedigung der Neugier - Ihr KI-Chat-Assistent liefert präzise Antworten. Fragen Sie einfach.",
      gptMessages: {
        message1: {
          message: "\"Hallo, vielen Dank, dass Sie sich die Zeit genommen haben, sich heute mit mir zu treffen. Ich weiß es zu schätzen, dass Sie mir die Gelegenheit geben, über meine berufliche Entwicklung und mein Potenzial im Unternehmen zu sprechen.\"",
          title: "Einleitung:"
        },
        message2: {
          message: "\"Im Laufe der letzten [Dauer] hatte ich das Privileg, an mehreren wichtigen Projekten mitzuwirken, darunter [spezifisches Projekt nennen], bei dem wir ein [spezifisches Ergebnis] erzielt haben.",
          title: "Heben Sie Ihre Errungenschaften hervor:"
        },
        message3: {
          message: "\"Ich engagiere mich mit Leidenschaft für [bestimmter Aspekt Ihrer Tätigkeit oder Ihres Unternehmens] und glaube, dass ich in einer [gewünschten Position] durch [bestimmte Beiträge] einen noch größeren Beitrag leisten kann. Ich freue mich darauf, neue Herausforderungen anzunehmen und unser Team weiter zum Erfolg zu führen.\"",
          title: "Vision für die Zukunft:"
        }
      },
      items: {
        item1: "Persönliche Produktivität",
        item2: "Finanzen",
        item3: "Lernen und Bildung",
        item4: "Spiritualität und Religion",
        item5: "Kommunikation, Erstellung von Inhalten",
        item6: "Seelisches Wohlbefinden"
      },
      title: "Sofortige Antworten erhalten",
      userMessage: "Helfen Sie mir, eine Rede vorzubereiten, um meinen Chef um eine Beförderung zu bitten"
    }
  }
};
