export default {
  mobileVersion: {
    button: "Continua",
    poweredBy: "Alimentato da",
    steps: {
      two: {
        gptMessages: {
          message1: "\"Salve, grazie per aver trovato il tempo di incontrarmi oggi. Apprezzo l'opportunità di discutere della mia crescita professionale e del mio potenziale all'interno dell'azienda\".",
          message2: "\"Salve, grazie per aver preso...",
          title: "INTRODUZIONE"
        },
        userMessage: "Aiutatemi a preparare un discorso per chiedere al mio capo una promozione"
      }
    }
  },
  signup: {
    trustText: "Milioni di persone si fidano ogni giorno dei prodotti AI"
  },
  steps: {
    "continue": "Continua",
    mobile: {
      one: {
        description: "Benvenuti nell'assistente di chat AI, alimentato da Grok di xAI. Ottenete immediatamente soluzioni taglienti e personalizzate da esperti. Chiedete qualsiasi cosa, in qualsiasi momento.",
        title: "Ottenere l'accesso illimitato<br/>a Grok"
      },
      three: {
        description: "Ridefinite il modo di affrontare le sfide, di comunicare su<br/>e di imparare, con la tecnologia avanzata di<br/>nelle vostre mani.",
        title: "Vivere con l'intelligenza artificiale"
      },
      two: {
        description: "Dalla risoluzione di problemi alla soddisfazione di curiosità, l'assistente di chat AI fornisce risposte precise. Basta chiedere.",
        title: "Ottenere risposte immediate"
      }
    },
    one: {
      description: "Benvenuti nell'assistente di chat AI, alimentato da Grok di xAI. Ottenete immediatamente soluzioni taglienti e personalizzate da esperti. Chiedete qualsiasi cosa, in qualsiasi momento.",
      items: {
        four: {
          description: "Può fornirmi l'oroscopo giornaliero per il Toro?",
          title: "Spiritualità"
        },
        one: {
          description: "Scrivere un post su Linkedin per attirare nuovi contatti per la mia azienda",
          title: "Alimentato da Grok AI"
        },
        three: {
          description: "Mi dia un test per verificare la mia conoscenza degli studi sociali.",
          title: "Apprendimento"
        },
        two: {
          description: "Scrivere un testo pubblicitario persuasivo per la mia nuova linea di stivali da cowboy",
          title: "Carriera"
        }
      },
      title: "Ottenete un accesso illimitato a Grok su <br/>"
    },
    poweredBy: "Alimentato da",
    three: {
      description: "Ridefinite il modo in cui affrontate le sfide, comunicate e imparate, con la tecnologia avanzata nelle vostre mani.",
      messages: {
        gpt1: "Ciao 👋 Come posso aiutarti?",
        gpt2: "Avviare un'attività in proprio può essere un'impresa entusiasmante e gratificante. Ecco una guida passo passo per aiutarvi a iniziare...",
        user: "Come posso avviare un'attività in proprio?"
      },
      title: "Vivere con l'intelligenza artificiale"
    },
    two: {
      description: "Dalla risoluzione di problemi alla soddisfazione di curiosità, l'assistente di chat AI fornisce risposte precise. Basta chiedere.",
      gptMessages: {
        message1: {
          message: "\"Salve, grazie per aver trovato il tempo di incontrarmi oggi. Apprezzo l'opportunità di discutere della mia crescita professionale e del mio potenziale all'interno dell'azienda\".",
          title: "Introduzione:"
        },
        message2: {
          message: "\"Nell'ultima [durata], ho avuto il privilegio di contribuire a diversi progetti chiave, tra cui [citare il progetto specifico], in cui abbiamo raggiunto un [risultato specifico]\".",
          title: "Evidenziate i vostri successi:"
        },
        message3: {
          message: "\"Sono appassionato di [aspetto specifico del tuo lavoro o della tua azienda] e credo che con il ruolo di [ruolo desiderato], potrò avere un impatto ancora maggiore mediante [contributi specifici]. Sono ansioso di affrontare nuove sfide e continuare a guidare il nostro team verso il successo\".",
          title: "Visione del futuro:"
        }
      },
      items: {
        item1: "Produttività personale",
        item2: "Finanza",
        item3: "Apprendimento e istruzione",
        item4: "Spiritualità e religione",
        item5: "Comunicazione, creazione di contenuti",
        item6: "Benessere mentale"
      },
      title: "Ottenere risposte immediate",
      userMessage: "Aiutatemi a preparare un discorso per chiedere al mio capo una promozione"
    }
  }
};
