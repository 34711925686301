export default {
  clearText: "Klarer Text",
  copied: "Kopiert",
  copy: "Kopieren",
  dislike: "Nicht mögen",
  enterTextHere: "Text hier eingeben ...",
  like: "wie",
  loadingText: "Wir analysieren jetzt Ihren Inhalt",
  yourOutputContent: "Ihr Ausgabeinhalt"
} 
