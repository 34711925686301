export default {
  detectAI: "Détecter l'IA",
  placeholder: 'Collez votre texte ici...',
  resultWillAppearHere: 'Les résultats apparaîtront ici<br/> après le balayage',
  significantAIContentFound: 'Un contenu IA significatif a été trouvé',
  values: {
    four: {
      description: "Votre texte semble avoir été rédigé par une IA. Pour répondre aux normes académiques et professionnelles, réécrivez les parties clés en utilisant vos propres idées, en restructurant les phrases générées par l'IA et en vous assurant que le contenu reflète votre style et votre compréhension uniques.",
      title: "Principalement générés par l'IA"
    },
    one: {
      description: "Votre texte semble avoir été rédigé en grande partie par des humains. Seule une petite partie peut contenir des éléments générés par l'IA. Si nécessaire, révisez les sections clés pour assurer la cohérence du ton et du style.",
      title: 'Essentiellement rédigés par des êtres humains'
    },
    three: {
      description: "Votre texte est en grande partie généré par l'IA. Pour une touche plus originale et personnelle, réécrivez les sections à forte teneur en IA en y apportant votre propre point de vue et en y ajoutant des idées ou des exemples uniques.",
      title: 'Un contenu IA significatif a été trouvé'
    },
    two: {
      description: "Votre texte contient un mélange de contenu humain et de contenu généré par l'IA. Pour renforcer l'authenticité, reformulez les sections générées par l'IA avec vos propres mots et affinez le ton pour qu'il corresponde à votre voix.",
      title: "Une certaine influence de l'IA a été détectée"
    }
  },
  yourTextIsLargely: "Votre texte est en grande partie généré par l'IA. Pour une touche plus originale et personnelle, réécrivez les sections à forte teneur en IA en y apportant votre propre point de vue et en y ajoutant des idées ou des exemples uniques."
};
