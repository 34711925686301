export default {
  dialogueSaved: "Dialogo salvato",
  mobileVersion: {
    four: {
      items: {
        "[0]": {
          emoji: "💥",
          text: "Produttività"
        },
        "[1]": {
          emoji: "📚",
          text: "Apprendimento"
        },
        "[2]": {
          emoji: "📈",
          text: "Vendite"
        },
        "[3]": {
          emoji: "🚀",
          text: "Lavoro"
        },
        "[4]": {
          emoji: "🔬",
          text: "Dati"
        },
        "[5]": {
          emoji: "🎭",
          text: "Svago"
        },
        "[6]": {
          emoji: "🎨",
          text: "Arte"
        },
        item1: "Produttività",
        item2: "Apprendimento",
        item3: "Vendite",
        item4: "Lavoro",
        item5: "Dati",
        item6: "Svago",
        item7: "Arte"
      }
    },
    three: {
      message: "Crea una serie di paesaggi da sogno, ognuno dei quali rappresenta una stagione diversa"
    },
    two: {
      message: "Crea opere d'arte basate sull'intelligenza artificiale combinando schemi di circuiti con fiori vivaci"
    }
  },
  model: "Immagine generata",
  poweredBy: "Alimentato da",
  steps: {
    four: {
      description: "Oltre agli strumenti di generazione di immagini IA, AskGPT offre una suite completa di potenti servizi IA, tra cui assistenza chat intelligente, analisi di link web e analisi di testo PDF.",
      gptMessage: "L'esplorazione dello spazio ha compiuto molta strada da quando il primo occhio umano si è rivolto al cielo colmo di stupore.<br/>I suoi traguardi hanno segnato l’audace viaggio dell’umanità al di là del nostro pianeta, aprendo la strada a un futuro pieno di straordinarie possibilità.<br/>Questo testo analizza i principali risultati ottenuti nell'esplorazione spaziale e quello che ci aspetta...",
      items: {
        "[0]": {
          emoji: "🎨",
          text: "Arte"
        },
        "[1]": {
          emoji: "🚀",
          text: "Lavoro"
        },
        "[2]": {
          emoji: "📚",
          text: "Apprendimento"
        },
        "[3]": {
          emoji: "📈",
          text: "Vendite"
        },
        "[4]": {
          emoji: "💥",
          text: "Produttività"
        },
        "[5]": {
          emoji: "🔬",
          text: "Dati"
        },
        "[6]": {
          emoji: "🎭",
          text: "Svago"
        },
        item1: "Arte",
        item2: "Lavoro",
        item3: "Apprendimento",
        item4: "Vendite",
        item5: "Produttività",
        item6: "Dati",
        item7: "Svago"
      },
      title: "Toolkit IA avanzato",
      userMessage: "Scrivi un testo di 1.000 parole sui traguardi raggiunti dall'esplorazione spaziale e sul suo futuro"
    },
    one: {
      description: "Ti diamo il benvenuto su AskGPT.<br/>Grazie a modelli IA avanzati, trasforma le tue idee più audaci in immagini straordinarie senza sforzo e senza limiti.",
      items: {
        four: {
          description: "Design moderno per il logo di una startup tecnologica",
          title: "Branding aziendale"
        },
        one: {
          description: "Cappello da mago da cui fuoriescono galassie",
          title: "Genera immagini con l'intelligenza artificiale"
        },
        three: {
          description: "Quadro astratto minimalista per un soggiorno",
          title: "Crea opere d'arte personalizzate"
        },
        two: {
          description: "Illustrazione per blog: scalatore sulla cima di una montagna",
          title: "Visualizza le tue idee"
        }
      },
      title: "Dall'immaginazione all'immagine <br​ class='hidden​ ​xxl:block'/>con la creatività dell'intelligenza artificiale"
    },
    three: {
      description: "AskGPT ti permette di creare arte personalizzata, design di moda, personaggi di gioco e contenuti visivi per il marketing. Grazie all'intelligenza artificiale all'avanguardia, trasforma le tue idee in realtà senza sforzo.",
      title: "Visualizza qualsiasi cosa<br/>con versatili strumenti<br/>di intelligenza artificiale",
      userMessage1: "Crea una serie di paesaggi da sogno, ognuno dei quali rappresenta una stagione diversa",
      userMessage2: "Genera una serie di interni per soggiorno, ciascuno con uno stile di rendering unico"
    },
    two: {
      description: "Crea istantaneamente straordinarie immagini con AskGPT. Sperimenta la generazione rapida di immagini premium e sfrutta infinite possibilità creative.",
      items: {
        "[0]": {
          emoji: "🎨",
          text: "Arte con AI"
        },
        "[1]": {
          emoji: "🪄",
          text: "Loghi con AI"
        },
        "[2]": {
          emoji: "📸",
          text: "Foto con AI"
        },
        "[3]": {
          emoji: "🖋️",
          text: "Tatuaggi con AI"
        },
        "[4]": {
          emoji: "🌈",
          text: "Poster con AI"
        },
        "[5]": {
          emoji: "📖",
          text: "Da​ ​testo​ ​a​ ​immagine"
        },
        "[6]": {
          emoji: "🔁",
          text: "Da immagine a immagine"
        },
        item1: "Arte con AI",
        item2: "Loghi con AI",
        item3: "Foto con AI",
        item4: "Tatuaggi con AI",
        item5: "Poster con AI",
        item6: "Da testo a immagine",
        item7: "Da immagine a immagine"
      },
      title: "Immagini basate sull'intelligenza<br/>artificiale di alta qualità<br/>in pochi secondi",
      userMessage: "Crea opere d'arte basate sull'intelligenza artificiale combinando schemi di circuiti con fiori vivaci"
    }
  }
};
