export default {
  acceptButton: "Ich​ bin​ ​dabei!",
  boostYourProductivityBy: "Steigere deine Produktivität​ um",
  declineButton: "Ich möchte keine Tipps oder Updates erhalten ",
  description: "Erhalte​ vertrauenswürdige​ ​Tipps,​ ​Updates​ ​und​ ​exklusive​ ​Angebote,<br />um ​das​ ​Beste​ ​aus​ ​deinem​ ​KI-Assistenten​ ​herauszuholen.",
  mobileVersion: {
    boostYourProductivityBy: "Steigern​ Sie​ ​Ihre​ ​Produktivität",
    by: "um",
    description: "Erhalte​ vertrauenswürdige​ ​Tipps,​ ​Updates​<br />​und​ ​exklusive​ ​Angebote,<br />um ​das​ ​Beste​ ​aus​ ​deinem​ ​KI-Assistenten​ ​herauszuholen."
  },
  upTo3x: "bis zu 3x!"
};
