export default {
  detectAI: 'AI erkennen',
  placeholder: 'Fügen Sie Ihren Text hier ein...',
  resultWillAppearHere: 'Die Ergebnisse werden nach dem Scannen hier angezeigt<br/> ',
  significantAIContentFound: 'Signifikanter AI-Gehalt gefunden',
  values: {
    four: {
      description: 'Ihr Text scheint von einer künstlichen Intelligenz geschrieben worden zu sein. Um akademischen und professionellen Standards zu entsprechen, sollten Sie wichtige Teile mit Ihren eigenen Gedanken umschreiben, die von der KI generierten Sätze umstrukturieren und sicherstellen, dass der Inhalt Ihren eigenen Stil und Ihr eigenes Verständnis widerspiegelt.',
      title: 'Überwiegend KI-generiert'
    },
    one: {
      description: 'Ihr Text scheint größtenteils von Menschen geschrieben zu sein. Nur ein kleiner Teil enthält möglicherweise KI-generierte Elemente. Überprüfen Sie bei Bedarf wichtige Abschnitte, um einen einheitlichen Ton und Stil zu gewährleisten.',
      title: 'Meistens von Menschen geschrieben'
    },
    three: {
      description: 'Ihr Text ist weitgehend KI-generiert. Für eine originellere und persönlichere Note sollten Sie KI-lastige Abschnitte mit Ihrer eigenen Perspektive umschreiben und einzigartige Erkenntnisse oder Beispiele hinzufügen.',
      title: 'Signifikanter AI-Gehalt gefunden'
    },
    two: {
      description: 'Ihr Text enthält eine Mischung aus menschlichen und KI-generierten Inhalten. Um die Authentizität zu erhöhen, formulieren Sie KI-generierte Abschnitte in Ihren eigenen Worten um und passen Sie den Tonfall an Ihre Stimme an.',
      title: 'Gewisser AI-Einfluss festgestellt'
    }
  },
  yourTextIsLargely: 'Ihr Text ist weitgehend KI-generiert. Für eine originellere und persönlichere Note sollten Sie KI-lastige Abschnitte mit Ihrer eigenen Perspektive umschreiben und einzigartige Erkenntnisse oder Beispiele hinzufügen.'
};
