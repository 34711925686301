export default {
  dialogueSaved: 'Diálogo gravado',
  mobileVersion: {
    four: {
      items: {
        '[0]': {
          emoji: '💥',
          text: 'Produtividade'
        },
        '[1]': {
          emoji: '📚',
          text: 'Aprendizagem'
        },
        '[2]': {
          emoji: '📈',
          text: 'Vendas'
        },
        '[3]': {
          emoji: '🚀',
          text: 'Trabalho'
        },
        '[4]': {
          emoji: '🔬',
          text: 'Dados'
        },
        '[5]': {
          emoji: '🎭',
          text: 'Diversão'
        },
        '[6]': {
          emoji: '🎨',
          text: 'Arte'
        },
        item1: 'Productivity',
        item2: 'Learn',
        item3: 'Sales',
        item4: 'Work',
        item5: 'Data',
        item6: 'Fun',
        item7: 'Art'
      }
    },
    three: {
      message: 'Cria um conjunto de paisagens de sonho, cada uma representando uma estação diferente'
    },
    two: {
      message: 'Cria arte de IA combinando padrões de circuito com flores vibrantes'
    }
  },
  model: 'Imagem Criada',
  poweredBy: 'Com tecnologia',
  steps: {
    four: {
      description: 'Além de ferramentas de imagem de IA, o AskGPT oferece uma série de poderosos serviços de IA, incluindo ChatGPT, GPT-4, GPT-4o, Carregar e Perguntar, e Ask PDF.',
      gptMessage: 'A exploração espacial percorreu um longo caminho desde que o primeiro olhar humano se voltou para o céu em admiração.<br/><br/>Os seus marcos assinalaram a arrojada jornada da humanidade para além da Terra e abriram caminho para um futuro repleto de possibilidades imensas.<br/><br/>Esta redação aborda as principais conquistas da exploração espacial e o que está para vir...',
      items: {
        '[0]': {
          emoji: '🎨',
          text: 'Arte'
        },
        '[1]': {
          emoji: '🚀',
          text: 'Trabalho'
        },
        '[2]': {
          emoji: '📚',
          text: 'Aprendizagem'
        },
        '[3]': {
          emoji: '📈',
          text: 'Vendas'
        },
        '[4]': {
          emoji: '💥',
          text: 'Produtividade'
        },
        '[5]': {
          emoji: '🔬',
          text: 'Dados'
        },
        '[6]': {
          emoji: '🎭',
          text: 'Diversão'
        },
        item1: 'Art',
        item2: 'Work',
        item3: 'Learn',
        item4: 'Sales',
        item5: 'Productivity',
        item6: 'Data',
        item7: 'Fun'
      },
      title: 'Conjunto avançado de ferramentas de IA',
      userMessage: 'Escreve uma redação de 1000 palavras sobre os marcos e o futuro da exploração espacial'
    },
    one: {
      description: 'Bem-vindo ao AskGPT.<br/>Com tecnologia ChatGPT e DALL-E 3, transforme facilmente as suas ideias arrojadas em imagens deslumbrantes — sem limites.',
      items: {
        four: {
          description: 'Desenho de um logótipo moderno para uma startup de tecnologia',
          title: 'Imagem de uma empresa'
        },
        one: {
          description: 'Chapéu de mágico com galáxias a fluir para fora',
          title: 'Crie imagens com IA'
        },
        three: {
          description: 'Pintura abstrata minimalista para uma sala de estar',
          title: 'Crie obras de arte personalizadas'
        },
        two: {
          description: 'Ilustração de um blogue: alpinista no pico de uma montanha',
          title: 'Veja as suas ideias'
        }
      },
      title: "Da imaginação para a imagem<br class='hidden xxl:block'/>com a criatividade de IA"
    },
    three: {
      description: 'O AskGPT permite-lhe criar arte, moda, personagens de jogo e imagens de marketing personalizadas. Com a tecnologia DALL-E 3, dê vida às suas ideias com facilidade.',
      title: 'Veja tudo com<br/>ferramentas de IA versáteis',
      userMessage1: 'Cria um conjunto de paisagens de sonho, cada uma representando uma estação diferente',
      userMessage2: 'Gera um conjunto de interiores de salas de estar, cada um com um estilo de renderização único'
    },
    two: {
      description: 'Crie imagens deslumbrantes imediatamente com o AskGPT. Experimente uma criação de imagens rápida e premium e explore possibilidade criativas sem fim.',
      items: {
        '[0]': {
          emoji: '🎨',
          text: 'Arte​•​de​•​IA'
        },
        '[1]': {
          emoji: '🪄',
          text: 'Logótipos de IA'
        },
        '[2]': {
          emoji: '📸',
          text: 'Fotos​•​de​•​IA'
        },
        '[3]': {
          emoji: '🖋️',
          text: 'Tatuagens​•​de​•​IA'
        },
        '[4]': {
          emoji: '🌈',
          text: 'Cartazes​•​de​•​IA'
        },
        '[5]': {
          emoji: '📖',
          text: 'Texto​•​para​•​imagem'
        },
        '[6]': {
          emoji: '🔁',
          text: 'Imagem​•​para​•​imagem'
        },
        item1: 'AI art',
        item2: 'AI logos',
        item3: 'AI photos',
        item4: 'AI tattoos',
        item5: 'Text to image',
        item6: 'Image to image'
      },
      title: 'Imagens de IA de elevada qualidade<br/>em segundos',
      userMessage: 'Cria arte de IA combinando padrões de circuito com flores vibrantes'
    }
  }
};
