export default {
  checkout: {
    "continue": "Continuer",
    discount: "de réduction",
    easierFasterAndSaferWayToPay: "Un moyen de paiement plus facile, plus rapide et plus sécurisé",
    orPayByCard: "Ou payer par carte",
    title: "Paiement",
    total: "Total",
    totalPerDiscount: "Coût par jour"
  },
  "continue": "Continuer",
  plans: {
    and: "et",
    appName: "AskGPT​ ​Pro",
    byProceedingWithThePurchase: "En souscrivant à un forfait, vous acceptez",
    mostPopular: "Le plus populaire",
    oneMonth: "1 mois",
    oneYear: "1 an",
    paySafe: "Payez en toute sécurité",
    perDay: "Par jour",
    privacyPolicy: "notre Politique de confidentialité",
    refund: "notre Politique d’annulation et de remboursement.",
    save: "économisez",
    subtitle: "Débloquez tout le potentiel de AskGPT Pro",
    subtitleDeepSeek: "Exploitez tout le potentiel de votre assistant de chat IA",
    subtitleDetector: "Exploitez tout le potentiel de l'application AskGPT",
    subtitleGrok: "Exploitez tout le potentiel de votre assistant de chat IA",
    termsOfService: "Conditions d’utilisation",
    threeMonths: "3 mois",
    title: "Mettre à jour<br/>vers AskGPT Pro",
    titleDeepSeek: "Mettez à jour<br/>vers l'assistant de chat AI Pro",
    titleGrok: "Mettez à jour<br/>vers l'assistant de chat AI Pro",
    to: "​à",
    toOur: "nos",
    upgrade: "Passer à"
  },
  poweredBy: "Développé par",
  prompts: {
    benefits: {
      "[0]": {
        description: "Des projets professionnels aux choses du quotidien",
        title: "Plus de 350 solutions sur mesure"
      },
      "[1]": {
        description: "Accédez instantanément à des solutions sur mesure pour relever vos défis",
        title: "Des réponses personnalisées en un clic"
      },
      "[2]": {
        description: "Votre assistant personnel et gratuit pour tous les aspects de votre vie",
        title: "Bot GPT universel"
      },
      item1: {
        description: "Des projets professionnels aux choses du quotidien",
        title: "Plus de 350 solutions sur mesure"
      },
      item2: {
        description: "Accédez instantanément à des solutions sur mesure pour relever vos défis",
        title: "Des réponses personnalisées en un clic"
      },
      item3: {
        description: "Votre assistant personnel et gratuit pour tous les aspects de votre vie",
        title: "Bot IA universel"
      }
    },
    boostYourProductivityBy: "Boostez votre productivité,",
    expertCraftedPrompts: "des requêtes créées par des experts",
    getLifetimeAccessOurExpert: "Obtenez un accès à vie à notre bibliothèque de requêtes expertes !",
    now: "maintenant",
    off: "DE RÉDUCTION",
    oneTimePaymentForLifetimeAccess: "Un paiement unique pour un accès à vie !",
    paySafe: "Paiement sécurisé",
    proposedPlans: {
      pro: {
        items: {
          "[0]": "10-15 min",
          "[1]": "3-4 heures",
          "[2]": "20-30 min",
          "[3]": "10-15 min",
          "[4]": "20 min",
          item1: "10-15 min",
          item2: "3-4 heures",
          item3: "20-30 min",
          item4: "10-15 min",
          item5: "20​ ​min"
        },
        newTitle: "Avec nos robots d'IA personnalisés<br/> ",
        title: "Avec nos requêtes basées sur les recherches"
      },
      standart: {
        items: {
          "[0]": "1-2 heures",
          "[1]": "8-10 heures",
          "[2]": "2-3 heures",
          "[3]": "1-2 heures",
          "[4]": "1-2 heures",
          item1: "1-2 heures",
          item2: "8-10 heures",
          item3: "2-3 heures",
          item4: "1-2 heures",
          item5: "1-2 heures"
        },
        title: "Avec <br/> AI Bot standard"
      }
    },
    save: "Économisez",
    superchargeYour: "Dopez votre",
    superchargeYourRoutine: "Optimisez votre routine",
    title: "Dopez votre <br​ ​class=\"lg:hidden\"​ />ChatGPT-4 avec <br​ ​class=\"lg:hidden\"​ />des requêtes créées par des experts, <br​ class=\"lg:hidden\"​ />maintenant <span​ ​class=\"text-[#DC2728]\">avec 80 % DE RÉDUCTION !</span>",
    topicsForRequests: {
      "[0]": "🏋 Créer un programme d’entraînement personnalisé",
      "[1]": "📸 Apprendre les bases du montage photo",
      "[2]": "🎥 Écrire un script pour une vidéo",
      "[3]": "🥗 Proposer un programme de menus hebdomadaire",
      "[4]": "📝 Répondre à 10 e-mails",
      topic1: "🏋 Créer un programme d’entraînement personnalisé",
      topic2: "📸 Apprendre les bases du montage photo",
      topic3: "🎥 Écrire un script pour une vidéo",
      topic4: "🥗 Proposer un programme de menus hebdomadaire",
      topic5: "📝 Répondre à 10 e-mails"
    },
    totalToday: "Total aujourd’hui",
    upgrade: "Passer",
    upgradeToExpert: "Passer à des messages experts",
    "with": "avec",
    withCustomAIBots: "avec des robots IA personnalisés,"
  },
  purchaseBenefits: {
    free: "gratuit",
    items: {
      advancedTools: {
        description: "Accéder à des outils d'IA avancés tels que le générateur d'images",
        title: "Débloquer les outils avancés"
      },
      gpt: {
        description: "Discuter avec l’assistant IA",
        title: "Accès à OpenAI GPT-4o mini"
      },
      gpt4o: {
        description: "OpenAI GPT-4o, Gemini, Claude",
        title: "Accès à des modèles avancés"
      },
      noLimits: {
        description: "Dialogues illimités avec tous les modèles d’IA",
        title: "Aucune limite"
      }
    },
    itemsDeepSeek: {
      advancedTools: {
        description: "Accéder à des outils d'IA avancés tels que le générateur d'images",
        title: "Débloquer les outils avancés"
      },
      deepSeek: {
        description: "Essayez le modèle avancé de DeepSeek AI",
        title: "Accès à DeepSeek"
      },
      gpt4o: {
        description: "OpenAI GPT-4o, Gemini, Claude",
        title: "Accès à de multiples modèles :"
      },
      noLimits: {
        description: "Dialogues illimités avec tous les modèles d'IA",
        title: "Pas de limites"
      }
    },
    itemsDetector: {
      advancedTools: {
        description: "Accéder à des outils avancés tels que le générateur d'images",
        title: "Débloquer les outils avancés"
      },
      gpt: {
        description: "Chat avec la technologie OpenAI",
        title: "Accès à OpenAI GPT-4o mini"
      },
      gpt4o: {
        description: "Détection de l'IA précise à 100",
        title: "Détecteur de contenu AI"
      },
      noLimits: {
        description: "Dialogues illimités avec tous les modèles d'IA",
        title: "Pas de limites"
      }
    },
    itemsGrok: {
      advancedTools: {
        description: "Accéder à des outils d'IA avancés tels que le générateur d'images",
        title: "Débloquer les outils avancés"
      },
      gpt4o: {
        description: "OpenAI GPT-4o, Gemini, Claude",
        title: "Accès à des modèles avancés :"
      },
      grok: {
        description: "Chat avec le modèle Grok de xAI",
        title: "Accès à Grok"
      },
      noLimits: {
        description: "Dialogues illimités avec tous les modèles d'IA",
        title: "Pas de limites"
      }
    },
    pro: "pro"
  },
  successModal: {
    backToChat: "Retour au chat",
    hasBeenSuccessfullyActivated: "a bien été activé.",
    plan: "Forfait",
    title: "Le paiement a bien été validé !",
    yourPro: "Votre Pro"
  }
};
