export default {
  loadingText: 'We’re analyzing your content now',
  clearText: 'Clear text',
  enterTextHere: 'Enter text here ...',
  yourOutputContent: 'Your output content',
  copy: 'Copy',
  copied: 'Copied',
  like: 'Like',
  dislike: 'Dislike',
  humanize: 'Humanize',
  chars: 'chars'
};