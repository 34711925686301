export default {
  advancedTools: {
    advanced: "Avanzadas",
    imageGeneration: {
      description: "Crea imágenes usando palabras",
      title: "Generar imágenes"
    },
    linkAndAsk: {
      description: "Pega cualquier enlace y pregunta",
      title: "Enlazar y preguntar"
    },
    title: "Herramientas​ ​avanzadas",
    tools: "Herramientas",
    uploadAndAsk: {
      description: "Sube un PDF y utiliza la información como quieras",
      title: "Subir y preguntar"
    }
  },
  aiHumanizer: "Humanizador AI",
  aiHumanizerChat: {
    aiTextHumanizer: "Humanizador de texto AI",
    description: "Perfeccione los contenidos generados por la IA para que coincidan con el estilo de redacción humano."
  },
  burgerMenu: {
    logout: "Cerrar sesión",
    settings: "Ajustes",
    startConversation: "Empezar una conversación"
  },
  chat: {
    startNewChat: "Empezar otra conversación"
  },
  chatHistory: "Historial​ de​ chat",
  chats: "Chats",
  customizeGPTWithSkills: "Personalice GPT con las habilidades y conocimientos que necesita.",
  dallE3: "Generar imágenes",
  dallE3V2: "Generador de imágenes",
  emptyChatGPT: {
    claude: "Claude",
    claudeDescription: "Un pensador claro para tareas complejas. Perfecto para resumir textos largos, refinar ideas y abordar temas complejos con claridad.",
    deepSeek: "DeepSeek",
    deepSeekDescription: "Un modelo de IA de gran precisión diseñado para obtener respuestas precisas, detalladas y perspicaces. Ideal para la resolución de problemas, la investigación y el análisis en profundidad.",
    gemini: "Gemini",
    geminiDescription: "Su asistente para la investigación, la resolución de problemas y la creatividad. Ideal para analizar datos, responder a preguntas difíciles y generar contenidos en varios idiomas.",
    gpt: "OpenAI GPT-4o-mini",
    gpt4o: "OpenAI GPT-4o",
    gpt4oDescription: "Un potente asistente para abordar tareas complejas y ofrecer respuestas detalladas. Perfecto para generar ideas, analizar información y resolver problemas complejos.",
    gpt4oMiniDescription: "Tu asistente de confianza para una amplia variedad de tareas. Desde redactar correos electrónicos y generar informes detallados hasta gestionar tu calendario y hacer lluvia de ideas.",
    gptTabDescription: "Tu asistente de confianza para una amplia variedad de tareas. Desde redactar correos electrónicos y generar informes detallados hasta gestionar tu calendario y hacer lluvia de ideas.",
    grok: "Grok",
    grokDescription: "Un potente asistente para abordar tareas complejas y ofrecer respuestas detalladas. Perfecto para generar ideas, analizar información y resolver problemas complejos.",
    hereToHelpWithAnyQuestions: "Estoy aquí para ayudarte con cualquier pregunta.",
    title: "¿Cómo te puedo ayudar hoy?"
  },
  emptyImageGeneration: {
    imageGeneration: "Generar imágenes",
    imageGenerationTabDescription: "Da rienda suelta a la magia de la IA con «Generar imágenes». Transforma al instante palabras en imágenes asombrosas y fascinantes. Embárcate en una travesía creativa: vive el poder de la imaginación en cada píxel. <br​ ​/><br​ ​/> Puedes empezar una nueva conversación desde el cuadro de mensaje que aparece más abajo o probar con los siguientes ejemplos:",
    imageGenerator: "Generador de imágenes",
    items: {
      image1: "Crea un horizonte iluminado<br/>por neones en un mundo<br/>ciberpunk futurista",
      image2: "Imagina una cabaña acogedora<br/>en un bosque nevado al anochecer.",
      image3: "Diseña un baño pequeño,<br/> pero lleno de estilo, que sea bonito y funcional.",
      image4: "Imagina a amigos y mascotas<br/> disfrutando juntos de un pícnic soleado."
    },
    title: "Crea imágenes usando palabras",
    turnYourIdeasIntoEye: "Convierte tus ideas en imágenes llamativas: sólo tienes que escribir una breve descripción.",
    youCanStartConvarsation: "Puedes iniciar una nueva conversación desde el cuadro de mensaje que aparece a continuación o probar con los siguientes ejemplos:"
  },
  emptyLinkAndAsk: {
    linkAndAsk: "Enlazar​ ​y​ ​preguntar",
    linkAndAskTabDescription: "Introduce una URL y obtén respuestas a cualquier pregunta relacionada con el contenido de la página.",
    pasteLinkHere: "Pega un enlace aquí",
    title: "Pega​ cualquier​ ​enlace​ ​y​ ​pregunta"
  },
  emptyUploadAndAsk: {
    changeDocument: "Cambiar documento",
    clickToUpload: "Haga clic para cargar",
    fileSizeShouldNotExceed: "El tamaño del archivo no debe exceder",
    format: "formato,​ máx.",
    helpToYouWithQuestions: "Estoy aquí para ayudarte con cualquier pregunta.",
    max: "Max",
    only: "Sólo",
    onlyPDFFilesAreAllowed: "Sólo se admiten archivos PDF.",
    orDragAndDrop: "o arrastrar y soltar",
    uploadAndAsk: "Subir​ y​ ​preguntar",
    uploadAndAskPDF: "Subir y preguntar",
    uploadAndAskTabDescription: "Busca o pregunta cualquier cosa sobre tu documento.<br​ ​/><br​ ​/> Desde acuerdos legales hasta informes financieros, la herramienta «Subir y preguntar» te permite interactuar con tus PDF sin complicaciones. Sube un PDF, haz preguntas, obtén resúmenes y extrae datos al instante utilizando la IA.",
    uploadFileInCorrectFormat: "Suba el archivo en el formato correcto (pdf, xlsx, csv, txt, doc, docx).",
    uploadPDFAskQuestions: "Cargue archivos, formule preguntas, obtenga resúmenes y extraiga datos al instante utilizando IA.",
    uploadPDFUseInfoYourWay: "Sube un PDF y utiliza la información como quieras",
    uploading: "Cargando…"
  },
  goPro: "Hazte PRO",
  greatForMostTasks: "Ideal para la mayoría de las tareas",
  linkAndAsk: "Enlazar​ y​ ​preguntar",
  message: {
    chat: "Chat",
    download: "Descargar",
    error: "Lo sentimos, su solicitud no ha podido ser procesada. Por favor, inténtelo de nuevo.",
    imageGeneration: "Su imagen se está generando...",
    regenerate: "Regenere",
    you: "Tú"
  },
  miniBotHub: "Concentrador de minirobots",
  mobileVersion: {
    allTime: "Siempre",
    header: {
      startNewChat: "Empezar otra conversación"
    },
    history: "Historial",
    lastMonth: "Últimos​ ​30​ días",
    lastWeek: "Últimos​ ​7​ ​días",
    lastYear: "Año pasado",
    myAccount: "Mi​ ​cuenta",
    save: "Ahorra un",
    today: "Hoy",
    tools: "Herramientas"
  },
  more: "Más",
  newChat: "Nuevo chat",
  pro: "Pro",
  promptLibrary: "Biblioteca de prompt",
  promptLibraryDescription: "Descubre y crea versiones personalizadas de ChatGPT que combinen instrucciones, conocimientos adicionales <br​ /> y cualquier combinación de habilidades.",
  promptLibraryHint: "Explora prompts para tus ideas",
  readyToUseAI: "Minirobots de inteligencia artificial listos para usar que simplifican las tareas y aumentan su eficacia.",
  recentlyUsed: "Usado Recientemente",
  seeAll: "Ver​ todo",
  startNewChat: "Empezar otra conversación",
  uploadAndAsk: "Subir y preguntar",
  uploadAndAskV2: "Subir y preguntar",
  usesAdvancedReasoning: "Utiliza el razonamiento avanzado",
  writeYourMessage: "Escribe tu mensaje…"
};
