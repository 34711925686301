export default {
  dialogueSaved: "Diálogo guardado",
  mobileVersion: {
    four: {
      items: {
        "[0]": {
          emoji: "💥",
          text: "Productividad"
        },
        "[1]": {
          emoji: "📚",
          text: "Aprender"
        },
        "[2]": {
          emoji: "📈",
          text: "Ventas"
        },
        "[3]": {
          emoji: "🚀",
          text: "Trabajo"
        },
        "[4]": {
          emoji: "🔬",
          text: "Datos"
        },
        "[5]": {
          emoji: "🎭",
          text: "Diversión"
        },
        "[6]": {
          emoji: "🎨",
          text: "Arte"
        },
        item1: "Productividad",
        item2: "Aprender",
        item3: "Ventas",
        item4: "Trabajo",
        item5: "Datos",
        item6: "Diversión",
        item7: "Arte"
      }
    },
    three: {
      message: "Crea una serie de paisajes de ensueño, donde cada uno represente una época distinta"
    },
    two: {
      message: "Crea arte de IA combinando modelos de circuitos con flores vibrantes"
    }
  },
  model: "Imagen​ generada",
  poweredBy: "Desarrollado por",
  steps: {
    four: {
      description: "Aparte de la generación de imágenes con IA, AskGPT ofrece un paquete completo de potentes servicios de IA, que incluye asistencia inteligente por chat, revisión de enlaces web y análisis de textos de PDF.",
      gptMessage: "La exploración del espacio ha avanzado mucho desde que la primera mirada humana se fijara en el cielo maravillada.<br/><br/>Sus hitos han dejado guella en el atrevido viaje de la humanidad más allá de la Tierra y han preparado el camino para un futuro lleno de posibilidades inmensas.<br/><br/>Este ensayo explora los logros clave en la exploración espacial y lo que queda por delante...",
      items: {
        "[0]": {
          emoji: "🎨",
          text: "Arte"
        },
        "[1]": {
          emoji: "🚀",
          text: "Trabajo"
        },
        "[2]": {
          emoji: "📚",
          text: "Aprender"
        },
        "[3]": {
          emoji: "📈",
          text: "Ventas"
        },
        "[4]": {
          emoji: "💥",
          text: "Productividad"
        },
        "[5]": {
          emoji: "🔬",
          text: "Datos"
        },
        "[6]": {
          emoji: "🎭",
          text: "Diversión"
        },
        item1: "Arte",
        item2: "Trabajo",
        item3: "Aprender",
        item4: "Ventas",
        item5: "Productividad",
        item6: "Datos",
        item7: "Diversión"
      },
      title: "Herramientas avanzadas de IA",
      userMessage: "Escribe un ensayo de 1000 palabras sobre los hitos y el futuro de la exploración del espacio"
    },
    one: {
      description: "Te damos la bienvenida a AskGPT.<br/>Aprovecha nuestra avanzada tecnología de IA para transformar tus ideas más brillantes en imágenes impresionantes sin esfuerzo y sin límites.",
      items: {
        four: {
          description: "Diseño de logotipo moderno para una empresa emergente tecnológica",
          title: "Marca de negocio"
        },
        one: {
          description: "El sombrero de un mago, de donde brotan galaxias",
          title: "Genera imágenes con IA"
        },
        three: {
          description: "Pintura abstracta minimalista para una sala de estar",
          title: "Crea obras de arte personalizadas"
        },
        two: {
          description: "Ilustración de blog: escalador en la cima de una montaña",
          title: "Visualiza tus ideas"
        }
      },
      title: "De la imaginación a la imagen <br class='hidden xxl:block'/>con creatividad de IA"
    },
    three: {
      description: "AskGPT te permite crear tu propio arte, diseños de moda, personajes de juegos o contenido gráfico publicitario. Con nuestra IA de última generación, tus ideas cobrarán vida.",
      title: "Transforma tus palabras en imágenes con la IA",
      userMessage1: "Crea una serie de paisajes de ensueño, donde cada uno represente una época distinta",
      userMessage2: "Genera una serie de interiores de sala de estar, donde cada uno tenga un estilo de enlucido único"
    },
    two: {
      description: "Crea imágenes asombrosas al instante con AskGPT. Prueba nuestra rapidísima y excepcional herramienta de generación de imágenes. ¡Las posibilidades son infinitas!",
      items: {
        "[0]": {
          emoji: "🎨",
          text: "Arte de IA"
        },
        "[1]": {
          emoji: "🪄",
          text: "Logotipos​ de​ ​IA"
        },
        "[2]": {
          emoji: "📸",
          text: "Fotos de IA"
        },
        "[3]": {
          emoji: "🖋️",
          text: "Tatuajes de IA"
        },
        "[4]": {
          emoji: "🌈",
          text: "Pósteres​ de​ ​IA"
        },
        "[5]": {
          emoji: "📖",
          text: "Texto​ a​ ​imagen"
        },
        "[6]": {
          emoji: "🔁",
          text: "Imagen​ ​a​ ​imagen"
        },
        item1: "Arte de IA",
        item2: "Logotipos de IA",
        item3: "Fotos de IA",
        item4: "Tatuajes de IA",
        item5: "Pósteres​ de​ ​IA",
        item6: "Texto ​a​ ​imagen",
        item7: "Imagen a imagen"
      },
      title: "Imágenes de IA de gran calidad<br/>en segundos",
      userMessage: "Crea arte de IA combinando modelos de circuitos con flores vibrantes"
    }
  }
};
