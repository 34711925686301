export default {
  detectAI: 'Detect AI',
  placeholder: 'Paste your text here...',
  resultWillAppearHere: 'Results will appear here<br/> after scanning',
  significantAIContentFound: 'Significant AI content found',
  yourTextIsLargely: 'Your text is largely AI-generated. For a more original and personal touch, rewrite AI-heavy sections with your own perspective and add unique insights or examples.',
  values: {
    one: {
      title: 'Mostly human-written',
      description: 'Your text is mostly human-written, with minimal AI influence. Review key sections to maintain a consistent tone and style.',
    },
    two: {
      title: 'Some AI influence detected',
      description: 'Your text blends human and AI-generated content. Rephrase AI sections to enhance authenticity and match your voice.',
    },
    three: {
      title: 'Significant AI content found',
      description: 'Your text is largely AI-generated. Rewrite AI-heavy sections with your own perspective, adding unique insights and examples.',
    },
    four: {
      title: 'Predominantly AI-generated',
      description: 'Your text appears AI-written. Rewrite key parts in your own words, restructure sentences, and ensure originality.',
    }
  }
};