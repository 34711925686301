export default {
  clearText: "Testo in chiaro",
  copied: "Copiato",
  copy: "Copia",
  dislike: "Non mi piace",
  enterTextHere: "Inserisci il testo qui ...",
  like: "Come",
  loadingText: "Stiamo analizzando i vostri contenuti ora",
  yourOutputContent: "Il vostro contenuto di output"
} 
