export default {
  advancedTools: {
    advanced: "Avancé",
    imageGeneration: {
      description: "Créez des images grâce à des mots",
      title: "Génération d’images"
    },
    linkAndAsk: {
      description: "Collez un lien et posez votre question",
      title: "Requête avec lien"
    },
    title: "Outils avancés",
    tools: "Outils",
    uploadAndAsk: {
      description: "Importez un PDF, utilisez les infos à votre guise",
      title: "Requête avec fichier"
    }
  },
  aiHumanizer: "AI Humanizer",
  aiHumanizerChat: {
    aiTextHumanizer: "L'humanisation du texte par l'IA",
    description: "Affiner le contenu généré par l'IA pour qu'il corresponde à un style d'écriture humain."
  },
  burgerMenu: {
    logout: "Se déconnecter",
    settings: "Paramètres",
    startConversation: "Démarrer une conversation"
  },
  chat: {
    startNewChat: "Commencer une nouvelle conversation"
  },
  chatHistory: "Historique​ ​du​ ​chat",
  chats: "Conversations",
  customizeGPTWithSkills: "Personnalisez GPT avec les compétences et les connaissances dont vous avez besoin.",
  dallE3: "Génération​ d’images",
  dallE3V2: "Générateur d'images",
  emptyChatGPT: {
    claude: "Claude",
    claudeDescription: "Un penseur clair pour les tâches complexes. Parfait pour résumer des textes longs, affiner des idées et aborder des sujets complexes avec clarté.",
    deepSeek: "DeepSeek",
    deepSeekDescription: "Un modèle d'IA très précis conçu pour des réponses précises, détaillées et perspicaces. Idéal pour la résolution de problèmes, la recherche et l'analyse approfondie.",
    gemini: "Gemini",
    geminiDescription: "Votre assistant pour la recherche, la résolution de problèmes et la créativité. Idéal pour analyser des données, répondre à des questions difficiles et générer du contenu dans plusieurs langues.",
    gpt: "OpenAI GPT-4o-mini",
    gpt4o: "Open AI GPT-4o",
    gpt4oDescription: "Un assistant puissant pour s'attaquer à des tâches complexes et fournir des réponses détaillées. Parfait pour générer des idées, analyser des informations et résoudre des problèmes complexes.",
    gpt4oMiniDescription: "Votre assistant incontournable pour une large gamme de tâches. De la rédaction d’e-mails et la création de rapports détaillés à la gestion de votre calendrier et la génération d’idées.",
    gptTabDescription: "Votre assistant incontournable pour une large gamme de tâches. De la rédaction d’e-mails et la création de rapports détaillés à la gestion de votre calendrier et la génération d’idées.",
    grok: "Grok",
    grokDescription: "Un assistant puissant pour s'attaquer à des tâches complexes et fournir des réponses détaillées. Parfait pour générer des idées, analyser des informations et résoudre des problèmes complexes.",
    hereToHelpWithAnyQuestions: "Je suis là pour répondre à toutes vos questions.",
    title: "Comment puis-je vous aider aujourd’hui ?"
  },
  emptyImageGeneration: {
    imageGeneration: "Génération​ d’images",
    imageGenerationTabDescription: "Libérez la magie de l’IA avec la Génération d’images. Transformez instantanément vos mots en visuels sublimes et captivants. Embarquez pour une odyssée créative et ressentez le pouvoir de l’imagination dans chaque pixel.<br​ /><br​ /> Vous pouvez commencer une conversation depuis le champ de message ci-dessous ou essayer avec les exemples suivants :",
    imageGenerator: "Générateur d'images",
    items: {
      image1: "Crée un panorama urbain<br/> illuminé de néons dans un monde futuriste cyberpunk.",
      image2: "Imagine une cabane confortable<br/> dans une forêt enneigée au crépuscule.",
      image3: "Crée une petite salle de bains élégante<br/> qui soit jolie et fonctionnelle.",
      image4: "Imagine des amis et des animaux<br/> profitant ensemble d’un pique-nique au soleil."
    },
    title: "Créez des images grâce à des mots",
    turnYourIdeasIntoEye: "Transformez vos idées en images accrocheuses - il vous suffit de taper une courte description.",
    youCanStartConvarsation: "Vous pouvez commencer une conversation depuis le champ de message ci-dessous ou essayer avec les exemples suivants :"
  },
  emptyLinkAndAsk: {
    linkAndAsk: "Requête​ avec​ ​Lien",
    linkAndAskTabDescription: "Saisissez une URL et obtenez une réponse à toutes vos questions sur le contenu de la page.",
    pasteLinkHere: "Collez un lien ici",
    title: "Collez​ ​un​ ​lien​ ​et​ ​demandez"
  },
  emptyUploadAndAsk: {
    changeDocument: "Changer de document",
    clickToUpload: "Cliquez pour importer",
    fileSizeShouldNotExceed: "La taille du fichier ne doit pas dépasser",
    format: "format,​ ​max.",
    helpToYouWithQuestions: "Je suis là pour répondre à toutes vos questions.",
    max: "Max",
    only: "Seulement ",
    onlyPDFFilesAreAllowed: "Seuls les fichiers PDF sont autorisés.",
    orDragAndDrop: "vos fichiers ou déposez-les ici",
    uploadAndAsk: "Requête avec fichier",
    uploadAndAskPDF: "Télécharger et demander",
    uploadAndAskTabDescription: "Recherchez ou demandez n’importe quoi dans votre document.<br​ ​/><br​ ​/> Qu'il s'agisse de contrats juridiques ou de rapports financiers, l'outil Requête avec fichier vous permet d'interagir facilement avec vos PDF. Importez un PDF, posez des questions, obtenez un résumé et extrayez des données instantanément à l'aide de l'IA.",
    uploadFileInCorrectFormat: "Télécharger le fichier dans le bon format (pdf, xlsx, csv, txt, doc, docx).",
    uploadPDFAskQuestions: "Téléchargez des fichiers, posez des questions, obtenez des résumés et extrayez des données instantanément grâce à l'IA.",
    uploadPDFUseInfoYourWay: "Importez un PDF, utilisez les infos à votre guise",
    uploading: "Téléchargement en cours..."
  },
  goPro: "Passer à Pro",
  greatForMostTasks: "Idéal pour la plupart des tâches",
  linkAndAsk: "Requête​ ​avec​ ​Lien",
  message: {
    chat: "Conversation",
    download: "Télécharger",
    error: "Désolé, votre demande n'a pas pu être traitée. Veuillez réessayer.",
    imageGeneration: "Votre image est en train d'être générée...",
    regenerate: "Regénérer",
    you: "Toi"
  },
  miniBotHub: "Concentrateur de mini-bots",
  mobileVersion: {
    allTime: "Tout le temps",
    header: {
      startNewChat: "Commencer une nouvelle conversation"
    },
    history: "Historique",
    lastMonth: "30 derniers jours",
    lastWeek: "7 derniers jours",
    lastYear: "L’année dernière",
    myAccount: "Mon​ Compte",
    save: "Économisez",
    today: "Aujourd'hui",
    tools: "Outils"
  },
  more: "Plus",
  newChat: "Nouvelle conversation",
  pro: "Pro",
  promptLibrary: "Bibliothèque de requêtes",
  promptLibraryDescription: "Découvrez et créez des versions personnalisées de ChatGPT qui combinent des instructions, des connaissances supplémentaires <br​ /> et toute combinaison possible de compétences.",
  promptLibraryHint: "Parcourez les requêtes pour trouver l’inspiration",
  readyToUseAI: "Des mini-robots d'IA prêts à l'emploi qui simplifient les tâches et augmentent votre efficacité.",
  recentlyUsed: "Récemment Utilisé",
  seeAll: "Tout voir",
  startNewChat: "Commencer une nouvelle conversation",
  uploadAndAsk: "Requête avec fichier",
  uploadAndAskV2: "Télécharger et demander",
  usesAdvancedReasoning: "Utilise un raisonnement avancé",
  writeYourMessage: "Écrivez votre message…"
};
