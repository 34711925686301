export default {
  advancedTools: {
    advanced: "Avanzati",
    imageGeneration: {
      description: "Crea immagini usando le parole",
      title: "Generazione di immagini"
    },
    linkAndAsk: {
      description: "Incolla qualsiasi link e chiedi",
      title: "Link & Ask"
    },
    title: "Strumenti avanzati",
    tools: "Strumenti",
    uploadAndAsk: {
      description: "Carica un PDF, usa le informazioni a modo tuo",
      title: "Upload & Ask"
    }
  },
  aiHumanizer: "Umanizzatore AI",
  aiHumanizerChat: {
    aiTextHumanizer: "Umanizzatore di testi AI",
    description: "Perfezionare i contenuti generati dall'IA in modo che corrispondano allo stile di scrittura umano."
  },
  burgerMenu: {
    logout: "Esci",
    settings: "Impostazioni",
    startConversation: "Avvia conversazione"
  },
  chat: {
    startNewChat: "Avvia una nuova chat"
  },
  chatHistory: "Cronologia chat",
  chats: "Chat",
  customizeGPTWithSkills: "Personalizzate GPT con le competenze e le conoscenze di cui avete bisogno.",
  dallE3: "Generazione di immagini",
  dallE3V2: "Generatore di immagini",
  emptyChatGPT: {
    claude: "Claude",
    claudeDescription: "Un pensatore chiaro per compiti complessi. Perfetto per riassumere testi lunghi, affinare le idee e affrontare argomenti complessi con chiarezza.",
    deepSeek: "DeepSeek",
    deepSeekDescription: "Un modello di intelligenza artificiale estremamente accurato, progettato per fornire risposte precise, dettagliate e perspicaci. Ideale per la risoluzione di problemi, la ricerca e l'analisi approfondita.",
    gemini: "Gemini",
    geminiDescription: "Il vostro assistente per la ricerca, la risoluzione dei problemi e la creatività. Ottimo per analizzare dati, rispondere a domande difficili e generare contenuti in più lingue.",
    gpt: "OpenAI GPT-4o-mini",
    gpt4o: "OpenAI GPT-4o",
    gpt4oDescription: "Un assistente potente per affrontare compiti complessi e fornire risposte dettagliate. Perfetto per generare intuizioni, analizzare informazioni e risolvere problemi impegnativi.",
    gpt4oMiniDescription: "Il tuo assistente di fiducia per una vasta gamma di attività. Dalla scrittura di e-mail e generazione di report dettagliati, alla gestione del calendario e al brainstorming.",
    gptTabDescription: "Il tuo assistente di fiducia per una vasta gamma di attività. Dalla scrittura di e-mail e generazione di report dettagliati, alla gestione del calendario e al brainstorming.",
    grok: "Grok",
    grokDescription: "Un assistente potente per affrontare compiti complessi e fornire risposte dettagliate. Perfetto per generare intuizioni, analizzare informazioni e risolvere problemi impegnativi.",
    hereToHelpWithAnyQuestions: "Sono qui per rispondere alle tue domande.",
    title: "Come posso aiutarti oggi?"
  },
  emptyImageGeneration: {
    imageGeneration: "Generazione di immagini",
    imageGenerationTabDescription: "Scatena i poteri magici dell'IA con la Generazione di immagini. Trasforma all'istante le tue parole in immagini sorprendenti e incantevoli. Intraprendi un'odissea creativa: sperimenta il potere dell'immaginazione in ogni pixel.<br​ ​/><br​ /> Puoi iniziare una conversazione dalla casella dei messaggi qui sotto o provare i seguenti esempi:",
    imageGenerator: "Generatore di immagini",
    items: {
      image1: "Crea uno skyline illuminato al neon<br/>in un futuristico mondo cyberpunk",
      image2: "Illustra un'accogliente baita<br/>in una foresta innevata al tramonto",
      image3: "Progetta un bagno piccolo<br/>ed elegante, che sia carino<br/>e funzionale",
      image4: "Immagina amici e animali<br/>domestici che si godono<br/>insieme un picnic al sole"
    },
    title: "Crea immagini usando le parole",
    turnYourIdeasIntoEye: "Trasformate le vostre idee in immagini accattivanti: basta digitare una breve descrizione.",
    youCanStartConvarsation: "Puoi iniziare una conversazione dalla casella dei messaggi qui sotto o provare i seguenti esempi:"
  },
  emptyLinkAndAsk: {
    linkAndAsk: "Link​ ​&​ ​Ask",
    linkAndAskTabDescription: "Inserisci un URL e ricevi risposte a qualsiasi domanda inerente al contenuto.",
    pasteLinkHere: "Incolla un link qui",
    title: "Incolla qualsiasi link e chiedi"
  },
  emptyUploadAndAsk: {
    changeDocument: "Cambia documento",
    clickToUpload: "Clicchi per caricare",
    fileSizeShouldNotExceed: "La dimensione del file non deve superare",
    format: "formato, max.",
    helpToYouWithQuestions: "Sono qui per rispondere alle tue domande.",
    max: "massimo",
    only: "Solo",
    onlyPDFFilesAreAllowed: "Sono ammessi solo i file PDF.",
    orDragAndDrop: "o trascinare e rilasciare",
    uploadAndAsk: "Upload​ &​ ​Ask",
    uploadAndAskPDF: "Carica e chiedi",
    uploadAndAskTabDescription: "Cerca o chiedi qualsiasi cosa nel tuo documento.<br​ /><br​ ​/> Da accordi legali a report finanziari, lo strumento Upload & Ask ti consente di interagire con i tuoi PDF senza problemi. Carica un PDF, fai domande, ottieni riepiloghi ed estrai dati all'istante usando l'IA.",
    uploadFileInCorrectFormat: "Caricare il file nel formato corretto (pdf, xlsx, csv, txt, doc, docx).",
    uploadPDFAskQuestions: "Caricare file, porre domande, ottenere sintesi ed estrarre dati all'istante utilizzando l'intelligenza artificiale.",
    uploadPDFUseInfoYourWay: "Carica un PDF, usa le informazioni a modo tuo",
    uploading: "Caricamento in corso..."
  },
  goPro: "VAI PRO",
  greatForMostTasks: "Ottimo per la maggior parte dei compiti",
  linkAndAsk: "Link & Ask",
  message: {
    chat: "Chat",
    download: "Scaricare",
    error: "Siamo spiacenti, non è stato possibile elaborare la richiesta. Si prega di riprovare.",
    imageGeneration: "L'immagine viene generata...",
    regenerate: "Rigenera",
    you: "Tu"
  },
  miniBotHub: "Hub per mini-robot",
  mobileVersion: {
    allTime: "Sempre",
    header: {
      startNewChat: "Avvia una nuova chat"
    },
    history: "Cronologia",
    lastMonth: "Ultimi 30 giorni",
    lastWeek: "Ultimi 7 giorni",
    lastYear: "Ultimo anno",
    myAccount: "Mio​ account",
    save: "Salva",
    today: "Oggi",
    tools: "Strumenti"
  },
  more: "Di più",
  newChat: "Nuova chat",
  pro: "Pro",
  promptLibrary: "Libreria di prompt",
  promptLibraryDescription: " Scopri e crea versioni personalizzate di ChatGPT che combinano istruzioni, conoscenze aggiuntive<br​ />e qualsiasi combinazione di competenze.",
  promptLibraryHint: "Esplora i prompt per le tue idee",
  readyToUseAI: "Mini-bot AI pronti all'uso che semplificano le attività e aumentano l'efficienza.",
  recentlyUsed: "Usato di recente",
  seeAll: "Vedi tutti",
  startNewChat: "Avvia una nuova chat",
  uploadAndAsk: "Upload & Ask",
  uploadAndAskV2: "Carica e chiedi",
  usesAdvancedReasoning: "Utilizza un ragionamento avanzato",
  writeYourMessage: "Scrivi il tuo messaggio..."
};
