export default {
  checkout: {
    "continue": "Continua",
    discount: "sconto",
    easierFasterAndSaferWayToPay: "Un modo più semplice, veloce e sicuro per pagare",
    orPayByCard: "Oppure paga tramite carta",
    title: "Completa il pagamento",
    total: "Totale",
    totalPerDiscount: "Totale al giorno"
  },
  "continue": "Continua",
  plans: {
    and: "e",
    appName: "AskGPT​ ​Pro",
    byProceedingWithThePurchase: "Procedendo con l'acquisto di un piano, accetti",
    mostPopular: "Il più popolare",
    oneMonth: "1 mese",
    oneYear: "1 anno",
    paySafe: "Paga in modo sicuro e protetto",
    perDay: "Al​ ​Giorno",
    privacyPolicy: "l'Informativa sulla privacy",
    refund: "la Politica sui rimborsi e recesso.",
    save: "IN MENO",
    subtitle: "Sbloccate il pieno potenziale di AskGPT Pro",
    subtitleDeepSeek: "Sbloccate il pieno potenziale dell'assistente di chat AI",
    subtitleDetector: "Sbloccate il pieno potenziale dell'App AskGPT",
    subtitleGrok: "Sbloccate il pieno potenziale dell'assistente di chat AI",
    termsOfService: "Termini di servizio",
    threeMonths: "3 mesi",
    title: "Aggiornare<br/>a AskGPT Pro",
    titleDeepSeek: "Aggiornate<br/>all'assistente di chat AI Pro",
    titleGrok: "Aggiornate<br/>all'assistente di chat AI Pro",
    to: "ad",
    toOur: "i nostri",
    upgrade: "Passa"
  },
  poweredBy: "Alimentato da",
  prompts: {
    benefits: {
      "[0]": {
        description: "Da progetti professionali a routine quotidiane",
        title: "Oltre 350 soluzioni di attività personalizzate"
      },
      "[1]": {
        description: "Accedi immediatamente a soluzioni personalizzate per le tue sfide",
        title: "Risposte personalizzate con un clic"
      },
      "[2]": {
        description: "Il tuo assistente personale e gratuito per tutti gli aspetti della vita",
        title: "Bot GPT universale"
      },
      item1: {
        description: "Da progetti professionali a routine quotidiane",
        title: "Oltre 350 soluzioni di attività personalizzate"
      },
      item2: {
        description: "Accedi immediatamente a soluzioni personalizzate per le tue sfide",
        title: "Risposte personalizzate con un clic"
      },
      item3: {
        description: "Il tuo assistente personale e gratuito per tutti gli aspetti della vita",
        title: "Bot AI universale"
      }
    },
    boostYourProductivityBy: "Aumenta la tua produttività di",
    expertCraftedPrompts: "prompt creati da esperti",
    getLifetimeAccessOurExpert: "Ottieni accesso a vita alla nostra libreria di prompt esperti!",
    now: "ora",
    off: "DI SCONTO",
    oneTimePaymentForLifetimeAccess: "Pagamento unico per l'accesso a vita!",
    paySafe: "Paga in modo sicuro e protetto",
    proposedPlans: {
      pro: {
        items: {
          "[0]": "10-15 min",
          "[1]": "3-4 ore",
          "[2]": "20-30 min",
          "[3]": "10-15 min",
          "[4]": "20​ min",
          item1: "10-15 min",
          item2: "3-4 ore",
          item3: "20-30 min",
          item4: "10-15 min",
          item5: "20​ ​min"
        },
        newTitle: "Con i nostri bot AI personalizzati<br/> ",
        title: "Con i nostri prompt basati sulla ricerca"
      },
      standart: {
        items: {
          "[0]": "1-2 ore",
          "[1]": "8-10 ore",
          "[2]": "2-3 ore",
          "[3]": "1-2 ore",
          "[4]": "1-2 ore",
          item1: "1-2 ore",
          item2: "8-10 ore",
          item3: "2-3 ore",
          item4: "1-2 ore",
          item5: "1-2 ore"
        },
        title: "Con il bot AI<br/>standard"
      }
    },
    save: "IN MENO",
    superchargeYour: "Potenzia il tuo",
    superchargeYourRoutine: "Potenziate la vostra routine",
    title: "Potenzia il tuo <br​ class=\"lg:hidden\"​ ​/>ChatGPT-4 con <br​ class=\"lg:hidden\"​ /> prompt creati da esperti, <br​ class=\"lg:hidden\"​ />ora <span​ class=\"text-[#DC2728]\">con l'80% DI SCONTO!</span>",
    topicsForRequests: {
      "[0]": "🏋 Crea un piano di allenamento personalizzato",
      "[1]": "📸 Impara le basi dell'editing fotografico",
      "[2]": "🎥 Scrivi una sceneggiatura per un video",
      "[3]": "🥗 Suggerisci un piano alimentare settimanale",
      "[4]": "📝 Rispondi a 10 e-mail",
      topic1: "🏋 Crea un piano di allenamento personalizzato",
      topic2: "📸 Impara le basi dell'editing fotografico",
      topic3: "🎥 Scrivi una sceneggiatura per un video",
      topic4: "🥗 Suggerisci un piano alimentare settimanale",
      topic5: "📝 Rispondi a 10 e-mail"
    },
    totalToday: "Totale​ oggi",
    upgrade: "Passa",
    upgradeToExpert: "Passa ai prompt degli esperti",
    "with": "con",
    withCustomAIBots: "con robot AI personalizzati,"
  },
  purchaseBenefits: {
    free: "gratis",
    items: {
      advancedTools: {
        description: "Accesso a strumenti avanzati di intelligenza artificiale come il Generatore di immagini",
        title: "Sblocca strumenti avanzati"
      },
      gpt: {
        description: "Chatta con l'assistente IA",
        title: "Accesso a OpenAI GPT-4o mini"
      },
      gpt4o: {
        description: "OpenAI GPT-4o, Gemini, Claude",
        title: "Accesso al modello GPT-4o"
      },
      noLimits: {
        description: "Conversazioni sena limiti con tutti i modelli di intelligenza artificiale",
        title: "Nessun limite"
      }
    },
    itemsDeepSeek: {
      advancedTools: {
        description: "Accesso a strumenti avanzati di intelligenza artificiale come il Generatore di immagini",
        title: "Sbloccare gli strumenti avanzati"
      },
      deepSeek: {
        description: "Prova il modello avanzato di DeepSeek AI",
        title: "Accesso a DeepSeek"
      },
      gpt4o: {
        description: "OpenAI GPT-4o, Gemini, Claude",
        title: "Accesso a più modelli:"
      },
      noLimits: {
        description: "Dialoghi illimitati con tutti i modelli di intelligenza artificiale",
        title: "Senza limiti"
      }
    },
    itemsDetector: {
      advancedTools: {
        description: "Accesso a strumenti avanzati come il generatore di immagini",
        title: "Sbloccare gli strumenti avanzati"
      },
      gpt: {
        description: "Chattare con la tecnologia OpenAI",
        title: "Accesso a OpenAI GPT-4o mini"
      },
      gpt4o: {
        description: "Rilevamento AI accurato al 100%",
        title: "Rilevatore di contenuti AI"
      },
      noLimits: {
        description: "Dialoghi illimitati con tutti i modelli di intelligenza artificiale",
        title: "Senza limiti"
      }
    },
    itemsGrok: {
      advancedTools: {
        description: "Accesso a strumenti avanzati di intelligenza artificiale come il Generatore di immagini",
        title: "Sbloccare gli strumenti avanzati"
      },
      gpt4o: {
        description: "OpenAI GPT-4o, Gemini, Claude",
        title: "Accesso a modelli avanzati:"
      },
      grok: {
        description: "Chattare con il modello Grok di xAI",
        title: "Accesso a Grok"
      },
      noLimits: {
        description: "Dialoghi illimitati con tutti i modelli di intelligenza artificiale",
        title: "Senza limiti"
      }
    },
    pro: "pro"
  },
  successModal: {
    backToChat: "Torna alla chat",
    hasBeenSuccessfullyActivated: "è stato attivato con successo.",
    plan: "Piano",
    title: "Pagamento effettuato con successo!",
    yourPro: "Il tuo Pro"
  }
};
