export default {
  steps: {
    one: {
      title: 'Get unlimited <br/>access to DeepSeek',
      description: 'Welcome to the AI chat assistant, powered by DeepSeek technology. Get highly accurate, personalized advice and solutions instantly. Ask anything, anytime.',
      items: {
        one: {
          title: 'Powered by DeepSeek',
          description: 'Write a Linkedin post to attract new leads for my business',
        },
        two: {
          title: 'Career',
          description: 'Write persuasive ad copy for my new line of cowboy boots',
        },
        three: {
          title: 'Learning',
          description: 'Give me a test to check my knowledge of Social Studies',
        },
        four: {
          title: 'Spirituality',
          description: 'Can you provide me with my daily horoscope for Taurus?',
        },
      },
    },
    two: {
      title: 'Get instant answers',
      description: 'From solving problems to satisfying curiosity, your AI chat assistant provides precise answers. Just ask.',
      userMessage: 'Help me prepare a speech to ask my boss for a promotion',
      gptMessages: {
        message1: {
          title: 'Introduction:',
          message: `"Hello, thank you for taking the time to meet with me today. I appreciate the opportunity to discuss my career growth and potential within the company."`,
        },
        message2: {
          title: 'Highlight Your Achievements:',
          message: `"Over the past [duration], I have had the privilege of contributing to several key projects, including [mention specific project], where we achieved a [specific result]."`,
        },
        message3: {
          title: 'Vision for the Future:',
          message: `"I am passionate about [specific aspect of your job or company], and I believe that in a [desired position], I can make an even greater impact by [specific contributions]. I am eager to take on new challenges and continue driving our team towards success."`,
        },
      },
      items: {
        item1: 'Personal productivity',
        item2: 'Finance',
        item3: 'Learning and education',
        item4: 'Spirituality and religion',
        item5: 'Communication, content creation',
        item6: 'Mental wellbeing',
      },
    },
    three: {
      title: 'AI-powered living',
      description: 'Redefine how you tackle challenges, communicate, and learn — with advanced technology in your hands.',
      messages: {
        gpt1: 'Hi 👋 How can I help you?',
        user: 'How do I start my own business?',
        gpt2: 'Starting your own business can be an exciting and rewarding venture. Here’s a step-by-step guide to help you get started...',
      },
    },
    continue: 'Continue',
    poweredBy: 'Powered by',
    mobile: {
      one: {
        title: 'Unlimited AI assistance<br/>powered by GPT',
        description: 'Welcome to AskGPT, your AI assistant<br/>powered by GPT and GPT-4o technology.<br/>Receive expert advice and personalized<br/>solutions instantly.<br/>Ask anything, anytime you need.',
      },
      two: {
        title: 'Get instant answers',
        description: 'From solving problems to satisfying curiosity,<br/>AskGPT delivers precise responses. Just ask.',
      },
      three: {
        title: 'AI-powered living',
        description: 'Redefine how you tackle challenges,<br/>communicate,  and learn — with advanced<br/>technology in your hands.',
      },
    },
  },
  signup: {
    trustText: 'Millions trust AI products every day',
  },
  mobileVersion: {
    poweredBy: 'Powered by',
    button: 'Continue',
    steps: {
      two: {
        userMessage: 'Help me prepare a speech to ask my boss for a promotion',
        gptMessages: {
          title: 'Introduction',
          message1: '"Hello, thank you for taking the time to meet with me today. I appreciate the opportunity to discuss my career growth and potential within the company."',
          message2: '"Hello, thank you for taking the...',
        },
      },
    },
  },
};
