export default {
  checkout: {
    "continue": "Weiter",
    discount: "Rabatt",
    easierFasterAndSaferWayToPay: "Noch einfachere, schnellere und sicherere Zahlungsmöglichkeit",
    orPayByCard: "Oder mit Karte zahlen",
    title: "Kasse",
    total: "Insg.",
    totalPerDiscount: "Insg. pro Tag"
  },
  "continue": "Weiter",
  plans: {
    and: "und",
    appName: "AskGPT​ Pro",
    byProceedingWithThePurchase: "Mit dem Abschluss eines Abonnements akzeptierst du",
    mostPopular: "Am​ beliebtesten",
    oneMonth: "1 Monat",
    oneYear: "1 Jahr",
    paySafe: "Sichere und schnelle Zahlung",
    perDay: "Pro Tag",
    privacyPolicy: "Datenschutzrichtlinien",
    refund: "Rückerstattungs- und Kündigungsrichtlinien.",
    save: "spare",
    subtitle: "Nutze das volle Potenzial von AskGPT​ Pro",
    subtitleDeepSeek: "Schöpfen Sie das volle Potenzial Ihres KI-Chat-Assistenten aus",
    subtitleDetector: "Schöpfen Sie das volle Potenzial der AskGPT App aus",
    subtitleGrok: "Schöpfen Sie das volle Potenzial Ihres KI-Chat-Assistenten aus",
    termsOfService: "Allgemeinen Geschäftsbedingungen",
    threeMonths: "3 Monate",
    title: "Upgrade von<br/>auf AskGPT Pro",
    titleDeepSeek: "Upgrade von<br/>auf den KI-Chat-Assistenten Pro",
    titleGrok: "Upgrade von<br/>auf den KI-Chat-Assistenten Pro",
    to: "auf",
    toOur: "unsere",
    upgrade: "Upgrade"
  },
  poweredBy: "Angetrieben von",
  prompts: {
    benefits: {
      "[0]": {
        description: "Von professionellen Projekten bis zu täglichen Routinen",
        title: "Über 350 maßgeschneiderte Lösungen"
      },
      "[1]": {
        description: "Sofortzugriff auf individuelle Lösungen für Ihre Herausforderungen",
        title: "Personalisierte Antworten mit einem Klick"
      },
      "[2]": {
        description: "Der persönliche Gratis-Assistent für alle Seiten des Lebens",
        title: "Universeller GPT-Bot"
      },
      item1: {
        description: "Von professionellen Projekten bis zu täglichen Routinen",
        title: "Über 350 maßgeschneiderte Lösungen"
      },
      item2: {
        description: "Sofortzugriff auf individuelle Lösungen für Ihre Herausforderungen",
        title: "Personalisierte Antworten mit einem Klick"
      },
      item3: {
        description: "Der persönliche Gratis-Assistent für alle Seiten des Lebens",
        title: "Universeller KI-Bot"
      }
    },
    boostYourProductivityBy: "Produktivität boosten",
    expertCraftedPrompts: "Experten-Prompts noch höher",
    getLifetimeAccessOurExpert: "Erhalte lebenslangen Zugriff auf unsere Experten-Prompt-Kollektion!",
    now: "jetzt",
    off: "RABATT",
    oneTimePaymentForLifetimeAccess: "Einmalige Zahlung für lebenslangen Zugang!",
    paySafe: "Sichere und schnelle Zahlung",
    proposedPlans: {
      pro: {
        items: {
          "[0]": "10-15 min",
          "[1]": "3-4 Stunden",
          "[2]": "20-30 min",
          "[3]": "10-15 min",
          "[4]": "20​ ​min",
          item1: "10-15 min",
          item2: "3-4 Stunden",
          item3: "20-30 min",
          item4: "10-15 min",
          item5: "20​ ​min"
        },
        newTitle: "Mit unseren maßgeschneiderten<br/> AI-Bots",
        title: "Mit unseren forschungsbasierten Prompts"
      },
      standart: {
        items: {
          "[0]": "1-2 Stunden",
          "[1]": "8-10 Stunden",
          "[2]": "2-3 Stunden",
          "[3]": "1-2 Stunden",
          "[4]": "1-2 Stunden",
          item1: "1-2 Stunden",
          item2: "8-10 Stunden",
          item3: "2-3 Stunden",
          item4: "1-2 Stunden",
          item5: "1-2 Stunden"
        },
        title: "Mit dem Standard<br/>AI-Bot"
      }
    },
    save: "Spare",
    superchargeYour: "Schalte",
    superchargeYourRoutine: "Überladen Sie Ihre Routine",
    title: "Mit <br class=\"lg:hidden\"/>Experten-Prompts schaltet <br class=\"lg:hidden\"/> ChatGPT-4 noch höher, <br class=\"lg:hidden\"/> jetzt mit <span class=\"text-[#DC2728]\">80 % RABATT!</span>",
    topicsForRequests: {
      "[0]": "🏋 Persönlichen Trainingsplan erstellen",
      "[1]": "📸 Grundlegende Fotobearbeitung lernen",
      "[2]": "🎥 Skript für ein Video schreiben",
      "[3]": "🥗 Wöchentlichen Speiseplan vorschlagen",
      "[4]": "📝 10 E-Mails beantworten",
      topic1: "🏋 Persönlichen Trainingsplan erstellen",
      topic2: "📸 Grundlegende Fotobearbeitung lernen",
      topic3: "🎥 Skript für ein Video schreiben",
      topic4: "🥗 Wöchentlichen Speiseplan vorschlagen",
      topic5: "📝 10 E-Mails beantworten"
    },
    totalToday: "Heute insgesamt",
    upgrade: "Upgrade",
    upgradeToExpert: "Upgrade auf Expertenansagen",
    "with": "mit",
    withCustomAIBots: "mit maßgeschneiderten KI-Bots,"
  },
  purchaseBenefits: {
    free: "kostenlos",
    items: {
      advancedTools: {
        description: "Zugang zu fortgeschrittenen AI-Tools wie dem Bildgenerator",
        title: "Fortgeschrittene Tools freischalten"
      },
      gpt: {
        description: "Chatte mit dem KI-Assistenten",
        title: "Zugang zum OpenAI GPT-4o mini"
      },
      gpt4o: {
        description: "OpenAI GPT-4o, Gemini, Claude",
        title: "Zugang zum GPT-4o-Modell"
      },
      noLimits: {
        description: "Unlimitiere Dialoge mit allen KI-Modellen",
        title: "Keine Begrenzungen"
      }
    },
    itemsDeepSeek: {
      advancedTools: {
        description: "Zugang zu fortgeschrittenen AI-Tools wie dem Bildgenerator",
        title: "Erweiterte Tools freischalten"
      },
      deepSeek: {
        description: "Testen Sie das fortschrittliche Modell von DeepSeek AI",
        title: "Zugang zu DeepSeek"
      },
      gpt4o: {
        description: "OpenAI GPT-4o, Zwillinge, Claude",
        title: "Zugang zu mehreren Modellen:"
      },
      noLimits: {
        description: "Unbegrenzte Dialoge mit allen KI-Modellen",
        title: "Keine Grenzen"
      }
    },
    itemsDetector: {
      advancedTools: {
        description: "Zugriff auf fortgeschrittene Tools wie den Bildgenerator",
        title: "Erweiterte Tools freischalten"
      },
      gpt: {
        description: "Chatten mit OpenAI-Technologie",
        title: "Zugang zum OpenAI GPT-4o mini"
      },
      gpt4o: {
        description: "100% genaue AI-Erkennung",
        title: "AI-Inhaltsdetektor"
      },
      noLimits: {
        description: "Unbegrenzte Dialoge mit allen KI-Modellen",
        title: "Keine Grenzen"
      }
    },
    itemsGrok: {
      advancedTools: {
        description: "Zugang zu fortgeschrittenen AI-Tools wie dem Bildgenerator",
        title: "Erweiterte Tools freischalten"
      },
      gpt4o: {
        description: "OpenAI GPT-4o, Zwillinge, Claude",
        title: "Zugang zu fortgeschrittenen Modellen:"
      },
      grok: {
        description: "Chatten mit dem Grok-Modell von xAI",
        title: "Zugang zu Grok"
      },
      noLimits: {
        description: "Unbegrenzte Dialoge mit allen KI-Modellen",
        title: "Keine Grenzen"
      }
    },
    pro: "pro"
  },
  successModal: {
    backToChat: "Zurück zum Chat",
    hasBeenSuccessfullyActivated: "wurde erfolgreich aktiviert.",
    plan: "Pro-Abo",
    title: "Zahlung erfolgreich!",
    yourPro: "Ihr"
  }
};
