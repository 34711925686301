export default {
  dialogueSaved: "Unterhaltung gespeichert",
  mobileVersion: {
    four: {
      items: {
        "[0]": {
          emoji: "💥",
          text: "Produktivität"
        },
        "[1]": {
          emoji: "📚",
          text: "Lernen"
        },
        "[2]": {
          emoji: "📈",
          text: "Sales"
        },
        "[3]": {
          emoji: "🚀",
          text: "Arbeit"
        },
        "[4]": {
          emoji: "🔬",
          text: "Daten"
        },
        "[5]": {
          emoji: "🎭",
          text: "Spaß"
        },
        "[6]": {
          emoji: "🎨",
          text: "Kunst"
        },
        item1: "Produktivität",
        item2: "Lernen",
        item3: "Sales",
        item4: "Arbeit",
        item5: "Daten",
        item6: "Spaß",
        item7: "Kunst"
      }
    },
    three: {
      message: "Erstelle eine Serie verträumter Landschaften, von denen jede eine andere Jahreszeit darstellt"
    },
    two: {
      message: "Erstelle KI-Kunstwerke, die Schaltkreismuster mit lebhaften Blumenmotiven kombinieren"
    }
  },
  model: "Generiertes Bild",
  poweredBy: "Angetrieben von",
  steps: {
    four: {
      description: "Über Bildgenerierung hinaus bietet AskGPT eine umfassende Suite leistungsstarker KI-Dienste, darunter intelligente Chat-Unterstützung, Website-Link-Analyse und PDF-Textanalyse.",
      gptMessage: "Seit der erste Mensch seinen Blick staunend gen Himmel richtete, ist die Erforschung des Weltraums einen langen Weg gegangen.<br/><br/>Zahlreiche bedeutende Fortschritte haben die wagemutige Reise der Menschheit hinaus ins Weltall markiert und uns den Weg in eine Zukunft voller ungeahnter Möglichkeiten geebnet.<br/><br/>In diesem Aufsatz geht es um die größten Entdeckungen aus der Weltraumforschung sowie um die künftigen ...",
      items: {
        "[0]": {
          emoji: "🎨",
          text: "Kunst"
        },
        "[1]": {
          emoji: "🚀",
          text: "Arbeit"
        },
        "[2]": {
          emoji: "📚",
          text: "Lernen"
        },
        "[3]": {
          emoji: "📈",
          text: "Sales"
        },
        "[4]": {
          emoji: "💥",
          text: "Produktivität"
        },
        "[5]": {
          emoji: "🔬",
          text: "Daten"
        },
        "[6]": {
          emoji: "🎭",
          text: "Spaß"
        },
        item1: "Kunst",
        item2: "Arbeit",
        item3: "Lernen",
        item4: "Sales",
        item5: "Produktivität",
        item6: "Daten",
        item7: "Spaß"
      },
      title: "Fortgeschrittenes KI-Toolkit",
      userMessage: "Schreib einen Aufsatz im Umfang von 1.000 Wörtern zu den Meilensteinen und der Zukunft der Weltraumforschung"
    },
    one: {
      description: "Willkommen bei AskGPT.<br/>Mit fortschrittlichen KI-Modellen verwandelst du deine kühnsten Ideen mühelos in atemberaubende Bilder – ohne Grenzen.",
      items: {
        four: {
          description: "Modernes Logo-Design für ein Technologie-Start-up",
          title: "Firmenbranding"
        },
        one: {
          description: "Ein Magierhut, aus dem Galaxien strömen",
          title: "Bilder mit KI generieren"
        },
        three: {
          description: "Minimalistisches, abstraktes Wandbild für ein Wohnzimmer",
          title: "Eigene Kunstwerke erschaffen"
        },
        two: {
          description: "Blog-Illustration: Bergsteiger auf einem Gipfel",
          title: "Ideen visualisieren"
        }
      },
      title: "Mit KI-Kreativität <br class='hidden xxl:block'/>von der Vorstellung zum Bild"
    },
    three: {
      description: "AskGPT ermöglicht dir, individuelle Kunstwerke, Modedesigns, Spielcharaktere und Marketing-Visuals zu erstellen. Mit modernster KI-Technologie werden deine Ideen mühelos zum Leben erweckt.",
      title: "Mit vielseitigen KI-Tools<br/>beliebige Daten visualisieren",
      userMessage1: "Erstelle eine Serie verträumter Landschaften, von denen jede eine andere Jahreszeit darstellt",
      userMessage2: "Erstelle eine Reihe von Wohnzimmern, jedes in einem einzigartigen Render-Stil"
    },
    two: {
      description: "Mit AskGPT erstellst du umgehend faszinierende visuelle Inhalte. Erlebe schnelle Bilderstellung auf höchstem Niveau und entdecke per Fingertipp endlose kreative Möglichkeiten.",
      items: {
        "[0]": {
          emoji: "🎨",
          text: "KI-Kunst"
        },
        "[1]": {
          emoji: "🪄",
          text: "KI-Logos"
        },
        "[2]": {
          emoji: "📸",
          text: "KI-Fotos"
        },
        "[3]": {
          emoji: "🖋️",
          text: "KI-Tattoos"
        },
        "[4]": {
          emoji: "🌈",
          text: "KI-Poster"
        },
        "[5]": {
          emoji: "📖",
          text: "Text​ ​zu​ Bild"
        },
        "[6]": {
          emoji: "🔁",
          text: "Bild zu Bild"
        },
        item1: "KI-Kunst",
        item2: "KI-Logos",
        item3: "KI-Fotos",
        item4: "KI-Tattoos",
        item5: "KI-Poster",
        item6: "Text zu Bild",
        item7: "Bild zu Bild"
      },
      title: "Hochwertige​ ​KI-Bilder in​ ​Sekunden",
      userMessage: "Erstelle KI-Kunstwerke, die Schaltkreismuster mit lebhaften Blumenmotiven kombinieren"
    }
  }
};
