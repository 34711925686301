export default {
  active: "Actif",
  cancel: "Annuler",
  canceled: "Annulé",
  "delete": "Supprimer",
  deleteAccount: "Supprimer le compte",
  deleteAccountAndData: "Supprimer le compte et les données",
  deleteAccountButton: "Supprimer le compte",
  deleteAccountText: "Êtes-vous sûr de vouloir supprimer le compte <br/> et les données ?",
  deleteSuccessful: "Votre compte a été supprimé",
  emailAddress: "Adresse e-mail",
  freePlan: "Forfait​ gratuit",
  goPro: "PASSER À PRO",
  logOut: "Se déconnecter",
  ok: " Ok",
  plan: "forfait",
  privacyPolicy: "notre Politique de confidentialité",
  renewsAt: "se​ renouvelle​ ​à",
  subscription: "Abonnement",
  termsOfUse: "Conditions d'utilisation",
  title: "Paramètres"
};
