import chat from './chat/index.js';
import paywall from './paywall/index.js';
import consent from './consent/index.js';
import cookieBanner from './cookie-banner/index.js';
import settings from './settings/index.js';
import onboarding from './onboarding/index.js';
import onboardingImage from './onboarding-image/index.js';
import onboardingDetector from './onboarding-detector/index.js';

export default {
  chat,
  paywall,
  consent,
  cookieBanner,
  settings,
  onboarding,
  onboardingImage,
  onboardingDetector,
};
