export default {
  mobileVersion: {
    steps: {
      three: {
        title: "Bleiben Sie<br/>von KI-generierten<br/>Inhalten Risiken voraus"
      }
    }
  },
  signup: {
    trustText: "Jeden Tag vertrauen Millionen Menschen auf KI-Produkte"
  },
  steps: {
    one: {
      description: "Identifizieren Sie von KI geschriebenen Text in Forschungsarbeiten, studentischen Arbeiten und professionellen Dokumenten.",
      leftSection: {
        description: "Geben Sie hier Text ein oder laden Sie eine Datei hoch, um auf AI-Inhalte zu prüfen.",
        items: {
          one: "Text einfügen",
          three: "Datei hochladen",
          two: "Mustertext"
        },
        title: "AI-Detektor"
      },
      rightSection: {
        text1: "Erkennen Sie KI-generierte Inhalte mit 100 %iger Genauigkeit.",
        text2: "Analysieren Sie ganze Dokumente auf ihre Echtheit.",
        text3: "Sie erhalten detaillierte Berichte mit umsetzbaren Erkenntnissen."
      },
      title: "AI-Detektor von AskGPT"
    },
    three: {
      description: "",
      leftSection: {
        min: "min",
        steps: {
          label1: "Verarbeitung von Texten",
          label2: "Identifizierung gleicher Wörter",
          label3: "Identifizierung geänderter Wörter",
          label4: "Abschluss der Quellenanalyse..."
        },
        title: "Wir analysieren jetzt Ihren Inhalt"
      },
      rightSection: {
        text1: "Erkennen Sie KI-generierte Inhalte vor der Übermittlung.",
        text2: "Überprüfen Sie Forschungsarbeiten, studentische Arbeiten und berufliche Dokumente.",
        text3: "Vermeiden Sie die versehentliche falsche Verwendung von KI-geschriebenem Text.",
        text4: "All-in-One-KI-Assistent mit integrierter KI-Erkennung."
      },
      title: "Bleiben Sie den Risiken von KI-<br/>generierten Inhalten voraus"
    },
    two: {
      description: "Wie es funktioniert:",
      leftSection: {
        everyDiscoveryPeelsBack: "Jede Entdeckung schält eine Schicht des Unbekannten ab, doch mit jeder Antwort tauchen neue Fragen auf, die uns daran erinnern, dass das Universum nicht nur riesig ist, sondern unendlich viele Geheimnisse birgt.",
        exoplanetsOrbitDistant: "Exoplaneten umkreisen ferne Sterne, einige",
        humanComprehensionWithBillions: "menschlichen Verstandes. Milliarden von Galaxien sind über das Gefüge der Raumzeit verstreut, und jede von ihnen birgt vielleicht ungeahnte Geheimnisse - einige sind uns vertraut, andere übersteigen unsere Vorstellungskraft.",
        potentiallyHarboringLife: "die möglicherweise Leben beherbergen, während Signale aus den Tiefen des Weltraums von unbekannten Phänomenen berichten.",
        scientistsEstimateThatOnly: "Wissenschaftler schätzen, dass nur 5 % des Universums aus bekannter Materie bestehen, während der Rest ein Rätsel aus dunkler Energie und dunkler Materie bleibt, unsichtbaren Kräften, die den Kosmos formen. Schwarze Löcher krümmen das Gewebe der Realität und verschlingen Licht und Zeit.",
        theUniverseIsAVastAndEver: "Das Universum ist ein riesiges und sich ständig erweiterndes Mysterium, das mit kosmischen Wundern gefüllt ist.",
        title: "AI-generierter Text"
      },
      rightSection: {
        step: "Schritt",
        text1: "Laden Sie Ihr Dokument hoch oder fügen Sie Ihren Text ein.",
        text2: "Unsere KI scannt und analysiert Ihre Inhalte.",
        text3: "Sie erhalten sofort einen detaillierten Authentizitätsbericht."
      },
      title: "Schnelle, zuverlässige und<br/>mühelose KI-Erkennung"
    }
  }
};
