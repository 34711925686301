export default {
  mobileVersion: {
    steps: {
      three: {
        title: "Rimanere all'avanguardia<br/>dei rischi legati ai contenuti generati dall'IA<br/>"
      }
    }
  },
  signup: {
    trustText: "Milioni di persone si fidano ogni giorno dei prodotti AI"
  },
  steps: {
    one: {
      description: "Identificare il testo scritto dall'intelligenza artificiale in documenti di ricerca, documenti di studenti e documenti professionali.",
      leftSection: {
        description: "Inserire qui il testo o caricare il file per verificare la presenza di contenuti AI.",
        items: {
          one: "Incolla testo",
          three: "Caricare il file",
          two: "Testo campione"
        },
        title: "Rivelatore AI"
      },
      rightSection: {
        text1: "Rileva i contenuti generati dall'intelligenza artificiale con una precisione del 100%.",
        text2: "Analizzare l'autenticità di interi documenti.",
        text3: "Ottenete rapporti dettagliati con informazioni utili."
      },
      title: "Rilevatore di intelligenza artificiale da AskGPT"
    },
    three: {
      description: "",
      leftSection: {
        min: "min",
        steps: {
          label1: "Elaborazione dei testi",
          label2: "Identificare le stesse parole",
          label3: "Identificare le parole modificate",
          label4: "Finalizzazione dell'analisi delle fonti..."
        },
        title: "Stiamo analizzando i vostri contenuti ora"
      },
      rightSection: {
        text1: "Rilevare i contenuti generati dall'intelligenza artificiale prima dell'invio.",
        text2: "Verificare i documenti di ricerca, i lavori degli studenti e i documenti professionali.",
        text3: "Evitare l'uso improprio del testo scritto dall'IA.",
        text4: "Assistente AI all-in-one con rilevamento AI integrato."
      },
      title: "Rimanere al passo con i rischi legati ai contenuti generati dall'intelligenza artificiale (AI)<br/>"
    },
    two: {
      description: "Come funziona:",
      leftSection: {
        everyDiscoveryPeelsBack: "Ogni scoperta rimuove uno strato di ignoto, eppure ad ogni risposta emergono nuove domande, ricordandoci che l'universo non è solo vasto, ma anche infinito nei suoi misteri.",
        exoplanetsOrbitDistant: "Gli esopianeti orbitano attorno a stelle lontane, alcuni",
        humanComprehensionWithBillions: "comprensione umana. Con miliardi di galassie sparse nel tessuto dello spazio-tempo, ognuna di esse può nascondere segreti indicibili, alcuni familiari, altri al di là della nostra immaginazione.",
        potentiallyHarboringLife: "potenzialmente ospitanti la vita, mentre i segnali provenienti dallo spazio profondo sussurrano di fenomeni sconosciuti.",
        scientistsEstimateThatOnly: "Gli scienziati stimano che solo il 5% dell'universo sia costituito da materia conosciuta, mentre il resto rimane un puzzle di energia e materia oscura, forze invisibili che plasmano il cosmo. I buchi neri piegano il tessuto stesso della realtà, inghiottendo la luce e il tempo stesso.",
        theUniverseIsAVastAndEver: "L'universo è un mistero vasto e in continua espansione, pieno di meraviglie cosmiche che vanno al di là di ogni dubbio.",
        title: "Testo generato dall'intelligenza artificiale"
      },
      rightSection: {
        step: "passo",
        text1: "Caricate il vostro documento o incollate il vostro testo.",
        text2: "La nostra AI scansiona e analizza i vostri contenuti.",
        text3: "Ricevere immediatamente un rapporto dettagliato sull'autenticità."
      },
      title: "Rilevamento dell'intelligenza artificiale veloce, affidabile e senza sforzo<br/>"
    }
  }
};
