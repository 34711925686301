export default {
  model: 'Image Generated',
  dialogueSaved: 'Dialogue saved',
  poweredBy: 'Powered by',
  steps: {
    one: {
      title: `From imagination to image <br class='hidden xxl:block'/>with AI creativity`,
      description: `Welcome to AskGPT.<br/>Powered by advanced AI models, transform your boldest ideas into stunning visuals effortlessly — without limits.`,
      items: {
        one: {
          title: 'Generate images with AI',
          description: 'Magician’s hat with galaxies flowing out',
        },
        two: {
          title: 'Visualize your ideas',
          description:
            'Blog illustration: climber on a mountain peak',
        },
        three: {
          title: 'Create custom art pieces',
          description:
            'Minimalist abstract painting for a living room',
        },
        four: {
          title: 'Business branding',
          description: 'Modern logo design for a tech startup',
        },
      },
    },
    two: {
      title: `High-quality AI images<br/>in seconds`,
      description: `Create stunning visuals instantly with AskGPT. Experience fast, premium image generation and tap into endless creative possibilities.`,
      userMessage: `Create AI art combining circuit patterns with vibrant flowers`,
      items: {
        item1: 'AI art',
        item2: 'AI logos',
        item3: 'AI photos',
        item4: 'AI tattoos',
        item5: 'AI posters',
        item6: 'Text to image',
        item7: 'Image to image'
      },
    },
    three: {
      title: `Visualize anything with<br/>versatile AI tools`,
      description: `AskGPT lets you create custom art, fashion, game characters, and marketing visuals. Powered by cutting-edge AI, bring your ideas to life effortlessly.`,
      userMessage1: `Create a series of dreamy landscapes, each representing a different season`,
      userMessage2: `Generate a series of living room interiors, each in a unique render style`,
    },
    four: {
      title: `Advanced AI toolkit`,
      description: `Beyond AI image tools, AskGPT offers a suite of powerful AI services, including smart chat assistance, website links analysis, and PDF text analysis.`,
      userMessage: `Write a 1,000-word essay on the milestones and future of space exploration`,
      gptMessage: `Space exploration has come a long way since the first human gaze turned skyward in wonder.<br/><br/>Its milestones have marked humanity’s bold journey beyond Earth and have paved the way for a future filled with immense possibilities.<br/><br/>This essay explores key achievements in space exploration and what lies ahead...`,
      items: {
        item1: 'Art',
        item2: 'Work',
        item3: 'Learn',
        item4: 'Sales',
        item5: 'Productivity',
        item6: 'Data',
        item7: 'Fun'
      },
    },
  },
  mobileVersion: {
    two: {
      message:
        'Create AI art combining circuit patterns with vibrant flowers',
    },
    three: {
      message:
        'Create a series of landscapes, representing a different season',
    },
    four: {
      items: {
        item1: 'Productivity',
        item2: 'Learn',
        item3: 'Sales',
        item4: 'Work',
        item5: 'Data',
        item6: 'Fun',
        item7: 'Art'
      },
    },
  },
};
