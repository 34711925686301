export default {
  active: "Attivo",
  cancel: "Annulla",
  canceled: "Annullato",
  "delete": "cancellare",
  deleteAccount: "Cancellare l'account",
  deleteAccountAndData: "Cancellare l'account e i dati",
  deleteAccountButton: "Cancellare l'account",
  deleteAccountText: "Siete sicuri di voler cancellare l'account <br/> e i dati?",
  deleteSuccessful: "Il tuo account è stato cancellato",
  emailAddress: "Indirizzo​ ​e-mail",
  freePlan: "Piano ​gratuito",
  goPro: "DIVENTA ​PRO",
  logOut: "Esci",
  ok: "OK",
  plan: "piano",
  privacyPolicy: "Informativa sulla privacy",
  renewsAt: "si​ rinnova​ ​a",
  subscription: "Abbonamento",
  termsOfUse: "Condizioni di utilizzo",
  title: "Impostazioni"
};
