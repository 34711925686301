export default {
  checkout: {
    'continue': 'Continuar',
    discount: 'desconto',
    easierFasterAndSaferWayToPay: 'A forma mais fácil, rápida e segura de pagar',
    orPayByCard: 'Ou pague com cartão',
    title: 'Finalização da compra',
    total: 'Total',
    totalPerDiscount: 'Total por dia'
  },
  'continue': 'Continue',
  plans: {
    and: 'e',
    appName: 'AskGPT​•​Pro',
    byProceedingWithThePurchase: 'Ao prosseguir com a compra de um plano, está a concordar',
    mostPopular: 'Mais popular',
    paySafe: 'Pagamento seguro',
    perDay: 'Por​•​Dia',
    privacyPolicy: 'Política de Privacidade',
    refund: 'Política de Reembolso e Cancelamento.',
    save: 'poupe',
    subtitle: 'Desbloqueie todo o potencial da aplicação AskGPT',
    termsOfService: 'Termos do Serviço',
    to: '​para',
    toOur: 'com os nossos',
    upgrade: 'Atualize'
  },
  poweredBy: 'Com tecnologia',
  prompts: {
    benefits: {
      '[0]': {
        description: 'De projetos profissionais a rotinas diárias',
        title: 'Mais de 350 soluções de tarefas personalizadas'
      },
      '[1]': {
        description: 'Aceda instantaneamente a soluções personalizadas para os seus desafios',
        title: 'Respostas personalizadas com um clique'
      },
      '[2]': {
        description: 'O seu assistente pessoal e gratuito para todos os aspetos da vida',
        title: 'Bot GPT universal'
      },
      item1: {
        description: 'From professional projects to daily routines',
        title: '350+ tailored task solutions'
      },
      item2: {
        description: 'Instantly access customized solutions for your challenges',
        title: 'One-click personalized answers'
      },
      item3: {
        description: 'Your personal, free assistant for all aspects of life',
        title: 'Universal AI bot'
      }
    },
    boostYourProductivityBy: 'Aumente a sua produtividade em',
    expertCraftedPrompts: 'instruções elaboradas por especialistas',
    getLifetimeAccessOurExpert: 'Get lifetime access to our expert prompt library!',
    now: 'agora',
    off: 'de desconto',
    paySafe: 'Pagamento​•​seguro',
    proposedPlans: {
      pro: {
        items: {
          '[0]': '10-15 min.',
          '[1]': '3-4 horas',
          '[2]': '20-30 min.',
          '[3]': '10-15 min.',
          '[4]': '20 min.',
          item1: '10-15 min',
          item2: '3-4 hours',
          item3: '20-30 min',
          item4: '10-15 min',
          item5: '20 min'
        },
        title: 'Com as nossas instruções baseadas em investigação'
      },
      standart: {
        items: {
          '[0]': '1-2 horas',
          '[1]': '8-10 horas',
          '[2]': '2-3 horas',
          '[3]': '1-2 horas',
          '[4]': '1-2 horas',
          item1: '1-2 hours',
          item2: '8-10 hours',
          item3: '2-3 hours',
          item4: '1-2 hours',
          item5: '1-2 hours'
        },
        title: 'Com o ChatGPT padrão'
      }
    },
    save: 'Save',
    superchargeYour: 'Potencie o seu',
    title: 'Potencie o seu<br​•​class="lg:hidden"​•​/>ChatGPT-4 com<br​•​class="lg:hidden"​•​/>instruções elaboradas por especialistas,<br​•​class="lg:hidden"​•​/>agora <span​•​class="text-[#DC2728]">com 80% de desconto!</span>',
    topicsForRequests: {
      '[0]': '🏋 Crie um plano de treinos personalizado',
      '[1]': '📸 Aprenda a fazer edição básica de fotos',
      '[2]': '🎥 Escreva um guião para um vídeo',
      '[3]': '🥗 Sugira um plano de refeições semanal',
      '[4]': '📝 Responda a 10 e-mails',
      topic1: '🏋 Create a custom workout plan',
      topic2: '📸 Learn basic photo editing',
      topic3: '🎥 Write a script for a video',
      topic4: '🥗 Suggest a weekly meal plan',
      topic5: '📝 Reply to 10 emails'
    },
    totalToday: 'Total hoje',
    upgrade: 'Atualize',
    'with': 'com'
  },
  purchaseBenefits: {
    free: 'grátis',
    items: {
      advancedTools: {
        description: 'Acesso a ferramentas avançadas de IA,<br/>como o Gerador de Imagens',
        title: 'Desbloquear ferramentas avançadas'
      },
      gpt: {
        description: 'Conversar com o ChatGPT',
        title: 'Acesso ao ChatGPT'
      },
      gpt4o: {
        description: 'Respostas mais precisas e detalhadas',
        title: 'Acesso ao GPT-4o'
      },
      noLimits: {
        description: 'Diálogos ilimitados com todos os modelos de IA',
        title: 'Sem limites'
      }
    },
    pro: 'pró'
  },
  successModal: {
    backToChat: 'Voltar​•​ao​•​chat',
    hasBeenSuccessfullyActivated: 'foi ativado com sucesso.',
    plan: 'Pro',
    title: 'Pagamento realizado com sucesso!',
    yourPro: 'O seu Plano'
  }
};
