import chat from './chat/index.js';
import consent from './consent/index.js';
import cookieBanner from './cookie-banner/index.js';
import paywall from './paywall/index.js';
import settings from './settings/index.js';
import onboarding from './onboarding/index.js';
import onboardingDeepSeek from './onboarding-deep-seek/index.js';
import onboardingImage from './onboarding-image/index.js';
import onboardingDetector from './onboarding-detector/index.js';
import onboardingGrok from './onboarding-grok/index.js';
import aiHumanizer from './ai-humanizer/index.js';
import aiDetector from './ai-detector/index.js';

export default {
  chat,
  paywall,
  consent,
  cookieBanner,
  settings,
  onboarding,
  onboardingDeepSeek,
  onboardingImage,
  onboardingDetector,
  onboardingGrok,
  aiHumanizer,
  aiDetector
};
